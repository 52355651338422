<div class="row justify-content-center">

    <div class="col-12">

        <h1 class="text-center mt-4">RevisaPrev - Administração dos Formulários</h1>

        <div class="row mt-5" *ngIf="formularios == null">
            <loading></loading>
        </div>

        <div class="row mt-5" *ngIf="formularios != null">

            <div class="col-12 col-md-6 col-xl-2 border-end">

                <lista-forms [formularios]="formularios" (formulario)="receberFormulario($event)"></lista-forms>

            </div>

            <div class="col-12 col-md-6 col-xl-2 mt-4 mt-md-0 border-end">

                <lista-perguntas [formulario]="formularioSelecionado" (pergunta)="receberPergunta($event)" (posicoes)="salvarPosicoes($event)"
                    *ngIf="formularioSelecionado != null"></lista-perguntas>

            </div>

            <div class="col-12 col-xl-8 mt-4 mt-md-0">

                <form-pergunta [idFormulario]="idFormularioSelecionado" [pergunta]="perguntaSelecionada" [variaveis]="variaveis"
                    [perguntas]="perguntasSelecionadas" [exibeBarraProgresso]="mostrarBarraProgresso"
                    [estiloFormulario]="formularioSelecionado.estilo" *ngIf="formularioSelecionado != undefined && perguntaSelecionada != undefined"
                    (atualizar)="atualizar()" (perguntaSalva)="atualizarVariaveis($event)"></form-pergunta>

            </div>

        </div>

    </div>

</div>
