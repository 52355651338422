import { Component } from "@angular/core";

@Component({
    selector: "loading",
    templateUrl: "./loading.component.html",
    styleUrls: ['./loading.component.css']
})
export class LoadingComponent{

}
