import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'formatador',
    templateUrl: './form-formata.component.html',
    styleUrls: ['./form-formata.component.css']
})
export class FormFormataComponent{

    @Input() id!: string;
    @Input() texto!:string;
    @Input() variaveis: Array<string> = [];
    @Input() descricao: string = "";
    @Output() textoFormatado: EventEmitter<string> = new EventEmitter();

    constructor(){}

    private selecionarTexto(): string[]{

        let partes: string[] = [];

        let textarea = document.querySelector(`#${this.gerarIdTexto()}`) as HTMLTextAreaElement;

        let inicioSelecao = textarea.selectionStart;
        let fimSelecao = textarea.selectionEnd;

        partes.push(textarea.value.substring(0,inicioSelecao)); //Pre Seleção
        partes.push(textarea.value.substring(inicioSelecao,fimSelecao)); //Seleção
        partes.push(textarea.value.substring(fimSelecao,textarea.value.length)); //Pós Seleção
        partes.push(inicioSelecao.toString());
        partes.push(fimSelecao.toString());

        return partes;

    }

    private aplicarTexto(texto: string){
        let textarea = document.querySelector(`#${this.gerarIdTexto()}`) as HTMLTextAreaElement;
        textarea.value = texto;
    }

    public estilo(opcao: number){

        let partes = this.selecionarTexto();

        let inicioSelecao = parseInt(partes[3]);
        let fimSelecao = parseInt(partes[4]);

        if(inicioSelecao == 0 && fimSelecao == 0 || inicioSelecao == fimSelecao) return;

        let selecao = partes[1]

        switch (opcao) {
            case 0: //negrito
                selecao = this.aplicarEstilo(selecao,'b');
            break;
            case 1: //italico
                selecao = this.aplicarEstilo(selecao,'i');
            break;
            case 2: //sublinhado
                selecao = this.aplicarEstilo(selecao,'u');
            break;
        }

        this.texto = partes[0] + selecao + partes[2];;
        this.aplicarTexto(this.texto);

    }

    private aplicarEstilo(texto: string, tag: string): string{
        if(texto.match(`<${tag}>`) || texto.match(`</${tag}>`)){
            texto = texto.replace(`<${tag}>`,'').replace(`</${tag}>`,'');
        }else{
            texto = `<${tag}>${texto}</${tag}>`;
        }
        return texto;
    }

    public colocarVariavel(){

        let partes = this.selecionarTexto();

        let inicioSelecao = parseInt(partes[3]);
        let fimSelecao = parseInt(partes[4]);

        if(inicioSelecao != fimSelecao) return

        let select = document.querySelector(`#${this.gerarIdSelecionarVariavel()}`) as HTMLSelectElement;
        let variavel = select.options[select.selectedIndex].value;

        if(variavel == "0") return;

        this.texto = `${partes[0]} <span class='destaque-variavel'>${variavel}</span> ${partes[2]}`;
        this.aplicarTexto(this.texto);

    }

    public eventoTextoDigitado(valor: string){
        this.texto = valor;
    }

    public gerarId():string{
        return `m${this.id}`;
    }

    public gerarIdTexto():string{
        return `t${this.id}`;
    }

    public gerarIdSelecionarVariavel(): string{
        return `s${this.id}`;
    }

    public abrirModal(){
        let textarea = document.querySelector(`#${this.gerarIdTexto()}`) as HTMLTextAreaElement;
        textarea.value = this.texto;
    }

    public salvar(){
        this.textoFormatado.emit(this.texto);
    }

    public aplicarCorDestaque(event: any){

        let corHex = event.target.value;

        let partes = this.selecionarTexto();

        let inicioSelecao = parseInt(partes[3]);
        let fimSelecao = parseInt(partes[4]);

        if(inicioSelecao == 0 && fimSelecao == 0 || inicioSelecao == fimSelecao) return;

        this.texto = `${partes[0]} <b><font color="${corHex}">${partes[1]}</font></b> ${partes[2]}`;
        this.aplicarTexto(this.texto);

    }

}
