import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Usuario } from "../modelos/usuario.model";

@Injectable()
export class UsuarioService {

    private api: string = `${environment.apiBackEnd}/api/v1/usuario`;

    constructor(private http: HttpClient) { }

    public cadastrar(usuario: Usuario): Observable<Usuario>{
        return this.http.post<Usuario>(this.api,usuario);
    }

    public alterar(id: number, usuario: Usuario): Observable<Usuario>{
        let urlLocal = `${this.api}/${id}`;
        return this.http.put<Usuario>(urlLocal,usuario);
    }

    public excluir(id: number): Observable<void>{
        let urlLocal = `${this.api}/${id}`;
        return this.http.delete<void>(urlLocal);
    }

    public buscarPorEmail(email: string): Observable<Usuario> {
        let url = `${this.api}/get`;
        return this.http.post<Usuario>(url, email);
    }

    public buscarTodos(): Observable<Usuario[]>{
        let urlLocal = `${this.api}/get-todos`;
        return this.http.post<Usuario[]>(urlLocal,null);
    }

}
