import { NgModule } from "@angular/core";
import { DataBsTargetDirective } from "./data-bs-target.directive";

@NgModule({
    declarations: [DataBsTargetDirective],
    exports: [DataBsTargetDirective]
})
export class DataBsTargetModule{

}
