import { NgModule } from "@angular/core";
import { RouterModule, Routes} from "@angular/router";
import { AutenticacaoComponent } from "./modulos_principais/autenticacao/autenticacao.component";
import { AutenticacaoGuardService } from "./servicos/autenticacao-guard.service";

const routes: Routes = [
    {
        path: '',
        component: AutenticacaoComponent,
        canActivate: [AutenticacaoGuardService]
    },
    {
        path: 'admin',
        loadChildren: () => import('./modulos_principais/adm-form/adm-form.module').then(m => m.AdmFormModule)
    },
    {
        path: 'estatistica',
        loadChildren: () => import('./modulos_principais/estatistica/estatistica.module').then(m => m.EstatisticaModule)
    },
    {
        path: 'cliente',
        loadChildren: () => import('./modulos_principais/cliente/cliente.module').then(m => m.ClienteModule)
    },
    {
        path: 'redir-url',
        loadChildren: () => import('./modulos_principais/form-redir/form-redir.module').then(m => m.FormRedirModule)
    },
    {
        path: 'usuarios',
        loadChildren: () => import('./modulos_principais/usuarios/usuarios.module').then(m => m.UsuariosModule)
    },
    {
        path: 'adm-videos',
        loadChildren: () => import('./modulos_principais/adm-videos/adm-videos.module').then(m => m.AdmVideosModule)
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
