import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { TokenService } from "./token.service";
import { Router } from "@angular/router";
import { UsuarioLogadoService } from "./usuario-logado.service";

@Injectable({providedIn: 'root'})
export class InterceptorService implements HttpInterceptor {

    constructor(
        private tokenService: TokenService,
        private usuarioLogadoService: UsuarioLogadoService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //Fazer Login
        if (req.url.match('/login')) {
            return next.handle(req);
        }

        //Para as demais requisições http

        const token = this.tokenService.getToken() as string | string[];

        req = req.clone({
            setHeaders: {
                "Authorization": token,
                "Cache-Control": "no-cache",
                Pragma: "no-cache",
            }
        });

        return next.handle(req).pipe(tap({
            error: error => {
                console.log("ERRO - LOG DO ERRO !");
                console.log(error);
                if(error.status == 403){ //Token Inválido possivelmente!
                    this.tokenService.excluirToken();
                    this.usuarioLogadoService.deslogarUsuario();
                    this.router.navigate(['/']);
                }
            }
        }));

    }

}
