<nav class="navbar navbar-expand-lg bg-revisa-prev">
    <div class="container-fluid">

        <a class="navbar-brand color-revisa-prev" routerLink="/admin">
            <img src="./../../../assets/imgs/logo.png" width="30" height="30" />REVISA PREV
        </a>

        <span style="color: white;position: absolute; bottom: 0; left: 50;margin-top: 5px;opacity: 0.5; margin-left: 30px;">Versão: {{versao}}</span>

        <div class="navbar-collapse" id="navbarText" *ngIf="usuario != null">

            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/admin" routerLinkActive="active-personalizado"><i class="fa-solid fa-pencil me-1"></i>ADM FORMULÁRIOS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/adm-videos" routerLinkActive="active-personalizado"><i class="fa-solid fa-film me-1"></i>ADM VIDEOS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/estatistica" routerLinkActive="active-personalizado"><i class="fa-solid fa-chart-line me-1"></i>ESTATÍSTICAS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/cliente" routerLinkActive="active-personalizado"><i class="fa-solid fa-users me-1"></i>CLIENTES</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/redir-url" routerLinkActive="active-personalizado"><i class="fa-solid fa-chart-line me-1"></i>URL REDIRECIONAMENTOS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/usuarios" routerLinkActive="active-personalizado"><i class="fa-solid fa-users me-1"></i>USUÁRIOS</a>
                </li>
            </ul>

            <span class="navbar-text text-white">
                <span class="fw-bolder me-4"><i class="fa-solid fa-user me-2"></i>{{usuario.nome}}</span>
                <a href="#" class="text-white" (click)="logout()"><i class="fa-solid fa-right-from-bracket me-1"></i>Sair</a>
            </span>

        </div>

    </div>
</nav>
