import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Pergunta } from "../modelos/pergunta.model";

@Injectable()
export class PerguntaService{

    private api:string = `${environment.apiBackEnd}/api/v1/pergunta`;

    constructor(private http: HttpClient){}

    public alterarPergunta(pergunta: Pergunta): Observable<Pergunta>{
        let url = `${this.api}/${pergunta.id}`;
        return this.http.put<Pergunta>(url,pergunta);
    }

    public excluir(id:number): Observable<any>{
        let url = `${this.api}/${id}`;
        return this.http.delete(url);
    }

    public salvarPosicao(perguntas: Pergunta[]): Observable<Pergunta[]>{
        let url = `${this.api}/posicao`;
        return this.http.patch<Pergunta[]>(url,perguntas);
    }

    public atualizarPerguntas(perguntas: Pergunta[]): Observable<Pergunta[]>{
        let url = `${this.api}/perguntas`;
        return this.http.put<Pergunta[]>(url,perguntas);
    }

    public consultaIdentificador(idFormulario: number, identificador: string): Observable<boolean>{
        let url = `${this.api}/consulta-identifacador/${idFormulario}`;
        return this.http.post<boolean>(url,identificador);
    }


}
