import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EstiloFormulario, Formulario } from "../modelos/formulario.model";
import { FormularioNome } from "../modelos/formularioNome";
import { Pergunta } from "../modelos/pergunta.model";
import { TipoResposta } from "../modelos/tipo-resposta.model";

@Injectable()
export class FormularioService{

    private api: string = `${environment.apiBackEnd}/api/v1/formulario`;

    constructor(private http: HttpClient){}

    public cadastrar(formulario: Formulario):Observable<Formulario>{
        let url = `${this.api}`;
        return this.http.post<Formulario>(url,formulario);
    }

    public excluir(id: number): Observable<any>{
        let url = `${this.api}/${id}`;
        return this.http.delete<any>(url);
    }

    public buscarFormularios():Observable<Formulario[]>{
        let url = `${this.api}/get`;
        return this.http.post<Formulario[]>(url, '');
    }

    public adicionarPergunta(idFormulario: number, pergunta: Pergunta):Observable<Formulario>{
        let url = `${this.api}/${idFormulario}/pergunta`;
        return this.http.post<Formulario>(url,pergunta);
    }

    public atualizarNome(id: number, formularioNome: FormularioNome):Observable<Formulario>{
        let url = `${this.api}/${id}`;
        return this.http.patch<Formulario>(url,formularioNome);
    }

    public clonar(id:number):Observable<Formulario>{
        let url = `${this.api}/clone/${id}`;
        return this.http.post<Formulario>(url,null);
    }

    public cadastrarPerguntas(idFormulario:number, perguntas: Pergunta[]): Observable<Formulario>{
        let url = `${this.api}/${idFormulario}/perguntas`;
        return this.http.post<Formulario>(url,perguntas);
    }

    public atualizarEstilo(idFormulario: number, estilo: EstiloFormulario): Observable<Formulario>{
        let url = `${this.api}/${idFormulario}/estilo`;
        return this.http.patch<Formulario>(url,estilo);
    }

    public consultarURL(url: string): Observable<Boolean>{
        let urlLocal = `${this.api}/consulta-url`;
        return this.http.post<Boolean>(urlLocal,url);
    }

    public consultarNome(nome: string): Observable<Boolean>{
        let urlLocal = `${this.api}/consulta-nome`;
        return this.http.post<Boolean>(urlLocal,nome);
    }

    public buscarPorId(id: number): Observable<Formulario>{
        let urlLocal = `${this.api}/get/${id}`;
        return this.http.post<Formulario>(urlLocal,null);
    }

}
