import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Video } from "../modelos/video.model";
import { Observable } from "rxjs";

@Injectable()
export class VideoService{

    private api: string = `${environment.apiBackEnd}/api/v1/video`;

    constructor(private http: HttpClient){}

    public upload(dados: Video): Observable<Video> {
        return this.http.post<Video>(this.api,dados);
    }

    public alterar(id: number, dados: Video): Observable<Video>{
        let urlLocal = `${this.api}/${id}`;
        return this.http.put<Video>(urlLocal,dados);
    }

    public buscarPorId(id:number): Observable<Video>{
        let urlLocal = `${this.api}/${id}`;
        return this.http.post<Video>(urlLocal,null);
    }

    public excluir(id:number): Observable<void> {
        let urlLocal = `${this.api}/${id}`;
        return this.http.delete<void>(urlLocal);
    }

    public listar(): Observable<Video[]>{
        return this.http.post<Video[]>(`${this.api}/get`,null);
    }

}