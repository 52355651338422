import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SpinnerService } from "src/app/compartilhados/componentes/spinners/spinner.service";
import { Autenticacao } from "src/app/modelos/autenticacao.model";
import { AutenticacaoService } from "src/app/servicos/autenticacao.service";
import { UsuarioLogadoService } from "src/app/servicos/usuario-logado.service";
import Swal from "sweetalert2";

@Component({
    selector: 'autenticacao',
    templateUrl: './autenticacao.component.html',
    styleUrls: ['./autenticacao.component.css']
})
export class AutenticacaoComponent implements OnInit{

    public formAutenticacao!: FormGroup;

    public desativaBotaoLogin:boolean = true;

    constructor(
        private formBuilder: FormBuilder,
        private autenticacaoService: AutenticacaoService,
        private usuarioLogadoService: UsuarioLogadoService,
        private router: Router,
        private spinnerService: SpinnerService,
    ){}

    ngOnInit(): void {
        this.formAutenticacao = this.formBuilder.group({
            email: ['',[Validators.required,Validators.email]],
            senha: ['',[Validators.required]],
        });
    }

    public entrar(){

        this.desativaBotaoLogin = true;

        let autenticacao = this.formAutenticacao.getRawValue() as Autenticacao;

        this.spinnerService.ativarSpinner();

        this.autenticacaoService.logar(autenticacao).subscribe({
            next: resp => {

                let token = `${resp.tipo} ${resp.token}`;

                this.usuarioLogadoService.logarUsuario(autenticacao.email,token);

                this.router.navigate(['/admin']);

                this.spinnerService.desativarSpinner();

            },
            error: error => {
                Swal.fire({
                    title: "Erro",
                    icon: "error",
                    text: "Usuário e/ou senha inválidos!"
                });
                this.formAutenticacao.reset();
                this.desativaBotaoLogin = false;
                this.spinnerService.desativarSpinner();
                console.log(error);
            }
        });

    }


}
