import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Autenticacao } from "src/app/modelos/autenticacao.model";
import { environment } from "src/environments/environment";
import { Token } from "../modelos/token.model";

@Injectable()
export class AutenticacaoService{

    private api: string = `${environment.apiBackEnd}/api/v1/auth`;

    constructor(private http: HttpClient){}

    public logar(autenticacao: Autenticacao): Observable<Token>{
        let url = `${this.api}/login`;
        return this.http.post<Token>(url,autenticacao);
    }

}
