<button type="button" title="Gerenciar Burladores"
    class="btn btn-sm btn-primary d-inline" data-bs-toggle="modal"
    [dataBsTarget]="gerarId()"><i class="fa-solid fa-gear me-1"></i>Gerenciar Burladores
</button>

<div class="modal fade" tabindex="-1" [id]="gerarId()">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Gerenciar Burladores</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <ng-container *ngIf="!mostrarEdicao">
                        <div class="row">

                            <h5>Adicionar Burlador:</h5>

                            <form [formGroup]="formSalvarBurlador" (submit)="salvar()">

                                <div class="row">

                                    <div class="col-12">
                                        <label for="nome" class="form-label mb-0">Nome:</label>
                                        <input type="text" class="form-control" formControlName="nome">
                                    </div>

                                    <div class="col-12 mt-2 d-flex align-items-center justify-content-start">
                                        <label for="linkProprio" class="form-check-label me-1">Link Proprio? Sim</label>
                                        <input type="checkbox" class="form-check-input" formControlName="linkProprio">
                                    </div>

                                    <div class="col-12 mt-2" *ngIf="formSalvarBurlador.get('linkProprio')?.value == true">
                                        <label for="link" class="form-label mb-0">Link Redirecionamento:</label>
                                        <input type="text" class="form-control" formControlName="link">
                                    </div>

                                </div>

                                <button type="submit" [disabled]="formSalvarBurlador.invalid" class="btn btn-primary mt-2"><i class="fa fa-save me-1"></i>Salvar</button>

                            </form>

                        </div>
                    </ng-container>

                    <ng-container *ngIf="mostrarEdicao">
                        <div class="row">

                            <h5>Editar Burlador:</h5>

                            <form [formGroup]="formEditarBurlador" (submit)="salvarEdicao()">

                                <div class="row">

                                    <div class="col-12">
                                        <label for="nome" class="form-label mb-0">Nome:</label>
                                        <input type="text" class="form-control" formControlName="nome">
                                    </div>

                                    <div class="col-12 mt-2 d-flex align-items-center justify-content-start">
                                        <label for="linkProprio" class="form-check-label me-1">Link Proprio? Sim</label>
                                        <input type="checkbox" class="form-check-input" formControlName="linkProprio">
                                    </div>

                                    <div class="col-12 mt-2" *ngIf="formEditarBurlador.get('linkProprio')?.value == true">
                                        <label for="link" class="form-label mb-0">Link Redirecionamento:</label>
                                        <input type="text" class="form-control" formControlName="link">
                                    </div>

                                </div>

                                <button type="submit" [disabled]="formEditarBurlador.invalid" class="btn btn-primary mt-2"><i class="fa fa-save me-1"></i>Salvar</button>
                                <button type="button" class="btn btn-outline-primary mt-2 ms-2" (click)="voltarPainelAdd()"><i class="fa-solid fa-left-long me-1"></i>Voltar</button>

                            </form>

                        </div>
                    </ng-container>

                    <hr>

                    <div class="row">

                        <h5>Burladores:</h5>

                        <span class="alert alert-warning text-center" *ngIf="burladores.length == 0">Sem Burladores no momento!</span>

                        <div class="row mt-1" *ngFor="let b of burladores">

                            <div class="col-4">
                                {{b.nome}}
                            </div>

                            <div class="col-6">
                               <span style="word-break: break-all;" *ngIf="b?.linkProprio">{{b.link}}</span>
                            </div>

                            <div class="col-2">
                                <button type="button" class="btn btn-sm btn-primary me-1" (click)="setDadosEditar(b)"><i class="fa fa-edit"></i></button>
                                <button type="button" class="btn btn-sm btn-danger" (click)="excluir(b)"><i class="fa fa-trash"></i></button>
                            </div>

                        </div>

                    </div>


                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
            </div>
        </div>
    </div>
</div>

