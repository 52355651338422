import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { SpinnerModule } from "src/app/compartilhados/componentes/spinners/spinner.module";
import { FormularioService } from "src/app/servicos/formulario.service";
import { InterceptorService } from "src/app/servicos/interceptador.service";
import { ModuloGuardService } from "src/app/servicos/modulo-guard.service";
import { PerguntaService } from "src/app/servicos/pergunta.service";
import { AdmFormComponent } from "./adm-fom.component";
import { AdmFormRoutingModule } from "./adm-form-routing.module";
import { FormPerguntaModule } from "./form-pergunta/form-pergunta.module";
import { LoadingModule } from "src/app/compartilhados/componentes/loading/loading.module";
import { ListaPerguntasModule } from "./lista-perguntas/lista-perguntas.module";
import { ListaFormsModule } from "./lista-forms/lista-forms.module";
import { VideoService } from "src/app/servicos/video.service";

@NgModule({
    declarations: [
        AdmFormComponent
    ],
    imports: [
        CommonModule,
        AdmFormRoutingModule,
        FormPerguntaModule,
        ListaPerguntasModule,
        ListaFormsModule,
        HttpClientModule,
        SpinnerModule,
        LoadingModule,
    ],
    exports: [AdmFormComponent],
    providers: [
        FormularioService,
        PerguntaService,
        ModuloGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass:  InterceptorService,
            multi: true
        },
        VideoService
    ]
})
export class AdmFormModule{

}
