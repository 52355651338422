<div class="row justify-content-end">
    <div class="col-1 d-flex justify-content-end">
        <visualizador [id]="gerarIdVisualizador('Visualizador')"
            [pergunta]="pergunta"
            [estilo]="estiloFormulario"></visualizador>
    </div>
</div>

<form [formGroup]="formMarcacaoUsuario" class="mb-3">

    <div class="row">

        <div class="col-12 col-md-4">
            <div class="form-group">
                <label for="posicao" class="form-label mb-0">Posição:</label>
                <select class="form-select" id="posicao" formControlName="posicao">
                    <ng-container *ngFor="let p of perguntas; let i = index">
                        <option [value]="i+1">{{i+1}}</option>
                    </ng-container>
                </select>
            </div>
        </div>

        <div class="col-12 col-md-4 mt-3 mt-md-0">
            <div class="form-group">
                <label for="tipo" class="form-label mb-0">Tipo da Pergunta:</label>
                <fieldset formGroupName="resposta">
                    <input type="text" id="tipo" class="form-control" formControlName="tipoResposta">
                </fieldset>
            </div>
        </div>

        <div class="col-12 col-md-4 mt-3 mt-md-0">
            <div class="form-group">
                <label for="identificador" class="form-label mb-0">Identificador:</label>
                <input type="text" id="identificador" class="form-control" formControlName="identificador">
            </div>
        </div>

    </div>

    <div class="form-group mt-3">
        <label for="titulo" class="form-label mb-0">Título:</label>
        <div class="d-md-flex">
            <formatador [id]="gerarIdFormatador('Titulo')" [variaveis]="variaveis" [texto]="getTitulo()"
                (textoFormatado)="unicaFormatadoTitulo($event)" descricao="Alterar texto de título"></formatador>
            <p [innerHTML]="formMarcacaoUsuario.get('titulo')?.value" class="ms-3"></p>
        </div>
    </div>

    <div class="form-group mt-3">
        <label for="descricao" class="form-label mb-0">Descrição:</label>
        <div class="d-md-flex">
            <formatador [id]="gerarIdFormatador('Descricao')"
                [variaveis]="variaveis" [texto]="getDescricao()"
                    (textoFormatado)="unicaFormatadoDescricao($event)" descricao="Alterar o texto do subtítulo"></formatador>
            <p [innerHTML]="formMarcacaoUsuario.get('descricao')?.value" class="ms-3"></p>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-4 mt-1">
            <label for="" class="form-label mb-0">Exibir imagem na pergunta? Sim: </label>
            <input type="checkbox" class="ms-2 form-check-input" formControlName="exibeImagem">
        </div>
        <div class="col-4" *ngIf="formMarcacaoUsuario.get('exibeImagem')?.value == true">
            <div class="d-md-flex">
                <input type="file" id="imagem" class="form-control" accept="image/*" (change)="tratarArquivo($event)">
            </div>
        </div>
        <div class="col-4" *ngIf="formMarcacaoUsuario.get('exibeImagem')?.value == true">
            <div class="d-md-flex">
                <input type="url" id="urlImagem" class="form-control" formControlName="urlImagemBase64">
            </div>
        </div>
    </div>

    <h5 class="mt-3">Alternativas:</h5>

    <div class="row mt-2">
        <div class="col">
            <fieldset formGroupName="resposta">
                <label for="letraAlternativas" class="form-label me-1">Exibir letras nas alternativa? Sim</label>
                <input type="checkbox" id="letraAlternativas" class="form-check-input" formControlName="exibirLetraAlternativa">
            </fieldset>
        </div>
    </div>

    <button type="button" title="Adicionar outra alternativa" (click)="addAlternativa()" class="btn btn-sm btn-outline-primary me-2 mt-3"><i class="fa-solid fa-plus"></i></button>

    <div clas="mt-3">
        <fieldset formGroupName="resposta">
            <ng-container *ngFor="let alternativa of formAlternativas.controls; let i=index">
                <ng-container [formGroup]="$any(alternativa)">
                    <div class="form-group mt-4 mt-md-3">

                        <div class="row justify-content-between mb-0">
                            <div class="col">
                                <label for="alternativa1" class="form-label mb-0 mt-2">Alternativa {{i+1}}:</label>
                            </div>
                            <div class="col text-end">
                                <button type="button" title="Excluir essa alternativa" (click)="rmvAlternativa(i)" class="btn btn-sm btn-outline-danger mb-1">
                                    <i class="fa-solid fa-minus"></i>
                                </button>
                            </div>
                        </div>

                        <input type="text" class="form-control" formControlName="titulo">

                        <div class="row mt-1">
                            <div class="col-12 col-md-6">
                                <label for="temEstiloProprio" class="me-1">Personalizar Botão da Alternativa? Sim</label>
                                <input type="checkbox" id="temEstiloProprio" class="form-check-input" formControlName="estiloProprio">
                            </div>
                            <div class="col-12 col-md-6 d-md-flex justify-content-end mt-1 mt-md-0" *ngIf="alternativa.get('estiloProprio')?.value">
                                <div>
                                    <form-formata-estilo-alternativa
                                        [id]="alternativa.get('id')?.value"
                                        [titulo]="alternativa.get('titulo')?.value"
                                        [estiloDefinido]="getEstiloAlternativa(i)"
                                        (estilo)="setEstiloAlternativa(i,$event)"></form-formata-estilo-alternativa>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-12 col-xl-3">
                                <div class="form-group mt-1">
                                    <label [for]="gerarIdInput('ultima',i)" class="form-label mb-0 me-1">Link? Sim</label>
                                    <input type="checkbox" [id]="gerarIdInput('ultima',i)" class="form-check-input" formControlName="ultima">
                                </div>
                            </div>
                            <div class="col-12 col-xl-9">
                                <div class="row">
                                    <ng-container *ngIf="alternativa.get('ultima')?.value != true">
                                        <label [for]="gerarIdInput('redirecionamentoPergunta',i)" class="col-12 col-md-6 col-form-label mb-0 text-xl-end">Redirecionamento:</label>
                                        <div class="col-12 col-md-6">
                                            <select [id]="gerarIdInput('redirecionamentoPergunta',i)" class="form-select" formControlName="redirecionamentoPergunta">
                                                <option value="0">Selecione uma opção!</option>
                                                <ng-container *ngFor="let p of perguntas; let i = index">
                                                    <option [value]="p.id">Pergunta {{i+1}}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="alternativa.get('ultima')?.value == true">
                                        <label [for]="gerarIdInput('redirecionamentoLink',i)" class="col-12 col-md-3 col-form-label mb-0 text-xl-end">Redirecionamento Link:</label>
                                        <div class="col-12 col-md-9">
                                            <input type="text" [id]="gerarIdInput('redirecionamentoLink',i)" class="form-control" formControlName="redirecionamentoLink">
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                    </div>
                </ng-container>
            </ng-container>
        </fieldset>
    </div>

    <div class="row mt-3" *ngIf="mostrarBarraProgresso == true">
        <div class="col col-md-6">
            <label for="indiceBarraProgresso" class="form-label mb-0">Indice da Barra de Progresso</label>
            <input type="number" min="1" class="form-control" id="indiceBarraProgresso" formControlName="indiceBarraProgresso">
        </div>
        <div class="col col-md-6">
            <label for="totalBarraProgresso" class="form-label mb-0">Tota de Indice da Barra de Progresso</label>
            <input type="number" min="1" class="form-control" id="totalBarraProgresso" formControlName="totalIndiceBarraProgresso">
        </div>
    </div>

    <div class="row mt-3 mb-3" *ngIf="formMarcacaoUsuario.get('ultima')?.value == true">

        <div class="col-12 col-lg-6">
            <label for="finaliza" class="form-label mb-0 me-1">Finaliza Fluxo? Sim</label>
            <input type="checkbox" id="finaliza" class="form-check-input" formControlName="finalizaFluxo">
        </div>

        <div class="col-12 col-lg-6" *ngIf="formMarcacaoUsuario.get('finalizaFluxo')?.value == true">
            <label for="principal" class="form-label mb-0 me-1">Fluxo Principal? Sim</label>
            <input type="checkbox" id="principal" class="form-check-input" formControlName="fluxoPrincipal">
        </div>

        <div class="col-12 mt-2" *ngIf="(formMarcacaoUsuario.get('finalizaFluxo')?.value == true) && formMarcacaoUsuario.get('fluxoPrincipal')?.value == false">
            <label for="nomefluxo" class="form-label mb-0 me-1">Nome do Fluxo: </label>
            <input type="text" id="nomefluxo" class="form-control" formControlName="nomeFluxo">
        </div>

    </div>

</form>
