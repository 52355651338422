import { NgModule } from "@angular/core";
import { ListaPerguntasComponent } from "./lista-perguntas.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PerguntaService } from "src/app/servicos/pergunta.service";
import { HttpClientModule } from "@angular/common/http";
import { SpinnerModule } from "src/app/compartilhados/componentes/spinners/spinner.module";

@NgModule({
    declarations: [
        ListaPerguntasComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        SpinnerModule
    ],
    exports: [
        ListaPerguntasComponent
    ],
    providers: [
        PerguntaService
    ]
})
export class ListaPerguntasModule{

}
