import { NgModule } from "@angular/core";
import { RouterModule, Routes} from "@angular/router";
import { ModuloGuardService } from "src/app/servicos/modulo-guard.service";
import { AdmFormComponent } from "./adm-fom.component";

const routes: Routes = [
    {
        path: '',
        component: AdmFormComponent,
        canActivate: [ModuloGuardService]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdmFormRoutingModule {

}
