import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OpenGraph } from "../modelos/OpenGraph.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class OpenGraphService{

    private url: string = `${environment.apiBackEnd}/api/v1/openGraph`;

    constructor(
        private http: HttpClient
    ){}

    public cadastrar(dados: OpenGraph): Observable<OpenGraph>{
        return this.http.post<OpenGraph>(this.url,dados);
    }

    public alterar(id: number, dados: OpenGraph): Observable<OpenGraph>{
        let urlLocal = `${this.url}/${id}`;
        return this.http.put<OpenGraph>(urlLocal,dados);
    }

}