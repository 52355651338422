<div class="row ms-1">
    <div class="col">
        <button type="button" title="Gravar todas as últimas alterações realizadas" (click)="atualizarPerguntas()" class="btn btn-sm btn-primary text-end me-1" data-test="atualizar-perguntas"><i class="fa-solid fa-pen-to-square me-1"></i>Atualizar Perguntas</button>
        <form-burlador (listarBurladores)="listarBurladores($event)" [idFormulario]="idFormulario"></form-burlador>
    </div>
</div>

<div class="row mt-3 ">
    <div class="col">
        <h6 class="text-start ms-3"><i class="fa-solid fa-hashtag me-1"></i>Editar Pergunta:</h6>
    </div>
</div>

<div class="row justify-content-center mb-2">

    <div class="col-11 col-lg-10 mt-3" *ngIf="pergunta != null && pergunta.resposta">

        <form-pergunta-texto *ngIf="pergunta.resposta.tipoResposta != tipoResposta.ALTERNATIVA_UNICA
            && pergunta.resposta.tipoResposta != tipoResposta.ALTERNATIVA_MULTIPLA
            && pergunta.resposta.tipoResposta != tipoResposta.INFORMATIVO_VIDEO
            && pergunta.resposta.tipoResposta != tipoResposta.ARQUIVO
            && pergunta.resposta.tipoResposta != tipoResposta.MARCACAO_USUARIO
            && pergunta.resposta.tipoResposta != tipoResposta.HTML 
            && pergunta.resposta.tipoResposta != tipoResposta.INFORMATIVO_VIDEO_PERSONALIZADO
            && pergunta.resposta.tipoResposta != tipoResposta.ALTERNATIVA_UNICA_DROPDOWN
            && pergunta.resposta.tipoResposta != tipoResposta.ALTERNATIVA_UNICA_COMPACTA" [pergunta]="pergunta"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [estiloFormulario]="estiloFormulario"
             [perguntas]="perguntas" [variaveis]="variaveis" (perguntaSalva)="onPerguntaSalva($event)">
        </form-pergunta-texto>

        <form-pergunta-alternativa-unica  *ngIf="pergunta.resposta.tipoResposta == tipoResposta.ALTERNATIVA_UNICA"
            [pergunta]="pergunta" [perguntas]="perguntas" [variaveis]="variaveis"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [estiloFormulario]="estiloFormulario"
            [burladores]="burladores"
            (perguntaSalva)="onPerguntaSalva($event)"></form-pergunta-alternativa-unica>

        <form-pergunta-alternativa-multipla *ngIf="pergunta.resposta.tipoResposta == tipoResposta.ALTERNATIVA_MULTIPLA"
            [pergunta]="pergunta" [perguntas]="perguntas" [variaveis]="variaveis"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [estiloFormulario]="estiloFormulario"
                (perguntaSalva)="onPerguntaSalva($event)"></form-pergunta-alternativa-multipla>

        <form-pergunta-video *ngIf="pergunta.resposta.tipoResposta == tipoResposta.INFORMATIVO_VIDEO"
            [pergunta]="pergunta" [perguntas]="perguntas" [variaveis]="variaveis"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [estiloFormulario]="estiloFormulario"
                (perguntaSalva)="onPerguntaSalva($event)"></form-pergunta-video>

        <form-arquivo *ngIf="pergunta.resposta.tipoResposta == tipoResposta.ARQUIVO"
            [pergunta]="pergunta" [perguntas]="perguntas" [variaveis]="variaveis"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            (perguntaSalva)="onPerguntaSalva($event)"></form-arquivo>

        <form-marcacao-usuario *ngIf="pergunta.resposta.tipoResposta == tipoResposta.MARCACAO_USUARIO"
            [pergunta]="pergunta" [perguntas]="perguntas" [variaveis]="variaveis"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [estiloFormulario]="estiloFormulario"
            (perguntaSalva)="onPerguntaSalva($event)"></form-marcacao-usuario>

        <form-pergunta-html *ngIf="pergunta.resposta.tipoResposta == tipoResposta.HTML"
            [pergunta]="pergunta" [perguntas]="perguntas" [variaveis]="variaveis"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [estiloFormulario]="estiloFormulario"
            (perguntaSalva)="onPerguntaSalva($event)"></form-pergunta-html>

        <form-pergunta-video-personalizado *ngIf="pergunta.resposta.tipoResposta == tipoResposta.INFORMATIVO_VIDEO_PERSONALIZADO"
            [pergunta]="pergunta" [perguntas]="perguntas" [variaveis]="variaveis"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [estiloFormulario]="estiloFormulario"
                (perguntaSalva)="onPerguntaSalva($event)"></form-pergunta-video-personalizado>

        <form-pergunta-alternativa-unica-dropdown  *ngIf="pergunta.resposta.tipoResposta == tipoResposta.ALTERNATIVA_UNICA_DROPDOWN"
            [pergunta]="pergunta" [perguntas]="perguntas" [variaveis]="variaveis"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [estiloFormulario]="estiloFormulario"
            [burladores]="burladores"
                (perguntaSalva)="onPerguntaSalva($event)"></form-pergunta-alternativa-unica-dropdown>

        <form-alternativa-unica-compacta  *ngIf="pergunta.resposta.tipoResposta == tipoResposta.ALTERNATIVA_UNICA_COMPACTA"
            [pergunta]="pergunta" [perguntas]="perguntas" [variaveis]="variaveis"
            [mostrarBarraProgresso]="exibeBarraProgresso"
            [estiloFormulario]="estiloFormulario"
            [burladores]="burladores"
                (perguntaSalva)="onPerguntaSalva($event)"></form-alternativa-unica-compacta>        

    </div>

</div>
