<div class="row">
    <div class="col-12">
        <h6 class="text-start ms-2"><i class="fa-solid fa-hashtag me-1"></i>Pastas:</h6>
    </div>
    <div class="col-12">
        <div class="text-start ms-2">
            <button type="button" title="Adicionar uma pasta para guardar/organizar os formulários" class="btn btn-sm btn-primary me-1" data-bs-toggle="modal" [dataBsTarget]="getId('modal-nova-pasta')"><i class="fa-solid fa-plus"></i></button>
            <button type="button" title="Editar o nome da Pasta" class="btn btn-sm btn-primary me-1" (click)="abrirModalEditarPasta()"><i class="fa-solid fa-pen-to-square"></i></button>
            <button type="button" title="Excluir a pasta selecionada" class="btn btn-sm btn-danger me-1" (click)="excluirPasta()" ><i class="fa-solid fa-minus"></i></button>
            <button type="button" title="Adicionar ou remova formulários de dentro da pasta selecionada" class="btn btn-sm btn-primary" (click)="abrirModalAddRmvConteudo()"><i class="fa-solid fa-up-down"></i></button>
        </div>
    </div>
</div>

<div class="accordion mt-2 ms-2" *ngIf="pastas.length > 0">
    <div class="accordion-item" *ngFor="let pasta of pastas; let i = index" #p>
        <h2 class="accordion-header">
            <button class="btn-pasta" type="button" (click)="alternarPainelPasta(p,pasta)">
                <div class="row">

                    <div class="col-2 d-flex align-items-center fs-4">
                        <i class="fa-solid fa-folder" *ngIf="!isPastaSelecionada(pasta)"></i>
                        <i class="fa-solid fa-folder-open" *ngIf="isPastaSelecionada(pasta)"></i>
                    </div>

                    <div class="col-10 d-flex align-items-center">
                        <div class="row">
                            <div class="col-12">
                                <span [ngClass]="{'fw-bolder': isPastaSelecionada(pasta)}">{{pasta.nome | uppercase}}</span>
                            </div>
                            <div class="col-12">
                                <span class="informacoes-pasta" *ngIf="buscarQtdConteudos(pasta) == 0">{{buscarQtdConteudos(pasta)}} {{assuntoConteudo}}</span>
                                <span class="informacoes-pasta" *ngIf="buscarQtdConteudos(pasta) > 0">{{buscarQtdConteudos(pasta)}} {{assuntoConteudo}}(s)</span>
                            </div>
                            <div class="col-12">
                                <span class="informacoes-pasta">Última Modificação: {{pasta.dataUltimaModificacao}}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row justify-content-center">
                    <div class="col-2 d-flex align-items-center justify-content-center">
                        <i class="fa-solid fa-chevron-up btn-icone"></i>
                    </div>
                </div>
            </button>
        </h2>
        <div [id]="gerarIdPainelPasta(pasta)" class="fechar-suave">
            <div class="accordion-body">

                <ul class="list-group list-group-flush mt-1" *ngIf="buscarConteudos(pasta) != undefined">
                    <ng-container *ngFor="let conteudo of buscarConteudos(pasta)">
                        <button type="button" (click)="selecionarConteudo(conteudo)" class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between">
                                <p>{{conteudo.nome | uppercase}}</p>
                                <span class="text-end" *ngIf="conteudoSelecionado == conteudo"><i class="fa-solid fa-right-long"></i></span>
                            </div>
                        </button>
                    </ng-container>
                </ul>

                <span *ngIf="buscarQtdConteudos(pasta) == 0" style="color: #DCDCDC;font-style: italic;">Pasta vazia!</span>

            </div>
        </div>
    </div>
</div>

<span *ngIf="pastas.length == 0" class="ms-2 mt-1" style="color: #DCDCDC;font-style: italic;">Sem pastas no momento!</span>

<div class="modal fade" tabindex="-1" [id]="getId('modal-nova-pasta')">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Nova Pasta</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row justify-content-center">

                        <div class="col-12">

                            <form [formGroup]="formNovaPasta">
                                <label for="nomeP" class="form-label mb-0 mt-3">Nova Pasta:</label>
                                <input type="text" id="nomeP" class="form-control" formControlName="nome" />
                            </form>

                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i
                        class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" (click)="cadastrarPasta()" [disabled]="formNovaPasta.invalid"
                    class="btn btn-primary" data-bs-dismiss="modal"><i
                        class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" tabindex="-1" [id]="getId('modal-editar-pasta')">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Editar Pasta</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row justify-content-center">

                        <div class="col-12">

                            <form [formGroup]="formEditarPasta">

                                <label for="nomePE" class="form-label mb-0 mt-3">Nome da Pasta:</label>
                                <input type="text" id="nomePE" class="form-control" formControlName="nome" />

                            </form>

                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i
                        class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" (click)="editarPasta()" [disabled]="formEditarPasta.invalid"
                    class="btn btn-primary" data-bs-dismiss="modal"><i
                        class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" tabindex="-1" [id]="getId('modal-add-conteudo-pasta')">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Gerenciar {{assuntoConteudo}} da Pasta</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row justify-content-center">

                        <div class="col-12">

                            <p *ngIf="pastaSelecionada != null">Pasta: {{pastaSelecionada.nome}}</p>

                            <p class="mb-0">{{assuntoConteudo}}:</p>
                            <div class="espaco-add-conteudo">
                                <ng-container *ngFor="let conteudo of conteudos">
                                    <input type="checkbox" *ngIf="!verificarConteudoNaPastaSelecionada(conteudo)" class="form-check-input me-1" (change)="alternarConteudo($event,conteudo)">
                                    <input type="checkbox" checked *ngIf="verificarConteudoNaPastaSelecionada(conteudo)" class="form-check-input me-1" (change)="alternarConteudo($event,conteudo)">
                                    <label class="form-check-label">{{conteudo?.nome | uppercase}}</label>
                                    <br/>
                                </ng-container>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i
                        class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                    (click)="salvarConteudoNaPasta()"><i class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>
