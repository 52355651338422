import { Component, DoCheck, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ReplaySubject } from "rxjs";
import { Alternativa, EstiloAlternativa, Pergunta } from "src/app/modelos/pergunta.model";
import { TipoResposta } from "src/app/modelos/tipo-resposta.model";
import { FormPerguntaService } from "../form-pergunta.service";
import { EstiloFormulario } from "src/app/modelos/formulario.model";

@Component({
    selector: 'form-marcacao-usuario',
    templateUrl: './form-marcacao-usuario.component.html',
    styleUrls: ['./form-marcacao-usuario.component.css']
})
export class FormMarcacaoUsuarioComponent implements OnChanges, DoCheck{

    @Input() pergunta!: Pergunta;
    @Input() perguntas!: Array<Pergunta> | null;

    @Input() mostrarBarraProgresso: boolean = false;
    @Input() estiloFormulario!: EstiloFormulario | undefined | null;

    @Output() perguntaEditada: EventEmitter<Pergunta> = new EventEmitter();
    @Output() perguntaSalva: EventEmitter<Pergunta> = new EventEmitter();
    @Output() perguntaCadastrar: EventEmitter<Pergunta> = new EventEmitter();

    public formMarcacaoUsuario!: FormGroup;

    @Input() variaveis!: Array<string>;
    public identificadores: string[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private formPerguntaService: FormPerguntaService
    ){}

    ngOnChanges(){

        this.restaurarUltimaVersaoPergunta();

        console.log(this.pergunta);

        this.formMarcacaoUsuario = this.formBuilder.group({
            id: [this.pergunta.id,[Validators.required]],
            posicao: [this.pergunta.posicao,[Validators.required]],
            identificador: [this.pergunta.identificador,[Validators.required]],
            titulo: [{value: this.pergunta.titulo, disabled: true},[Validators.required]],
            descricao: [{value: this.pergunta.descricao, disabled: true},[Validators.required]],
            exibeImagem: [this.pergunta.exibeImagem,[]],
            imagemBase64: [this.pergunta.imagemBase64,[]],
            urlImagemBase64: [{value: this.pergunta.chaveImagem, disabled: true},[]],
            ultima: [false,[Validators.required]],
            opcaoRedirecionamento: [this.pergunta.opcaoRedirecionamento,[]],
            redirecionamentoPergunta: [this.pergunta.redirecionamentoPergunta,[]],
            redirecionamentoLink: [this.pergunta.redirecionamentoLink,[]],
            indiceBarraProgresso: [this.pergunta.indiceBarraProgresso,[]],
            totalIndiceBarraProgresso: [this.pergunta.totalIndiceBarraProgresso,[]],
            finalizaFluxo: [this.pergunta.finalizaFluxo,[]],
            fluxoPrincipal: [this.pergunta.fluxoPrincipal,[]],
            nomeFluxo: [this.pergunta.nomeFluxo,[]],
            resposta: this.formBuilder.group({
                id: [this.pergunta.resposta.id,[Validators.required]],
                tipoResposta: [{value: this.pergunta.resposta.tipoResposta,disabled: true},[Validators.required]],
                guarda: [this.pergunta.resposta.guarda,[Validators.required]],
                placeholder: [this.pergunta.resposta.placeholder,[]],
                alternativas: this.formBuilder.array([]),
                exibirLetraAlternativa: [this.pergunta.resposta.exibirLetraAlternativa,[]]
            })
        });
        this.pergunta.resposta.alternativas?.forEach(
            alternativa => {
                this.formAlternativas.push(this.criarAlternativa(alternativa));
            }
        );
        this.identificadores = (this.perguntas as Pergunta[]).filter(p =>
            p.resposta.tipoResposta != TipoResposta.INFORMATIVO_TEXTO)
            .filter(p => p.resposta.tipoResposta != TipoResposta.INFORMATIVO_VIDEO)
            .filter(p => p.resposta.tipoResposta != TipoResposta.INFORMATIVO_VIDEO_PERSONALIZADO)
            .map(p => p.identificador);
    }

    private restaurarUltimaVersaoPergunta(){
        let p = this.formPerguntaService.getPerguntaUltimaVersao(this.pergunta.id);
        if(p) this.pergunta = p;
    }

    ngDoCheck(): void {
        let f = this.formMarcacaoUsuario.getRawValue() as Pergunta;
        this.formPerguntaService.addPerguntaAlterar(f);
    }

    private criarAlternativa(alternativa?: Alternativa): FormGroup{
        return this.formBuilder.group({
            id: [alternativa?.id,[Validators.required]],
            titulo: [alternativa?.titulo,[Validators.required]],
            ultima: [alternativa?.ultima,[Validators.required]],
            redirecionamentoPergunta: [alternativa?.redirecionamentoPergunta,[]],
            redirecionamentoLink: [alternativa?.redirecionamentoLink,[]],
            estiloProprio: [alternativa?.estiloProprio,[]],
            estilo: this.formBuilder.group({
                corTitulo: [alternativa?.estilo?.corTitulo  ? alternativa?.estilo?.corTitulo : '#ffffff',[]],
                corFundo: [alternativa?.estilo?.corFundo ?  alternativa?.estilo?.corFundo : '#413877',[]],
                corLetra: [alternativa?.estilo?.corLetra ?  alternativa?.estilo?.corLetra : '#413877',[]],
                corFundoLetra: [alternativa?.estilo?.corFundoLetra ? alternativa?.estilo?.corFundoLetra : '#ffffff',[]],
                corTituloSelecionada: [alternativa?.estilo?.corTituloSelecionada ? alternativa?.estilo?.corTituloSelecionada : '#413877',[]],
                corFundoSelecionado: [alternativa?.estilo?.corFundoSelecionado ?  alternativa?.estilo?.corFundoSelecionado : '#ffffff',[]],
                corLetraSelecionada: [alternativa?.estilo?.corLetraSelecionada ? alternativa?.estilo?.corLetraSelecionada : '#ffffff',[]],
                corFundoLetraSelecionado: [alternativa?.estilo?.corFundoLetraSelecionado ? alternativa?.estilo?.corFundoLetraSelecionado : '#413877',[]],
                corBorda: [alternativa?.estilo?.corBorda ? alternativa.estilo.corBorda : '#413877', []],
                corBordaSelecionada: [alternativa?.estilo?.corBordaSelecionada ? alternativa.estilo.corBordaSelecionada : '#413877', []],
                corBordaLetra: [alternativa?.estilo?.corBordaLetra ? alternativa.estilo.corBordaLetra : '#ffffff', []],
                corBordaLetraSelecionada: [alternativa?.estilo?.corBordaLetraSelecionada ? alternativa.estilo.corBordaLetraSelecionada : '#413877', []],
                exibirIconeEsquerdo: [alternativa?.estilo?.exibirIconeEsquerdo,[]],
                iconeEsquerdo: [alternativa?.estilo?.iconeEsquerdo,[]],
                exibirIconeDireito: [alternativa?.estilo?.exibirIconeDireito,[]],
                iconeDireito: [alternativa?.estilo?.iconeDireito,[]]
            })
        });
    }

    get formAlternativas(): FormArray{
        return this.formMarcacaoUsuario.get('resposta')?.get('alternativas') as FormArray;
    }

    public addAlternativa(){
        this.formAlternativas.push(this.criarAlternativa());
    }

    public rmvAlternativa(index: number){
        this.formAlternativas.removeAt(index);
    }

    public guardaPergunta(){
        let f = this.formMarcacaoUsuario.getRawValue() as Pergunta;
        this.perguntaSalva.emit(f);
    }

    public getTitulo():string{
        return this.formMarcacaoUsuario.get('titulo')?.value as string;
    }

    public getDescricao():string{
        return this.formMarcacaoUsuario.get('descricao')?.value as string;
    }

    public unicaFormatadoTitulo(texto: string){
        this.formMarcacaoUsuario.get('titulo')?.setValue(texto);
    }

    public unicaFormatadoDescricao(texto: string){
        this.formMarcacaoUsuario.get('descricao')?.setValue(texto);
    }

    public gerarIdFormatador(radical: string){
        return `unica${radical}${this.pergunta.identificador}`;
    }

    public gerarIdInput(prefixo:string,id: number):string{
        return `${prefixo}-${id}`;
    }

    public gerarIdVisualizador(radical: string){
        return `visualizador${radical}${this.pergunta.identificador}`;
    }

    public tratarArquivo(event: any){

        let file = event.target.files[0] as File;

        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(window.btoa(event.target?.result?.toString() as string));

        result.subscribe({
            next: arquivoBase64 => {
                this.formMarcacaoUsuario.get('imagemBase64')?.setValue(arquivoBase64);
            }
        });

    }

    public getEstiloAlternativa(index:number): EstiloAlternativa{
        return this.formAlternativas.controls[index]?.get('estilo')?.getRawValue() as EstiloAlternativa;
    }

    public setEstiloAlternativa(index:number,estilo: EstiloAlternativa){
        let formEstilo = this.formAlternativas.controls[index].get('estilo');
        formEstilo?.get('corTitulo')?.setValue(estilo.corTitulo);
        formEstilo?.get('corFundo')?.setValue(estilo.corFundo);
        formEstilo?.get('corLetra')?.setValue(estilo.corLetra);
        formEstilo?.get('corFundoLetra')?.setValue(estilo.corFundoLetra);
        formEstilo?.get('corTituloSelecionada')?.setValue(estilo.corTituloSelecionada);
        formEstilo?.get('corFundoSelecionado')?.setValue(estilo.corFundoSelecionado);
        formEstilo?.get('corLetraSelecionada')?.setValue(estilo.corLetraSelecionada);
        formEstilo?.get('corFundoLetraSelecionado')?.setValue(estilo.corFundoLetraSelecionado);
        formEstilo?.get('corBorda')?.setValue(estilo.corBorda);
        formEstilo?.get('corBordaSelecionada')?.setValue(estilo.corBordaSelecionada);
        formEstilo?.get('corBordaLetra')?.setValue(estilo.corBordaLetra);
        formEstilo?.get('corBordaLetraSelecionada')?.setValue(estilo.corBordaLetraSelecionada);
        formEstilo?.get('exibirIconeEsquerdo')?.setValue(estilo.exibirIconeEsquerdo);
        formEstilo?.get('iconeEsquerdo')?.setValue(estilo.iconeEsquerdo);
        formEstilo?.get('exibirIconeDireito')?.setValue(estilo.exibirIconeDireito);
        formEstilo?.get('iconeDireito')?.setValue(estilo.iconeDireito);
    }


}
