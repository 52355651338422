<button type="button" title="Pré visualização da pergunta no PrevNator" title="Alterar o posicionamento da imagem na pergunta"
    class="btn btn-sm btn-primary d-inline" data-bs-toggle="modal"
    [dataBsTarget]="gerarId()"><i class="fa-solid fa-border-top-left"></i>
</button>

<div class="modal fade" tabindex="-1" [id]="gerarId()">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Ajustes da Pergunta</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row">

                        <div class="col-4 border-end">
                            <form [formGroup]="formAjustes">

                                <fieldset *ngIf="pergunta.exibeImagem == true">
                                    <legend>Ajustes da imagem</legend>
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="alturaImagem" class="form-label mb-0">Altura:</label>
                                            <input type="number" class="form-control" id="alturaBoxImagem" min="1" max="220" formControlName="alturaBoxImagem"/>
                                        </div>
                                        <div class="col-6">
                                            <label for="margemTop" class="form-label mb-0">Margem:</label>
                                            <input type="number" class="form-control" id="margemTop" min="0" formControlName="margemTopBoxImagem">
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset *ngIf="pergunta.resposta.tipoResposta == 'Informativo Video'">
                                    <legend>Ajustes do video</legend>
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="alturaImagemVideo" class="form-label mb-0">Altura:</label>
                                            <input type="number" class="form-control" id="alturaImagemVideo" min="1" max="220" formControlName="alturaBoxVideo"/>
                                        </div>
                                        <div class="col-6">
                                            <label for="margemTopVideo" class="form-label mb-0">Margem:</label>
                                            <input type="number" class="form-control" id="margemTopVideo" min="0" formControlName="margemTopBoxVideo">
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset *ngIf="pergunta.resposta.tipoResposta == 'Html'">
                                    <legend>Ajustes do html</legend>
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="alturaImagemHtml" class="form-label mb-0">Altura:</label>
                                            <input type="number" class="form-control" id="alturaImagemHtml" min="1" max="220" formControlName="alturaBoxHtml"/>
                                        </div>
                                        <div class="col-6">
                                            <label for="margemTopHtml" class="form-label mb-0">Margem:</label>
                                            <input type="number" class="form-control" id="margemTopHtml" min="0" formControlName="margemTopBoxHtml">
                                        </div>
                                    </div>
                                </fieldset>

                            </form>
                        </div>

                        <div class="col-8">

                            <h6 class="text-center">Pré visualização:</h6>

                            <div class="box-visualizacao" [style]="getCorEFundoEscolhido()">

                                <div class="box-pergunta">

                                    <h5 class="text-start mt-3" [style]="getFontECorEscolhida()" [innerHTML]="pergunta.titulo" *ngIf="pergunta.exibirTitulo"></h5>

                                    <div class="box-imagem" [style]="getEstiloBoxImagem()" *ngIf="pergunta.exibeImagem == true"></div>

                                    <div class="box-video" [style]="getEstiloBoxVideo()" *ngIf="pergunta.resposta.tipoResposta == 'Informativo Video'"></div>

                                    <div class="box-html" [style]="getEstiloBoxHtml()" *ngIf="pergunta.resposta.tipoResposta == 'Html'"></div>

                                    <h5 class="texto-imagem mt-2" [style]="getFontECorEscolhida()" [innerHTML]="pergunta.textoImagem" *ngIf="pergunta?.exibeImagem && pergunta?.exibeTextoImagem"></h5>

                                    <p class="p-1" style="text-align: justify;" [style]="getFontECorEscolhida()" *ngIf="pergunta.resposta.tipoResposta != 'Html'">{{pergunta.descricao}}</p>

                                    <input type="text" class="ipt mb-1" [style]="getFontECorEscolhida()" *ngIf="pergunta.resposta.tipoResposta == 'Texto' || pergunta.resposta.tipoResposta == 'Email' || pergunta.resposta.tipoResposta == 'Celular' ">

                                    <ng-container *ngIf="pergunta.resposta.tipoResposta == 'Alternativa Múltipla' || pergunta.resposta.tipoResposta == 'Alternativa Única' || pergunta.resposta.tipoResposta == 'Marcação de Usuário'  ">

                                        <ng-container *ngFor="let alternativa of pergunta?.resposta?.alternativas; let i = index">
                                            <div class="base-btn-alternativa btn-alternativa-padrao mt-2" *ngIf="alternativa?.estiloProprio == false">
                                                <span class="base-letra-alternativa letra-alternativa-padrao  ms-1 me-1">{{getLetraAlternativa(i) | uppercase}}</span>
                                                {{alternativa.titulo}}
                                            </div>
                                            <div class="base-btn-alternativa mt-2" [style]="estiloPersonalizadoBtnAlternativa(alternativa)" *ngIf="alternativa?.estiloProprio == true">
                                                <span class="base-letra-alternativa ms-1 me-1" [style]="estiloPersonalizadoLetraBtnAlternativa(alternativa)">{{getLetraAlternativa(i) | uppercase}}</span>
                                                {{alternativa.titulo}}
                                            </div>
                                        </ng-container>

                                    </ng-container>

                                    <button type="button" class="btn-voltar" *ngIf="pergunta.posicao > 1">Voltar</button>

                                    <!--
                                    <button type="button" class="btn-proximo" *ngIf="pergunta?.textoBotaoPersonalizado == false">Continuar</button>
                                    <button type="button" class="btn-proximo" *ngIf="pergunta?.textoBotaoPersonalizado == true">{{pergunta.textoBotao}}</button>-->

                                </div>

                                <div class="container-progresso" *ngIf="estilo?.mostrarBarraProgresso == true">
                                    <span class="texto-progresso" [ngStyle]="{'color': getCorPassoProgresso()}">Passo {{pergunta.indiceBarraProgresso}} de {{pergunta.totalIndiceBarraProgresso}}</span>
                                    <div class="box-progresso">
                                        <div class="barra-progresso" [ngStyle]="{'background-color': getCorBarraProgresso()}" [style]="calcularBarraProgresso()"></div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" (click)="salvar()" class="btn btn-primary" data-bs-dismiss="modal"><i class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>
