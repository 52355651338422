import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Alternativa, Pergunta } from "src/app/modelos/pergunta.model";
import { TipoResposta } from "src/app/modelos/tipo-resposta.model";
import { PerguntaService } from "src/app/servicos/pergunta.service";

@Injectable()
export class FormPerguntaService{

    private perguntasASeremAlteradas: Map<number,Pergunta> = new Map<number,Pergunta>();

    constructor(
        private perguntaService: PerguntaService
    ){}

    public addPerguntaAlterar(pergunta: Pergunta){
        this.perguntasASeremAlteradas.set(pergunta.id,pergunta);
    }

    public getPerguntaUltimaVersao(chave: number): Pergunta | undefined{
        return this.perguntasASeremAlteradas.get(chave);
    }

    public podeAtualizar(): boolean{
        let perguntas: Pergunta[] = []
        for(let p of this.perguntasASeremAlteradas.values()){
            perguntas.push(p);
        }
        if(perguntas.length == 0) return false;
        return true;
    }

    public validarPerguntas(): boolean{
        let perguntas: Pergunta[] = [];
        for(let p of this.perguntasASeremAlteradas.values()){
            perguntas.push(p);
        }
        let resultado = true;
        for(let i=0; i < perguntas.length; i++){

            if(perguntas[i].resposta.tipoResposta != TipoResposta.MARCACAO_USUARIO
                && perguntas[i].resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA && 
                    perguntas[i].resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA_DROPDOWN &&
                    perguntas[i].resposta.tipoResposta != TipoResposta.ALTERNATIVA_UNICA_COMPACTA){

                if( perguntas[i].ultima == false && perguntas[i].redirecionamentoPergunta == 0){
                    resultado = false;
                    break;
                }

            }else{
                let num = perguntas[i].resposta.alternativas?.length as number;
                for(let j=0; j < num ; j++){
                    let p = (perguntas[i].resposta?.alternativas as Alternativa[])[j];
                    if( p.ultima == false && p.redirecionamentoPergunta == 0){
                        resultado = false;
                        break;
                    }
                    if(p.redirecionamentoLink && p.finalizaFluxo == false) {
                        resultado = false;
                        break;
                    }
                    if(p.redirecionamentoLink 
                        && p.finalizaFluxo && !p.fluxoPrincipal && !p.nomeFluxo){
                        resultado = false;
                        break;
                    }
                    
                }
                if(resultado == false) break;
            }

        }
        return resultado;
    }

    public atualizarPerguntas(): Observable<Pergunta[]>{
        let perguntas: Pergunta[] = []
        for(let p of this.perguntasASeremAlteradas.values()){
            perguntas.push(p);
        }
        this.perguntasASeremAlteradas.clear();
        return this.perguntaService.atualizarPerguntas(perguntas);
    }

    public atualizarPerguntasSalvas(perguntas: Pergunta[]){
        this.perguntasASeremAlteradas.clear();
        perguntas.forEach(pergunta => {
            this.perguntasASeremAlteradas.set(pergunta.id,pergunta);
        });
    }

}
