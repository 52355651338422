import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CabecalhoComponent } from './compartilhados/cabecalho/cabecalho.component';
import { SpinnerModule } from './compartilhados/componentes/spinners/spinner.module';
import { AdmFormModule } from './modulos_principais/adm-form/adm-form.module';
import { AutenticacaoModule } from './modulos_principais/autenticacao/autenticacao.module';
import { InterceptorService } from './servicos/interceptador.service';
import { TokenService } from './servicos/token.service';
import { UsuarioLogadoService } from './servicos/usuario-logado.service';
import { UsuarioService } from './servicos/usuario.service';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
    declarations: [
        AppComponent,
        CabecalhoComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AutenticacaoModule,
        AdmFormModule,
        SpinnerModule,
        MatNativeDateModule
    ],
    providers: [
        UsuarioLogadoService,
        TokenService,
        UsuarioService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass:  InterceptorService,
            multi: true
        },
    ],
    bootstrap: [
        AppComponent
    ],
})
export class AppModule {}
