<button type="button" [title]="descricao" class="btn btn-sm btn-outline-primary d-inline" data-bs-toggle="modal"
    [dataBsTarget]="gerarId()" (click)="abrirModal()" data-test="btn-editar-texto">
    <i class="fa-solid fa-pencil"></i>
</button>

<div class="modal fade" tabindex="-1" [id]="gerarId()">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Formatador de Texto</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row">

                        <div class="col-12">
                            <label for="texto">Texto:</label>
                            <textarea class="form-control" [id]="gerarIdTexto()" rows="4" #t (keyup)="eventoTextoDigitado(t.value)" data-test="texto-editado"></textarea>
                        </div>

                        <div class="mt-1" style="font-size: 11px">
                            <span class="d-block">*Selecione o trecho que deseja aplicar o estilo</span>
                            <span class="d-block">*Selecione o trcho incluindo a tag para desfazer o estilo</span>
                        </div>

                        <div class="col-12 mt-3 d-md-flex">
                            <button type="button" (click)="estilo(0)" class="btn btn-sm btn-outline-secondary me-2 fw-bolder">Negrito</button>
                            <button type="button" (click)="estilo(1)" class="btn btn-sm btn-outline-secondary me-2 fst-italic">Italico</button>
                            <button type="button" (click)="estilo(2)" class="btn btn-sm btn-outline-secondary me-2 text-decoration-underline">Sublinhado</button>

                            <label class="mt-2 me-2">Variáveis:</label>
                            <select class="form-select" [id]="gerarIdSelecionarVariavel()" (change)="colocarVariavel()">
                                <option value="0">Selecione uma variável</option>
                                <ng-container *ngFor="let variavel of variaveis">
                                    <option [value]="variavel">{{variavel | uppercase}}</option>
                                </ng-container>
                            </select>

                        </div>

                        <div class="col-12 mt-3 d-md-flex align-items-center">
                            <label for="destaque-cor" class="form-label me-1">Cor Destaque: </label>
                            <input type="color" id="destaque-cor" class="form-control form-control-color" (change)="aplicarCorDestaque($event)">
                        </div>

                        <div class="col-12 mt-4">
                            <h5>Resultado:</h5>
                            <p [innerHTML]="texto"></p>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" (click)="salvar()" class="btn btn-primary" data-bs-dismiss="modal" data-test="btn-salvar-texto-editado"><i class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>
