import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { EstiloAlternativa } from "src/app/modelos/pergunta.model";

@Component({
    selector: 'form-formata-estilo-alternativa',
    templateUrl: './form-formata-estilo-alternativa.component.html',
    styleUrls: ['./form-formata-estilo-alternativa.component.css']
})
export class FormFormataEstiloAlternativaComponent implements OnChanges, AfterViewInit{

    @Input() id!: string;
    @Input() titulo:string = "Alternativa";
    @Input() estiloDefinido!: EstiloAlternativa;

    @Input() tituloModal: string = "Personalizar Botão Alternativa";

    @Input() ativarLetra: boolean = true;

    @Input() btnAvancar: boolean = false;

    @Output()
    public estilo: EventEmitter<EstiloAlternativa> = new EventEmitter<EstiloAlternativa>();

    @ViewChild('btnAlternativa')
    public btnAlternativa!: ElementRef<HTMLButtonElement>;

    @ViewChild('btnAlternativaSelecionado')
    public btnAlternativaSelecionado!: ElementRef<HTMLButtonElement>;

    public formBtn!: FormGroup;
    public formBtnSelecionado!: FormGroup;
    public formIconesBtn!: FormGroup;

    constructor(
        private formBuilder: FormBuilder
    ){
        this.formBtn = this.formBuilder.group({
            corTitulo: ['',[]], corFundo: ['',[]],
            corLetra: ['',[]],  corFundoLetra: ['',[]],
            corBorda: ['',[]],  corBordaLetra: ['',[]]
        });
        this.formBtnSelecionado = this.formBuilder.group({
            corTituloSelecionada: ['',[]], corFundoSelecionado: ['',[]],
            corLetraSelecionada: ['',[]],  corFundoLetraSelecionado: ['',[]],
            corBordaSelecionada: ['',[]],  corBordaLetraSelecionada: ['',[]]
        });
        this.formIconesBtn = this.formBuilder.group({
            exibirIconeEsquerdo: [false,[]], iconeEsquerdo: ['',[]],
            exibirIconeDireito: [false,[]], iconeDireito: ['',[]]
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['id'] && changes['id'].previousValue != this.id){
            this.setValores();
        }
        this.formatarBtns();
    }

    ngAfterViewInit(): void {
        this.formatarBtns();
    }

    private setValores(){

        this.formBtn.get('corTitulo')?.setValue((this.estiloDefinido.corTitulo && this.estiloDefinido?.corTitulo != '') ? this.estiloDefinido.corTitulo : '#ffffff');
        this.formBtn.get('corFundo')?.setValue((this.estiloDefinido.corFundo && this.estiloDefinido.corFundo != '') ? this.estiloDefinido.corFundo : '#3C3A80');
        this.formBtn.get('corLetra')?.setValue((this.estiloDefinido.corLetra && this.estiloDefinido.corLetra != '') ? this.estiloDefinido.corLetra : '#3C3A80');
        this.formBtn.get('corFundoLetra')?.setValue((this.estiloDefinido.corFundoLetra && this.estiloDefinido.corFundoLetra != '') ? this.estiloDefinido.corFundoLetra : '#ffffff');
        this.formBtn.get('corBorda')?.setValue((this.estiloDefinido.corBorda && this.estiloDefinido.corBorda != '') ? this.estiloDefinido.corBorda : '#3C3A80');
        this.formBtn.get('corBordaLetra')?.setValue((this.estiloDefinido.corBordaLetra && this.estiloDefinido.corBordaLetra != '') ? this.estiloDefinido.corBordaLetra : '#ffffff');

        this.formBtnSelecionado.get('corTituloSelecionado')?.setValue((this.estiloDefinido.corTituloSelecionada && this.estiloDefinido.corTituloSelecionada != '') ? this.estiloDefinido.corTituloSelecionada : '#3C3A80');
        this.formBtnSelecionado.get('corFundoSelecionado')?.setValue((this.estiloDefinido.corFundoSelecionado && this.estiloDefinido.corFundoSelecionado != '') ? this.estiloDefinido.corFundoSelecionado : '#ffffff');
        this.formBtnSelecionado.get('corLetraSelecionada')?.setValue((this.estiloDefinido.corLetraSelecionada && this.estiloDefinido.corLetraSelecionada != '') ? this.estiloDefinido.corLetraSelecionada : '#ffffff');
        this.formBtnSelecionado.get('corFundoLetraSelecionado')?.setValue((this.estiloDefinido.corFundoLetraSelecionado && this.estiloDefinido.corFundoLetraSelecionado != '') ? this.estiloDefinido.corFundoLetraSelecionado : '#3C3A80');
        this.formBtnSelecionado.get('corBordaSelecionada')?.setValue((this.estiloDefinido.corBordaSelecionada && this.estiloDefinido.corBordaSelecionada != '') ? this.estiloDefinido.corBordaSelecionada : '#3C3A80');
        this.formBtnSelecionado.get('corBordaLetraSelecionada')?.setValue((this.estiloDefinido.corBordaLetraSelecionada && this.estiloDefinido.corBordaLetraSelecionada != '') ? this.estiloDefinido.corBordaLetraSelecionada : '#3C3A80');

        this.formIconesBtn.get('exibirIconeEsquerdo')?.setValue(this.estiloDefinido.exibirIconeEsquerdo);
        this.formIconesBtn.get('iconeEsquerdo')?.setValue(this.estiloDefinido.iconeEsquerdo);
        this.formIconesBtn.get('exibirIconeDireito')?.setValue(this.estiloDefinido.exibirIconeDireito);
        this.formIconesBtn.get('iconeDireito')?.setValue(this.estiloDefinido.iconeDireito);

    }

    private formatarBtns(){
        if(this.btnAlternativa){
            this.btnAlternativa.nativeElement.style.backgroundColor = this.formBtn.get('corFundo')?.value;
            this.btnAlternativa.nativeElement.style.color = this.formBtn.get('corTitulo')?.value;
            this.btnAlternativa.nativeElement.style.borderColor = this.formBtn.get('corBorda')?.value;
            let letraBtn = this.btnAlternativa.nativeElement.getElementsByClassName('base-letra-alternativa')[0] as HTMLSpanElement;
            if(letraBtn) {
                letraBtn.style.backgroundColor = this.formBtn.get('corFundoLetra')?.value;
                letraBtn.style.color = this.formBtn.get('corLetra')?.value;
                letraBtn.style.borderColor = this.formBtn.get('corBordaLetra')?.value;
            }
        }
        if(this.btnAlternativaSelecionado){
            this.btnAlternativaSelecionado.nativeElement.style.backgroundColor = this.formBtnSelecionado.get('corFundoSelecionado')?.value;
            this.btnAlternativaSelecionado.nativeElement.style.color = this.formBtnSelecionado.get('corTituloSelecionada')?.value;
            this.btnAlternativaSelecionado.nativeElement.style.borderColor = this.formBtnSelecionado.get('corBordaSelecionada')?.value;
            let letraBtnSelecionado = this.btnAlternativaSelecionado.nativeElement.getElementsByClassName('base-letra-alternativa')[0] as HTMLSpanElement;
            if(letraBtnSelecionado) {
                letraBtnSelecionado.style.backgroundColor = this.formBtnSelecionado.get('corFundoLetraSelecionado')?.value;
                letraBtnSelecionado.style.color = this.formBtnSelecionado.get('corLetraSelecionada')?.value;
                letraBtnSelecionado.style.borderColor = this.formBtnSelecionado.get('corBordaLetraSelecionada')?.value;
            }
        }
    }

    public gerarId():string{
        if(this.btnAvancar) return `mEBtnAvancar-${this.id}`;
        return `mE${this.id}`;
    }

    public salvar(){
        let estilo = {
            corTitulo: this.formBtn.get('corTitulo')?.value,
            corFundo: this.formBtn.get('corFundo')?.value,
            corFundoLetra: this.formBtn.get('corFundoLetra')?.value,
            corLetra: this.formBtn.get('corLetra')?.value,
            corTituloSelecionada: this.formBtnSelecionado.get('corTituloSelecionada')?.value,
            corFundoSelecionado: this.formBtnSelecionado.get('corFundoSelecionado')?.value,
            corLetraSelecionada: this.formBtnSelecionado.get('corLetraSelecionada')?.value,
            corFundoLetraSelecionado: this.formBtnSelecionado.get('corFundoLetraSelecionado')?.value,
            corBorda: this.formBtn.get('corBorda')?.value,
            corBordaSelecionada: this.formBtnSelecionado.get('corBordaSelecionada')?.value,
            corBordaLetra: this.formBtn.get('corBordaLetra')?.value,
            corBordaLetraSelecionada: this.formBtnSelecionado.get('corBordaLetraSelecionada')?.value,
            exibirIconeEsquerdo: this.formIconesBtn.get('exibirIconeEsquerdo')?.value,
            iconeEsquerdo: this.formIconesBtn.get('iconeEsquerdo')?.value,
            exibirIconeDireito: this.formIconesBtn.get('exibirIconeDireito')?.value,
            iconeDireito: this.formIconesBtn.get('iconeDireito')?.value
        } as EstiloAlternativa;

        this.estilo.emit(estilo);
    }

}
