import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SpinnerService } from "src/app/compartilhados/componentes/spinners/spinner.service";
import { Formulario } from "src/app/modelos/formulario.model";
import { Pergunta } from "src/app/modelos/pergunta.model";
import { TipoResposta } from "src/app/modelos/tipo-resposta.model";
import { PerguntaService } from "src/app/servicos/pergunta.service";
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import Swal from "sweetalert2";
import { FormularioService } from "src/app/servicos/formulario.service";
import { debounceTime } from "rxjs";

const PAUSA = 300;

@Component({
    selector: 'lista-perguntas',
    templateUrl: './lista-perguntas.component.html',
    styleUrls: ['./lista-perguntas.component.css']
})
export class ListaPerguntasComponent implements OnInit, OnChanges{

    @Input() formulario!: Formulario;
    @Output() pergunta: EventEmitter<Pergunta | null> = new EventEmitter();
    @Output() posicoes: EventEmitter<Pergunta[]> = new EventEmitter();

    public perguntas!: Array<Pergunta>;

    public perguntaSelecionada: number = 0;

    public formNovaPergunta!: FormGroup;
    public campoIdentificadorValido: boolean = true;

    public tiposPergunta = TipoResposta;

    public desativaBtnPosicao: boolean = true;

    constructor(
        private fb: FormBuilder,
        private perguntaService: PerguntaService,
        private spinnerService: SpinnerService,
        private formularioService: FormularioService
    ){}

    ngOnInit(): void {
        this.formNovaPergunta = this.fb.group({
            tipoPergunta: ['',[Validators.required]],
            identificador: ['',[Validators.required]]
        });
        this.formNovaPergunta.get('identificador')?.valueChanges.pipe(debounceTime(PAUSA)).subscribe({
            next: valor => {
                this.perguntaService.consultaIdentificador(this.formulario.id as number,valor).subscribe({
                    next: resp => {this.campoIdentificadorValido = resp}
                });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.selecionarPrimeiraPergunta();
    }

    private selecionarPrimeiraPergunta(){
        if(this.formulario != undefined){
            if(this.formulario.perguntas.length != 0){
                this.perguntas = this.formulario.perguntas;
                //this.selecionarPergunta(this.formulario.perguntas[0]);
            }else{
                this.perguntas = [];
                this.perguntaSelecionada = 0;
                this.selecionarPergunta(null);
                this.pergunta.emit(null);
            }
        }
    }

    public selecionarPergunta(pergunta: Pergunta | null){
        let p = pergunta;
        if(pergunta != null){
            this.perguntaSelecionada = pergunta.id;
            p = this.perguntas.find(p => p == pergunta) as Pergunta;
        }
        this.pergunta.emit(p);
    }

    public addPergunta(){

        let tipoPergunta = this.formNovaPergunta.get('tipoPergunta')?.value as TipoResposta;
        let identificador = this.formNovaPergunta.get('identificador')?.value as string;

        let novaPergunta = {
            id: 0,
            posicao: 0,
            identificador: identificador,
            descricao: "",
            titulo: "",
            ultima: false,
            opcaoRedirecionamento: 0,
            redirecionamentoPergunta: 0,
            exibirTitulo: true,
            exibeTextoImagem: false,
            textoImagem: "",
            resposta: {
                id: 0,
                guarda: false,
                tipoResposta: tipoPergunta,
                alternativas: [],
                linkVideo: "",
                placeholder: "",
                fullScreen: false,
                tempoFullScreen: 0,
            },

        } as Pergunta;

        this.spinnerService.ativarSpinner();

        this.formularioService.adicionarPergunta(this.formulario.id as never,novaPergunta).subscribe({
            next: form => {
                this.formulario.perguntas.push(form.perguntas[0]);
                this.perguntas = this.formulario.perguntas;
                this.selecionarPergunta(this.perguntas[this.perguntas.length-1]);
                this.formNovaPergunta.reset();
                this.spinnerService.desativarSpinner();
            },
            error: error => {
                Swal.fire("Erro ao adicionar nova pergunta", "", "error");
            }
        });

    }

    public rmvPergunta(){
        if(this.perguntas.length == 0){
            Swal.fire('Atenção', 'Sem perguntas no momento!','warning');
            return;
        }
        if(this.perguntaSelecionada == 0) {
            Swal.fire('Atenção', 'Nenhuma pergunta selecionada!','warning');
            return;
        }
        Swal.fire({
            title: 'Deseja realmente excluir a pergunta do formulário?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {

            if(result.isConfirmed){

                this.spinnerService.ativarSpinner();

                this.perguntaService.excluir(this.perguntaSelecionada).subscribe({
                    next: resp => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Pergunta excluída com sucesso :) !', '', 'success');
                        let p = this.perguntas.find(f => f.id == this.perguntaSelecionada) as Pergunta;
                        this.perguntas.splice(this.perguntas.indexOf(p),1);
                        this.selecionarPrimeiraPergunta();
                    },
                    error: error => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Erro ao excluir pergunta :( !', '', 'error');
                    }
                });

            }

        });
    }


    public arrastar(event: CdkDragDrop<Pergunta[]>) {
        moveItemInArray(this.perguntas, event.previousIndex, event.currentIndex);
        this.perguntas.forEach((p,index) => {
            p.posicao = index+1;
        });
        this.desativaBtnPosicao = false;
    }

    public salvarPosicoes(){
        this.posicoes.emit(this.perguntas);
        this.desativaBtnPosicao = true;
    }

}
