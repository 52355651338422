import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InterceptorService } from "src/app/servicos/interceptador.service";
import { FormFormataComponent } from "./form-formata/form-formata.component";
import { FormPerguntaAlternativaMultiplaComponent } from "./form-pergunta-alternativa-multipla/form-pergunta-alternativa-multipla.component";
import { FormPerguntaAlternativaUnicaComponent } from "./form-pergunta-alternativa-unica/form-pergunta-alternativa-unica.component";
import { FormPerguntaTextoComponent } from "./form-pergunta-texto/form-pergunta-texto.component";
import { FormPerguntaVideoComponent } from "./form-pergunta-video/form-pergunta-video.component";
import { FormPerguntaComponent } from "./form-pergunta.component";
import { FormArquivoComponent } from "./form-arquivo/form-arquivo.component";
import { FormMarcacaoUsuarioComponent } from "./form-marcacao-usuario/form-marcacao-usuario.component";
import { FormPerguntaService } from "./form-pergunta.service";
import { FormPerguntaHTMLComponent } from "./form-pergunta-html/form-pergunta-html.component";
import { FormFormataEstiloAlternativaComponent } from "./form-formata-estilo-alternativa/form-formata-estilo-alternativa";
import { DataBsTargetModule } from "src/app/compartilhados/diretivas/data-bs-target.module";
import { FormVisualizadorComponent } from "./form-visualizador/form-visualizador.component";
import { FormBurladorComponent } from "./form-burlador/form-burlador.component";
import { BurladorService } from "src/app/servicos/burlador.service";
import { FormPerguntaVideoPersonalizadoComponent } from "./form-pergunta-video-personalizado/form-pergunta-video-personalizado.component";
import { FormPerguntaAlternativaUnicaDropdownComponent } from "./form-pergunta-alternativa-unica-dropdown/form-pergunta-alternativa-unica-dropdown.component";
import { FormAlternativaUnicaCompacta } from "./form-alternativa-unica-compacta/form-alternativa-unica-compacta";

@NgModule({
    declarations: [
        FormPerguntaComponent,
        FormPerguntaTextoComponent,
        FormPerguntaAlternativaUnicaComponent,
        FormPerguntaAlternativaMultiplaComponent,
        FormPerguntaVideoComponent,
        FormFormataComponent,
        FormArquivoComponent,
        FormMarcacaoUsuarioComponent,
        FormPerguntaHTMLComponent,
        FormFormataEstiloAlternativaComponent,
        FormVisualizadorComponent,
        FormBurladorComponent,
        FormPerguntaVideoPersonalizadoComponent,
        FormPerguntaAlternativaUnicaDropdownComponent,
        FormAlternativaUnicaCompacta
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DataBsTargetModule
    ],
    exports: [
        FormPerguntaComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass:  InterceptorService,
            multi: true
        },
        FormPerguntaService,
        BurladorService
    ]
})
export class FormPerguntaModule{

}
