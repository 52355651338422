<div class="painel-spinner">

    <div class="caixa-spinner">
        <div class="spinner-border spinner text-primary">
            <span class="visually-hidden">Carregando...</span>
        </div>
        <span>Carregando...</span>
    </div>

</div>
