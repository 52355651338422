<div class="row">
    <div class="col-12">
        <h6 class="text-start ms-3"><i class="fa-solid fa-hashtag me-1"></i>Perguntas:</h6>
    </div>
    <div class="col-12">
        <div class="text-start ms-3">
            <button type="button" title="Adicionar uma pergunta e seleciona um identificador único e um tipo" class="btn btn-sm btn-primary me-1" data-bs-toggle="modal" data-bs-target="#modal-nova-pergunta" data-test="add-pergunta"><i class="fa-solid fa-plus"></i></button>
            <button type="button" title="Deletar a pergunta selecionada" class="btn btn-sm btn-danger me-1" (click)="rmvPergunta()" data-test="excluir-pergunta"><i class="fa-solid fa-trash"></i></button>
            <!-- Ocultado !!
            <button type="button" title="Salvar ordem das perguntas" [disabled]="desativaBtnPosicao" class="btn btn-sm btn-outline-primary" (click)="salvarPosicoes()"><i class="fa-solid fa-arrow-up-a-z"></i></button>-->
        </div>
    </div>
</div>

<ul cdkDropList (cdkDropListDropped)="arrastar($event)" class="list-group list-group-flush mt-4" *ngIf="pergunta != null">

    <ng-container *ngFor="let pergunta of perguntas; let i = index">
        <button #btn type="button" (click)="selecionarPergunta(pergunta)" class="list-group-item list-group-item-action cursor-mover" cdkDrag>
            <div class="d-flex w-100 justify-content-between">
                <p>{{i+1}}) {{pergunta.identificador | uppercase}}</p>
                <span class="text-end" *ngIf="perguntaSelecionada == pergunta.id"><i class="fa-solid fa-right-long"></i></span>
            </div>
        </button>
    </ng-container>

</ul>

<div class="modal fade" tabindex="-1" id="modal-nova-pergunta">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Nova Pergunta</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row justify-content-center">

                        <div class="col-12">

                            <form [formGroup]="formNovaPergunta">
                                <label for="tipo" class="form-label mb-0">Tipo da Pergunta:</label>
                                <select class="form-select" formControlName="tipoPergunta" id="tipo" data-test="tipo-pergunta">
                                    <ng-container *ngFor="let tp of tiposPergunta | keyvalue ">
                                        <option [value]="tp.value">{{tp.value}}</option>
                                    </ng-container>
                                </select>

                                <label for="i" class="form-label mb-0 mt-3">Identificador:</label>
                                <input type="text" id="i" class="form-control" [ngClass]="{'is-invalid': !campoIdentificadorValido}" formControlName="identificador" data-test="identificador-pergunta">
                                <div [ngClass]="{'invalid-feedback': !campoIdentificadorValido}">
                                    <span *ngIf="!campoIdentificadorValido">Identificador já está em uso!</span>
                                </div>

                            </form>

                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" (click)="addPergunta()" [disabled]="formNovaPergunta.invalid || !campoIdentificadorValido" class="btn btn-primary" data-bs-dismiss="modal" data-test="btnSalvarPergunta"><i class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>
