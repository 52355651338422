<div class="row">
    <div class="col-12">
        <h6 class="text-start ms-2"><i class="fa-solid fa-hashtag me-1"></i>Formulários:</h6>
    </div>
    <div class="col-12">
        <div class="text-start ms-2">
            <button type="button" title="Criar novo formulário" class="btn btn-sm btn-primary me-1"
                data-bs-toggle="modal" data-bs-target="#modal-novo-formulario" data-test="novo-formulario"><i
                    class="fa-solid fa-plus"></i></button>
            <button type="button" title="Deletar o formulário selecionado" class="btn btn-sm btn-danger me-1"
                (click)="rmvForm()" data-test="excluir-formulario"><i class="fa-solid fa-trash"></i></button>
            <button type="button" title="Editar o formulário e sua URL" (click)="abrirModalEditarForm()"
                class="btn btn-sm btn-primary me-1" data-test="editar-formulario"><i
                    class="fa-solid fa-edit"></i></button>
            <button type="button"
                title="Clonar o formulário atual, criando uma cópia com 'Clone' na frente do nome e URL"
                class="btn btn-sm btn-primary me-1" (click)="clonarFormulario()"><i
                    class="fa-solid fa-clone"></i></button>
            <button type="button" title="Abrir link do formulário selecionado" class="btn btn-sm btn-primary me-1"
                (click)="abrirPrevNator()"><i class="fa-solid fa-arrow-up-right-from-square"></i></button>
            <button type="button" title="Editar fonte, cor e imagem de fundo do formulário selecionado"
                class="btn btn-sm btn-primary me-1" (click)="abrirModalEstiloForm()" data-test="btn-opcoes-form"><i
                    class="fa-solid fa-border-top-left"></i></button>
            <button type="button" title="Editar as informações do OpenGraph" class="btn btn-sm btn-primary"
                (click)="abrirModalOpenGraph()"><i class="fa-solid fa-code"></i></button>
        </div>
    </div>
</div>

<p class="mt-3"></p>

<pasta assuntoConteudo="formulário" [pastas]="pastas" [grupo]="grupoPasta" [conteudos]="formularios"
    (eventConteudosSemPasta)="addFormularioSemPasta($event)"
    (eventConteudoSelecionado)="receberFormularioSelecionado($event)"
    (eventConteudosExcluido)="receberFormulariosDePastaExcluida($event)"
    (eventConteudoMovidoPasta)="rmvFormularioSemPasta($event)"
    (eventSalvarConteudoPasta)="salvarFormularioEmPasta($event)"></pasta>

<h6 class="text-start ms-2 mt-3"><i class="fa-solid fa-hashtag me-1"></i>Formulários Sem Pasta:</h6>

<ul class="list-group list-group-flush mt-1">
    <ng-container *ngFor="let form of formulariosSemPasta">
        <button type="button" (click)="selecionarFormulario(form)" class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
                <p>{{form.nome | uppercase}}</p>
                <span class="text-end" *ngIf="formSelecionado?.id == form.id"><i
                        class="fa-solid fa-right-long"></i></span>
            </div>
        </button>
    </ng-container>
</ul>

<div class="modal fade" tabindex="-1" id="modal-novo-formulario">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Novo Formulário</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row justify-content-center">

                        <div class="col-12">

                            <form [formGroup]="formNovoFormulario">
                                <label for="url" class="form-label mb-0">URL do Formulário:</label>
                                <input type="text" id="url" class="form-control"
                                    [ngClass]="{'is-invalid': !campoUrlValido}" formControlName="url"
                                    data-test="url-cadastro-form" />
                                <div [ngClass]="{'invalid-feedback': !campoUrlValido}">
                                    <span *ngIf="!campoUrlValido">URL já está em uso!</span>
                                </div>

                                <label for="nome" class="form-label mb-0 mt-3">Nome do Formulário:</label>
                                <input type="text" id="nome" class="form-control"
                                    [ngClass]="{'is-invalid': !campoNomeValido}" data-test="nome-cadastro-form"
                                    formControlName="nome" />
                                <div [ngClass]="{'invalid-feedback': !campoNomeValido}">
                                    <span *ngIf="!campoNomeValido">Nome já está em uso!</span>
                                </div>

                                <label for="versao" class="form-label mb-0 mt-3">Escolher Versão:</label>
                                <select id="versao" class="form-select" formControlName="versaoPrevNator"
                                    data-test="versao-cadastro-form">
                                    <option value="v1">V1</option>
                                    <option value="v2">V2</option>
                                </select>

                            </form>

                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i
                        class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" (click)="addForm()"
                    [disabled]="formNovoFormulario.invalid || !campoUrlValido || !campoNomeValido"
                    data-test="btn-cadastrar-form" class="btn btn-primary" data-bs-dismiss="modal"
                    id="btn-salvar-novo-formulario"><i class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" tabindex="-1" id="modal-editar-nome-formulario">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Editar Formulário</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row justify-content-center">

                        <div class="col-12">

                            <form [formGroup]="formEditarNomeFormulario">

                                <label for="urlE" class="form-label mb-0">URL do Formulário:</label>
                                <input type="text" id="urlE" class="form-control" formControlName="url"
                                    data-test="url-editar-form" />

                                <label for="nomeE" class="form-label mb-0 mt-3">Nome do Formulário:</label>
                                <input type="text" id="nomeE" class="form-control" formControlName="nome"
                                    data-test="nome-editar-form" />

                                <label for="versao" class="form-label mb-0 mt-3">Versão Layout PrevNator: </label>
                                <select id="versao" class="form-select" formControlName="versaoPrevNator"
                                    data-test="versao-editar-form">
                                    <option value="v1">V1</option>
                                    <option value="v2">V2</option>
                                </select>

                                <div class="form-check mt-2">
                                    <input type="checkbox" id="ativo" class="form-check-input" formControlName="ativo">
                                    <label for="ativo" class="form-check-label">Ativar</label>
                                </div>

                            </form>

                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i
                        class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" (click)="editarNomeForm()" [disabled]="formEditarNomeFormulario.invalid"
                    class="btn btn-primary" data-bs-dismiss="modal" data-test="btnEditar-form"><i
                        class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" tabindex="-1" id="modal-editar-estilo-formulario">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Editar Estilo Formulário</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row justify-content-center">

                        <div class="col-12">

                            <div class="row">

                                <div class="col-12 col-md-4">

                                    <form [formGroup]="formEstiloFormulario">

                                        <label for="fonte" class="form-label mb-0">Fonte:</label>
                                        <input type="text" formControlName="fonte" id="fonte" class="form-control"
                                            list="fontes">
                                        <datalist id="fontes">
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Helvetica">Helvetica</option>
                                            <option value="Arial">Arial</option>
                                            <option value="Comic Sans MS">Comic Sans Ms</option>
                                            <option value="Times New Roman">Times New Roman</option>
                                        </datalist>

                                        <label for="corFonte" class="form-label mb-0 mt-2">Cor da Fonte:</label>
                                        <input type="color" class="form-control form-control-color" id="corFonte"
                                            formControlName="corFonte">

                                        <label for="corFundo" class="form-label mb-0 mt-2">Cor do Fundo:</label>
                                        <input type="color" class="form-control form-control-color" id="corFundo"
                                            formControlName="corFundo">

                                        <div class="mt-2">
                                            <label for="exibeImagemFundo" class="form-check-label me-1">Exibir Imagem de
                                                Fundo?</label>
                                            <input type="checkbox" class="form-check-input" id="exibeImagemFundo"
                                                formControlName="exibeImagemFundo">
                                        </div>

                                        <ng-container *ngIf="exibirImagemFundo()">
                                            <div class="d-md-flex">
                                                <input type="file" class="form-control" id="imagemBase64"
                                                    accept="image/*" (change)="arquivoImagem($event,0)">
                                            </div>
                                            <div class="d-md-flex mt-1">
                                                <input type="url" class="form-control" formControlName="chaveImagem">
                                            </div>
                                            <div class="mt-1">
                                                <label for="posicao" class="form-label mb-0">Posição</label>
                                                <select id="posicao" formControlName="posicaoImagem"
                                                    class="form-select">
                                                    <option value="Preencher">Preencher</option>
                                                    <option value="Centralizar">Centralizar</option>
                                                </select>
                                            </div>
                                        </ng-container>

                                        <div class="mt-3">
                                            <label for="mostrarBarraProgresso" class="form-check-label me-1">Barra de
                                                Progresso?</label>
                                            <input type="checkbox" class="form-check-input" id="mostrarBarraProgresso"
                                                formControlName="mostrarBarraProgresso"
                                                data-test="mostrar-barra-progresso">
                                        </div>

                                        <div class="mt-0"
                                            *ngIf="formEstiloFormulario.get('mostrarBarraProgresso')?.value == true">
                                            <label for="corPassoProgresso" class="form-label mb-0 mt-2">Cor do Passo de
                                                Progresso:</label>
                                            <input type="color" class="form-control form-control-color"
                                                id="corPassoProgresso" formControlName="corPassoProgresso">
                                        </div>

                                        <div class="mt-1"
                                            *ngIf="formEstiloFormulario.get('mostrarBarraProgresso')?.value == true">
                                            <label for="corBarraProgresso" class="form-label mb-0 mt-2">Cor da Barra de
                                                Progresso:</label>
                                            <input type="color" class="form-control form-control-color"
                                                id="corBarraProgresso" formControlName="corBarraProgresso">
                                        </div>

                                        <div class="mt-3">
                                            <span>Opções de Header:</span>
                                            <div class="row">
                                                <div class="col-6">
                                                    <input type="radio" [value]="0" class="form-check-input"
                                                        name="opcaoCabecalho" formControlName="opcaoCabecalho">
                                                    <label class="form-label ms-1">2 Imagens</label>
                                                </div>
                                                <div class="col-6">
                                                    <input type="radio" [value]="1" class="form-check-input"
                                                        name="opcaoCabecalho" formControlName="opcaoCabecalho">
                                                    <label class="form-label ms-1">1 Imagem</label>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-12">
                                                    <label for="" class="me-1">Exibir Primeira Imagem: Sim?</label>
                                                    <input type="checkbox" class="form-check-input"
                                                        formControlName="exibeImg1Cabecalho">
                                                    <ng-container
                                                        *ngIf="formEstiloFormulario.get('exibeImg1Cabecalho')?.value == true">
                                                        <label>Imagem 1</label>
                                                        <input type="file" class="form-control" accept="image/*"
                                                            (change)="arquivoImagem($event,1)">
                                                        <input type="url" class="form-control"
                                                            formControlName="chaveImg1Cabecalho">
                                                    </ng-container>
                                                </div>
                                                <div class="col-12 mt-1"
                                                    *ngIf="formEstiloFormulario.get('opcaoCabecalho')?.value == 0">
                                                    <label for="" class="me-1">Exibir Segunda Imagem: Sim?</label>
                                                    <input type="checkbox" class="form-check-input"
                                                        formControlName="exibeImg2Cabecalho">
                                                    <ng-container
                                                        *ngIf="formEstiloFormulario.get('exibeImg2Cabecalho')?.value == true">
                                                        <label>Imagem 2</label>
                                                        <input type="file" class="form-control" accept="image/*"
                                                            (change)="arquivoImagem($event,2)">
                                                        <input type="url" class="form-control"
                                                            formControlName="chaveImg2Cabecalho">
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-4">
                                            <label for="exibeFooter" class="form-label me-1">Exibir Footer? Sim</label>
                                            <input type="checkbox" class="form-check-input" id="exibeFooter"
                                                formControlName="exibeRodape">
                                            <div class="row"
                                                *ngIf="formEstiloFormulario.get('exibeRodape')?.value == true">
                                                <div class="col-12">
                                                    <label>Imagem Rodapé</label>
                                                    <input type="file" class="form-control" accept="image/*"
                                                        (change)="arquivoImagem($event,3)">
                                                    <input type="url" class="form-control"
                                                        formControlName="chaveImagemRodape">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-4">
                                            <label for="splashscreen" class="form-label me-1">Exibir Splashscreen?
                                                Sim</label>
                                            <input type="checkbox" class="form-check-input" id="splashscreen"
                                                formControlName="exibirSplashscreen">
                                        </div>

                                    </form>

                                </div>

                                <div class="col-12 col-md-8">

                                    <div class="visualizacao-form" [style]="getCorEFundoEscolhido()">

                                        <h2 class="text-center mt-3" [style]="getFontECorEscolhida()">Titulo da Pergunta
                                        </h2>

                                        <h5 class="text-center p-1" [style]="getFontECorEscolhida()">Lorem ipsum dolor
                                            sit amet consectetur, adipisicing elit.
                                            Veritatis veniam velit minus illo corrupti rem, sit sunt
                                            voluptatum natus officia incidunt pariatur accusantium
                                            aliquid blanditiis, similique assumenda? Voluptatem,
                                            quidem exercitationem.
                                        </h5>

                                        <div class="container-progresso"
                                            *ngIf="formEstiloFormulario.get('mostrarBarraProgresso')?.value == true">
                                            <span class="texto-progresso"
                                                [ngStyle]="{'color': getCorPassoProgresso()}">Passo 1 de 10</span>
                                            <div class="box-progresso">
                                                <div class="barra-progresso"
                                                    [ngStyle]="{'background-color': getCorBarraProgresso()}"
                                                    style="width: 10%;"></div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                    data-test="btn-fechar-modal-opcoes-form"><i
                        class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" [disabled]="formEstiloFormulario.invalid" (click)="salvarEstiloFormulario()"
                    data-test="btn-salvar-opcoes-form" class="btn btn-primary" data-bs-dismiss="modal"><i
                        class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" tabindex="-1" id="modal-openGraph">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Open Graph</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row justify-content-center">

                        <div class="col-12">

                            <form [formGroup]="formOpenGraph">

                                <label for="siteName" class="form-label mb-0">Site Name:</label>
                                <input type="text" id="siteName" formControlName="siteName" class="form-control">

                                <label for="url" class="form-label mb-0 mt-3">URL:</label>
                                <input type="url" id="url" formControlName="url" class="form-control">

                                <label for="title" class="form-label mb-0 mt-3">Title:</label>
                                <input type="text" id="title" formControlName="title" class="form-control">

                                <label for="description" class="form-label mb-0 mt-3">Description:</label>
                                <textarea id="description" formControlName="description" class="form-control"
                                    rows="5"></textarea>

                                <fieldset formGroupName="audio" class="mt-4">

                                    <legend>Audio:</legend>

                                    <label for="url-audio" class="form-label mb-0">URL:</label>
                                    <input type="url" id="url-audio" formControlName="url" class="form-control">

                                    <label for="secure_url-audio" class="form-label mb-0 mt-3">Securite URL:</label>
                                    <input type="url" id="secure_url-audio" formControlName="secure_url"
                                        class="form-control">

                                    <label for="type-audio" class="form-label mb-0 mt-3">Type:</label>
                                    <select id="type" formControlName="type" class="form-select">
                                        <option value="ogg">OGG</option>
                                        <option value="mp4">MP4</option>
                                        <option value="webm">WEBM</option>
                                        <option value="mp3">MP3</option>
                                        <option value="wave">WAVE</option>
                                    </select>

                                </fieldset>

                                <fieldset formGroupName="image" class="mt-4">

                                    <legend>Image:</legend>

                                    <label for="url-image" class="form-label mb-0">URL:</label>
                                    <input type="url" id="url-image" formControlName="url" class="form-control">

                                    <label for="secure_url-image" class="form-label mb-0 mt-3">Secure URL:</label>
                                    <input type="url" id="secure_url-image" formControlName="secure_url"
                                        class="form-control">

                                    <label for="alt-image" class="form-label mb-0 mt-3">Alt:</label>
                                    <textarea id="alt-image" class="form-control" formControlName="alt"
                                        rows="5"></textarea>

                                    <label for="type-image" class="form-label mb-0 mt-3">Type:</label>
                                    <select id="type-image" formControlName="type" class="form-select">
                                        <option value="jpg">JPG</option>
                                        <option value="jpeg">JPEG</option>
                                        <option value="png">PNG</option>
                                        <option value="gif">GIF</option>
                                        <option value="svg">SVG</option>
                                    </select>

                                    <div class="row mt-3">
                                        <div class="col-6">
                                            <label for="width-image" class="form-label mb-0">Width:</label>
                                            <input type="number" id="width-image" min="0" formControlName="width"
                                                class="form-control">
                                        </div>
                                        <div class="col-6">
                                            <label for="height-image" class="form-label mb-0">height:</label>
                                            <input type="number" id="height-image" min="0" formControlName="height"
                                                class="form-control">
                                        </div>
                                    </div>

                                </fieldset>

                                <fieldset formGroupName="video" class="mt-4">

                                    <legend>Video:</legend>

                                    <label for="url-video" class="form-label mb-0">URL:</label>
                                    <input type="url" id="url-video" formControlName="url" class="form-control">

                                    <label for="secure_url-video" class="form-label mb-0 mt-3">Secure URL:</label>
                                    <input type="url" id="secure_url-video" formControlName="secure_url"
                                        class="form-control">

                                    <label for="alt-video" class="form-label mb-0 mt-3">Alt:</label>
                                    <textarea id="alt-video" class="form-control" formControlName="alt"
                                        rows="5"></textarea>

                                    <label for="type-video" class="form-label mb-0 mt-3">Type:</label>
                                    <select id="type-video" formControlName="type" class="form-select">
                                        <option value="avi">AVI</option>
                                        <option value="wmv">WMV</option>
                                        <option value="ASF">ASF</option>
                                        <option value="MPG">MPG</option>
                                        <option value="flv">FLV</option>
                                        <option value="mp5">MP5</option>
                                    </select>

                                    <div class="row mt-3">
                                        <div class="col-6">
                                            <label for="width" class="form-label mb-0">Width:</label>
                                            <input type="number" min="0" formControlName="width" class="form-control">
                                        </div>
                                        <div class="col-6">
                                            <label for="height" class="form-label mb-0">height:</label>
                                            <input type="number" min="0" formControlName="height" class="form-control">
                                        </div>
                                    </div>

                                </fieldset>

                            </form>

                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i
                        class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" class="btn btn-primary" (click)="salvarOpenGraph()" data-bs-dismiss="modal"><i
                        class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>