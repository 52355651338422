import { Component, OnInit } from "@angular/core";
import { SpinnerService } from "src/app/compartilhados/componentes/spinners/spinner.service";
import { Formulario } from "src/app/modelos/formulario.model";
import { Pergunta } from "src/app/modelos/pergunta.model";
import { FormularioService } from "src/app/servicos/formulario.service";
import { PerguntaService } from "src/app/servicos/pergunta.service";
import Swal from "sweetalert2";

@Component({
    selector: 'adm-form',
    templateUrl: './adm-form.component.html',
    styleUrls: ['./adm-form.component.css']
})
export class AdmFormComponent implements OnInit{

    public formularios!: Array<Formulario>;
    public formularioSelecionado!: Formulario;
    public idFormularioSelecionado: number = 0;
    public perguntaSelecionada!: Pergunta | null;
    public perguntasSelecionadas!: Pergunta[] | null;

    public mostrarBarraProgresso: boolean = false;

    public variaveis!: Array<string>;

    constructor(
        private formularioService: FormularioService,
        private spinnerService: SpinnerService,
        private perguntaService: PerguntaService,
    ){}

    ngOnInit(): void {
        this.buscarFormularios();
    }

    private buscarFormularios(){
        this.spinnerService.ativarSpinner();
        this.formularioService.buscarFormularios().subscribe({
            next: forms => {
                this.formularios = forms;
                this.spinnerService.desativarSpinner();
            },
            error: error => {
                console.log(error);
                this.spinnerService.desativarSpinner();
            }
        });
    }

    public receberFormulario(formulario: Formulario){
        if(!formulario) return;
        this.formularioSelecionado = formulario;
        this.mostrarBarraProgresso = formulario.estilo?.mostrarBarraProgresso as boolean;
        this.idFormularioSelecionado = this.formularioSelecionado.id as number;
        this.perguntasSelecionadas = this.formularioSelecionado.perguntas;
        if(this.formularioSelecionado.perguntas.length != 0){
            this.variaveis = [];
            this.variaveis = this.formularioSelecionado.perguntas.filter(p => p.resposta?.guarda == true).map(p => p.identificador);
        }
    }

    public receberPergunta(pergunta: Pergunta | null){
        this.perguntaSelecionada = pergunta;
    }

    public atualizarVariaveis(pergunta: Pergunta){
        if(pergunta.resposta.guarda == true){
            let variavel = this.variaveis.filter(v => v == pergunta.identificador);
            if(variavel.length == 0){
                this.variaveis.push(pergunta.identificador);
            }
        }else{
            let variavel = this.variaveis.filter(v => v == pergunta.identificador);
            if(variavel.length != 0){
                this.variaveis.splice(this.variaveis.indexOf(variavel[0]),1);
            }
        }
    }

    public atualizar(){
        this.buscarFormularios();
    }

    public salvarPosicoes(perguntas: Pergunta[]){

        Swal.fire({
            title: 'Deseja realmente salvar alteração da posição das pergunta do formulário?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {

            if(result.isConfirmed){

                this.spinnerService.ativarSpinner();

                this.perguntaService.salvarPosicao(perguntas).subscribe({
                    next: perguntas => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Posições alterada com sucesso :) !', '', 'success');
                    },
                    error: error => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Erro ao alterar posições :( !', '', 'error');
                    }
                });

            }

        });

    }

}
