import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SpinnerService } from "src/app/compartilhados/componentes/spinners/spinner.service";
import { Burlador, Pergunta } from "src/app/modelos/pergunta.model";
import { TipoResposta } from "src/app/modelos/tipo-resposta.model";
import Swal from "sweetalert2";
import { FormPerguntaService } from "./form-pergunta.service";
import { EstiloFormulario } from "src/app/modelos/formulario.model";

@Component({
    selector: 'form-pergunta',
    templateUrl: './form-pergunta.component.html',
    styleUrls: ['./form-pergunta.component.css']
})
export class FormPerguntaComponent{

    @Input() public idFormulario!: number | null;
    @Input() public pergunta!: Pergunta | null;
    @Input() public perguntas!: Array<Pergunta> | null;
    @Input() public variaveis!: Array<string>;
    @Input() public estiloFormulario!: EstiloFormulario | undefined | null;
    @Input() public exibeBarraProgresso: boolean = false;

    @Output() public perguntaSalva: EventEmitter<Pergunta> = new EventEmitter();
    @Output() public atualizar: EventEmitter<any> = new EventEmitter();

    public tipoResposta = TipoResposta;

    public burladores: Burlador[] = [];

    constructor(
        private spinnerService: SpinnerService,
        private formPerguntaService: FormPerguntaService
    ){}

    public onPerguntaSalva(pergunta: Pergunta){
        this.perguntaSalva.emit(pergunta);
    }

    public atualizarPerguntas(){

        Swal.fire({
            title: 'Você gostaria de salvar as alterações feitas nas perguntas?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Salvar',
            denyButtonText: `Não Salvar`,
        }).then((result) => {

            if (result.isConfirmed) {

                this.spinnerService.ativarSpinner();

                if(!this.formPerguntaService.podeAtualizar()){
                    this.spinnerService.desativarSpinner();
                    Swal.fire("Aviso!","Nenhuma pergunta pra ser salvo as alterações","info");
                    return;
                }

                if(!this.formPerguntaService.validarPerguntas()){
                    this.spinnerService.desativarSpinner();
                    Swal.fire("Atenção!","Existe uma pergunta que não tem o redirecionamento definido!","warning");
                    return;
                }

                this.formPerguntaService.atualizarPerguntas().subscribe({
                    next: perguntas => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire("Sucesso!","Perguntas atualizadas com sucesso! :)","success");
                        this.formPerguntaService.atualizarPerguntasSalvas(perguntas);
                    },
                    error: error => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire("Erro!","Erro ao atualizar perguntas! :( ", "error");
                    }
                });

            }

        });
    }

    public listarBurladores(burladores: Burlador[]){
        this.burladores = burladores;
    }

}
