<div class="row justify-content-end">
    <div class="col-1 d-flex justify-content-end">
        <visualizador [id]="gerarIdVisualizador('Visualizador')"
            [pergunta]="getPerguntaUltimoEstado()"
            [estilo]="estiloFormulario" (dadosSalvos)="getDadosExtras($event)"></visualizador>
    </div>
</div>

<form [formGroup]="formPerguntaMultiplaAlternativa" class="mb-3">

    <div class="row">

        <div class="col-12 col-md-4">
            <div class="form-group">
                <label for="posicao" class="form-label mb-0">Posição:</label>
                <select class="form-select" id="posicao" formControlName="posicao">
                    <ng-container *ngFor="let p of perguntas; let i = index">
                        <option [value]="i+1">{{i+1}}</option>
                    </ng-container>
                </select>
            </div>
        </div>

        <div class="col-12 col-md-4 mt-2 mt-md-0">
            <div class="form-group">
                <label for="tipo" class="form-label mb-0">Tipo da Pergunta:</label>
                <fieldset formGroupName="resposta">
                    <input type="text" id="tipo" class="form-control" formControlName="tipoResposta">
                </fieldset>
            </div>
        </div>

        <div class="col-12 col-md-4 mt-2 mt-md-0">
            <div class="form-group">
                <label for="identificador" class="form-label mb-0">Identificador:</label>
                <input type="text" id="identificador" class="form-control" formControlName="identificador">
            </div>
        </div>

    </div>

    <div class="form-group mt-3">
        <label for="titulo" class="form-label mb-0">Título:</label>
        <div class="d-md-flex">
            <formatador [id]="gerarIdFormatador('Titulo')" [variaveis]="variaveis"
                [texto]="getTitulo()" descricao="Alterar texto de título"
                    (textoFormatado)="multiplaFormatadoTitulo($event)"></formatador>
            <p [innerHTML]="formPerguntaMultiplaAlternativa.get('titulo')?.value" class="ms-3"></p>
        </div>
    </div>

    <div class="form-group mb-0">
        <label for="exibeTitulo" class="form-label me-1">Exibir Titulo? Sim </label>
        <input type="checkbox" id="exibeTitulo" formControlName="exibirTitulo" class="form-check-input">
    </div>

    <div class="form-group mt-3">
        <label for="descricao" class="form-label mb-0">Descrição:</label>
        <div class="d-md-flex">
            <formatador [id]="gerarIdFormatador('Descricao')"
                [variaveis]="variaveis" [texto]="getDescricao()" descricao="Alterar o texto do subtítulo"
                    (textoFormatado)="multiplaFormatadoDescricao($event)"></formatador>
            <p [innerHTML]="formPerguntaMultiplaAlternativa.get('descricao')?.value" class="ms-3"></p>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-4 mt-1">
            <label for="" class="form-label mb-0">Exibir imagem na pergunta? Sim: </label>
            <input type="checkbox" class="ms-2 form-check-input" formControlName="exibeImagem">
        </div>
        <div class="col-4" *ngIf="formPerguntaMultiplaAlternativa.get('exibeImagem')?.value == true">
            <div class="d-md-flex">
                <input type="file" id="imagem" class="form-control" accept="image/*" (change)="tratarArquivo($event)">
            </div>
        </div>
        <div class="col-4" *ngIf="formPerguntaMultiplaAlternativa.get('exibeImagem')?.value == true">
            <div class="d-md-flex">
                <input type="url" id="urlImagem" class="form-control" formControlName="urlImagemBase64">
            </div>
        </div>
    </div>

    <div class="row mt-1" *ngIf="formPerguntaMultiplaAlternativa.get('exibeImagem')?.value == true">

        <div class="col-4">
            <label for="exibeTextoImagem" class="form-label me-1">Exibir texto abaixo da imagem? Sim</label>
            <input type="checkbox" id="exibeTextoImagem" class="form-check-input" formControlName="exibeTextoImagem">
        </div>

        <div class="row" *ngIf="formPerguntaMultiplaAlternativa.get('exibeTextoImagem')?.value == true">
            <div class="d-md-flex">
                <formatador [id]="gerarIdFormatador('TextoImagem')" descricao="Editar texto abaixo da imagem"
                    [variaveis]="variaveis" [texto]="getTextoImagem()"
                    (textoFormatado)="textoFormatadoTextoImagem($event)"></formatador>
                <p [innerHTML]="formPerguntaMultiplaAlternativa.get('textoImagem')?.value" class="ms-3"></p>
            </div>
        </div>

    </div>

    <h5 class="mt-3">Alternativas:</h5>

    <div class="row mt-2">
        <div class="col">
            <fieldset formGroupName="resposta">
                <label for="letraAlternativas" class="form-label me-1">Exibir letras nas alternativa? Sim</label>
                <input type="checkbox" id="letraAlternativas" class="form-check-input" formControlName="exibirLetraAlternativa">
            </fieldset>
        </div>
    </div>

    <button type="button" title="Adicionar outra alternativa" (click)="addAlternativa()" class="btn btn-sm btn-outline-primary me-2 mt-3"><i
            class="fa-solid fa-plus"></i></button>

    <div clas="mt-3">
        <fieldset formGroupName="resposta">
            <ng-container *ngFor="let alternativa of formAlternativas.controls; let i=index">
                <ng-container [formGroup]="$any(alternativa)">
                    <div class="form-group mt-4 mt-md-3">

                        <div class="row justify-content-between mb-0">
                            <div class="col">
                                <label for="alternativa1" class="form-label mb-0 mt-2">Alternativa {{i+1}}:</label>
                            </div>
                            <div class="col text-end">
                                <button type="button" title="Excluir essa alternativa" (click)="rmvAlternativa(i)" class="btn btn-sm btn-outline-danger mb-1">
                                    <i class="fa-solid fa-minus"></i>
                                </button>
                            </div>
                        </div>

                        <input type="text" class="form-control" formControlName="titulo">

                        <div class="row mt-1">
                            <div class="col-12 col-md-6">
                                <label for="temEstiloProprio" class="me-1">Personalizar Botão da Alternativa? Sim</label>
                                <input type="checkbox" id="temEstiloProprio" class="form-check-input" formControlName="estiloProprio">
                            </div>
                            <div class="col-12 col-md-6 d-md-flex justify-content-end mt-1 mt-md-0" *ngIf="alternativa.get('estiloProprio')?.value">
                                <div>
                                    <form-formata-estilo-alternativa
                                        [id]="alternativa.get('id')?.value"
                                        [titulo]="alternativa.get('titulo')?.value"
                                        [estiloDefinido]="getEstiloAlternativa(i)"
                                        (estilo)="setEstiloAlternativa(i,$event)"></form-formata-estilo-alternativa>
                                </div>
                            </div>
                        </div>

                    </div>
                </ng-container>
            </ng-container>

        </fieldset>
    </div>

    <div class="form-group mt-4">
        <fieldset formGroupName="resposta">
            <label for="guarda" class="form-label mb-0 me-1">A resposta dessa pergunta será usada em outra pergunta?
                Sim</label>
            <input type="checkbox" id="guarda" class="form-check-input" formControlName="guarda" (change)="guardarPergunta()">
        </fieldset>
    </div>

    <h5 class="mt-4">Validações:</h5>

    <button type="button" title="Adicionar regra de validação" (click)="addValidacao()" class="btn btn-sm btn-outline-primary me-2 mt-3"><i
            class="fa-solid fa-plus"></i></button>

    <button type="button" title="Remover regra de validação" (click)="rvmValidacao()" class="btn btn-sm btn-outline-danger me-2 mt-3"
        *ngIf="formValidacoes.length != 0"><i class="fa-solid fa-minus"></i></button>

    <div clas="mt-3 mb-2">
        <fieldset formGroupName="resposta">
            <ng-container *ngFor="let validacao of formValidacoes.controls; let i=index">
                <ng-container [formGroup]="$any(validacao)">
                    <div class="form-group mt-4 mt-md-3">

                        <div class="row justify-content-between mb-0">

                            <div class="col-2">
                                <label for="tipo" class="form-label mb-0">Tipo</label>
                                <select class="form-select" id="tipo" formControlName="tipo">
                                    <option [value]="tipoValidacao.REQUERIDA">Requerido</option>
                                </select>
                            </div>

                            <div class="col-2">
                                <label for="valor" class="form-label mb-0">Valor</label>
                                <select class="form-select" id="valor" formControlName="valor">
                                    <option value="false">Não</option>
                                    <option value="true">Sim</option>
                                </select>
                            </div>

                            <div class="col-8">
                                <label for="msg" class="form-label mb-0">Mensagem de Aviso</label>
                                <input type="text" class="form-control" formControlName="mensagem">
                            </div>

                        </div>

                    </div>
                </ng-container>
            </ng-container>

        </fieldset>
    </div>

    <div class="row mt-3">
        <div class="col-12 col-md-6 mt-2">
            <label for="btnPersonalizado" class="form-label mb-0 me-1">Personalizar Botão Avançar? Sim</label>
            <input type="checkbox" id="btnPersonalizado" class="form-check-input" formControlName="personalizarBtnAvancar">
        </div>
    </div>

    <fieldset formGroupName="botaoAvancar">

        <div class="row mt-2" *ngIf="formPerguntaMultiplaAlternativa.get('personalizarBtnAvancar')?.value == true">

            <div class="col-12 col-md-6 mt-2">
                <label for="textoBotaoPersonalizado" class="form-label mb-0 me-1">Alterar Rótulo do Botão? Sim</label>
                <input type="checkbox" id="textoBotaoPersonalizado" class="form-check-input" formControlName="textoBotaoPersonalizado">
            </div>

            <div class="col-12 col-md-6" *ngIf="getFormBotaoAvancar().get('textoBotaoPersonalizado')?.value == true">
                <input type="text" id="btn" class="form-control" formControlName="textoBotao" placeholder="Texto personalizado do Botão!!">
            </div>

        </div>

        <div class="row mt-2" *ngIf="formPerguntaMultiplaAlternativa.get('personalizarBtnAvancar')?.value == true">
            <div class="col">
                <span class="me-2">Alterar Estilo do Botão Avançar: </span>
                <form-formata-estilo-alternativa
                    tituloModal="Personalizar Botão Avançar"
                    [ativarLetra]="false" [btnAvancar]="true"
                    [id]="getFormBotaoAvancar().get('id')?.value"
                    [titulo]="getFormBotaoAvancar().get('textoBotao')?.value"
                    [estiloDefinido]="getEstiloAlternativaBtnAvancar()"
                    (estilo)="setEstiloAlternativaBtnAvancar($event)"></form-formata-estilo-alternativa>
            </div>
        </div>

    </fieldset>

    <div class="row mt-3" *ngIf="mostrarBarraProgresso == true">
        <div class="col col-md-6">
            <label for="indiceBarraProgresso" class="form-label mb-0">Indice da Barra de Progresso</label>
            <input type="number" min="1" class="form-control" id="indiceBarraProgresso" formControlName="indiceBarraProgresso">
        </div>
        <div class="col col-md-6">
            <label for="totalBarraProgresso" class="form-label mb-0">Tota de Indice da Barra de Progresso</label>
            <input type="number" min="1" class="form-control" id="totalBarraProgresso" formControlName="totalIndiceBarraProgresso">
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12 col-lg-3">
            <div class="form-group mt-2">
                <label for="ultima" class="form-label mb-0 me-1">Última Pergunta? Sim</label>
                <input type="checkbox" id="ultima" class="form-check-input" formControlName="ultima">
            </div>
        </div>
        <div class="col-12 col-xl-9">
            <div class="row">
                <ng-container *ngIf="formPerguntaMultiplaAlternativa.get('ultima')?.value != true">
                    <label for="redirecionamentoPergunta"
                        class="col-12 col-md-6 col-form-label mb-0 text-xl-end">Redirecionamento:</label>
                    <div class="col-12 col-md-6">
                        <select id="redirecionamentoPergunta" class="form-select"
                            formControlName="redirecionamentoPergunta">
                            <option value="0">Selecione uma opção!</option>
                            <ng-container *ngFor="let p of perguntas; let i = index">
                                <option [value]="p.id">Pergunta {{i+1}}</option>
                            </ng-container>
                        </select>
                    </div>
                </ng-container>
                <ng-container *ngIf="formPerguntaMultiplaAlternativa.get('ultima')?.value == true">
                    <div class="col mt-2">
                        <span class="me-3">Enviar Para:</span>
                        <label for="opcaoRedirecionamentoAPI" class="form-label me-1">API</label>
                        <input type="radio" value="0" id="opcaoRedirecionamentoAPI" name="opcaoRedirecionamento"
                            class="form-check-input" formControlName="opcaoRedirecionamento">

                        <label for="opcaoRedirecionamentoLink" class="ms-3 me-1 form-label">Link</label>
                        <input type="radio" value="1" id="opcaoRedirecionamentoLink" name="opcaoRedirecionamento"
                            class="form-check-input" formControlName="opcaoRedirecionamento">

                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="row mt-3 mb-3" *ngIf="formPerguntaMultiplaAlternativa.get('ultima')?.value == true">
        <div class="col-12 col-lg-3">
            <div class="form-group mt-2">
                <label for="btnWhatsApp" class="form-label mb-0 me-1">Exibir Botão WhatsApp? Sim</label>
                <input type="checkbox" id="btnWhatsApp" class="form-check-input" formControlName="exibirBtnWhatsApp">
            </div>
        </div>
    </div>

    <div class="row mt-3 mb-3" *ngIf="formPerguntaMultiplaAlternativa.get('ultima')?.value == true">

        <!-- Envio API -->

        <fieldset formGroupName="envioAPI" *ngIf="formPerguntaMultiplaAlternativa.get('opcaoRedirecionamento')?.value == 0">

            <div class="row">

                <div class="col col-md-3">

                    <label for="metodo" class="form-label mb-0">Método HTTP:</label>
                    <select id="metodo" class="form-select" formControlName="metodoHTTP">
                        <option value="POST">POST</option>
                        <option value="GET">GET</option>
                        <option value="PUT">PUT</option>
                        <option value="DELETE">DELETE</option>
                        <option value="PATCH">PATCH</option>
                    </select>

                </div>

                <div class="col col-md-9">

                    <label for="Iurl" class="form-label mb-0">URL:</label>
                    <input type="url" id="Iurl" class="form-control" formControlName="url">

                </div>

            </div>

            <h6 class="my-2">Dados:</h6>

            <button type="button" class="btn btn-sm btn-outline-primary mb-3 me-2" (click)="addDado()"><i class="fa-solid fa-plus"></i></button>
            <button type="button" class="btn btn-sm btn-outline-danger mb-3" (click)="rmvDado()"><i class="fa-solid fa-minus"></i></button>

            <fieldset formGroupName="dados">

                <ng-container *ngFor="let dado of formDado.controls; let i=index">
                    <ng-container [formGroup]="$any(dado)">

                        <div class="row justify-content-center" [ngClass]="{'mt-3':i != 0}">

                            <div class="col col-md-3">

                                <label [for]="gerarIdInput('nomeCampo',i)" class="form-label mb-0">Nome do Campo:</label>
                                <input type="text" [id]="gerarIdInput('nomeCampo',i)" class="form-control" formControlName="campo">

                            </div>

                            <div class="col col-md-3 d-flex align-items-center justify-content-center">
                                <div class="align-middle">
                                    <label [for]="gerarIdInput('valorEspecifico',i)" class="form-label mb-0 me-1">Valor Especifico? Sim</label>
                                    <input type="checkbox" class="form-check-input" [id]="gerarIdInput('valorEspecifico',i)" formControlName="valorEspecifico">
                                </div>
                            </div>

                            <div class="col col-md-6">

                                <label [for]="gerarIdInput('valor',i)" class="form-label mb-0">Valor:</label>
                                <input type="text" [id]="gerarIdInput('valor',i)" class="form-control" formControlName="valor"
                                    *ngIf="dado.get('valorEspecifico')?.value == true">

                                <select [id]="gerarIdInput('valor',i)" class="form-select" formControlName="valor"
                                    *ngIf="dado.get('valorEspecifico')?.value == false">
                                    <ng-container *ngFor="let valor of identificadores">
                                        <option [value]="valor">{{valor | uppercase}}</option>
                                    </ng-container>
                                </select>

                            </div>

                        </div>

                    </ng-container>
                </ng-container>

            </fieldset>

            <div class="row mt-5">
                <div class="col-12 col-md-4">
                    <div class="form-group mt-2">
                        <label for="proprio" class="form-label mb-0 me-1">Redirecionar para link próprio? Sim</label>
                        <input type="checkbox" id="proprio" class="form-check-input" formControlName="linkProprio">
                    </div>
                </div>
                <div class="col-12 col-md-8" *ngIf="this.formPerguntaMultiplaAlternativa.get('envioAPI')?.get('linkProprio')?.value == true">
                    <div class="row">
                        <label for="redirecionamentoLink" class="col-12 col-md-1 col-form-label mb-0 text-start">Link:</label>
                        <div class="col-12 col-md-11">
                            <input type="text" id="redirecionamentoLink" class="form-control" formControlName="link"/>
                        </div>
                    </div>
                </div>
            </div>

        </fieldset>

        <!-- Fim -->

        <div class="col" *ngIf="formPerguntaMultiplaAlternativa.get('opcaoRedirecionamento')?.value == 1">
            <label for="redirecionamentoLink" class="form-label mb-0">Link de Redirecionamento:</label>
            <input type="url" id="redirecionamentoLink" class="form-control" formControlName="redirecionamentoLink">
        </div>

    </div>

    <div class="row mt-3 mb-3" *ngIf="formPerguntaMultiplaAlternativa.get('ultima')?.value == true">

        <div class="col-12 col-lg-6">
            <label for="finaliza" class="form-label mb-0 me-1">Finaliza Fluxo? Sim</label>
            <input type="checkbox" id="finaliza" class="form-check-input" formControlName="finalizaFluxo">
        </div>

        <div class="col-12 col-lg-6" *ngIf="formPerguntaMultiplaAlternativa.get('finalizaFluxo')?.value == true">
            <label for="principal" class="form-label mb-0 me-1">Fluxo Principal? Sim</label>
            <input type="checkbox" id="principal" class="form-check-input" formControlName="fluxoPrincipal">
        </div>

        <div class="col-12 mt-2" *ngIf="(formPerguntaMultiplaAlternativa.get('finalizaFluxo')?.value == true) && formPerguntaMultiplaAlternativa.get('fluxoPrincipal')?.value == false">
            <label for="nomefluxo" class="form-label mb-0 me-1">Nome do Fluxo: </label>
            <input type="text" id="nomefluxo" class="form-control" formControlName="nomeFluxo">
        </div>

    </div>

</form>
