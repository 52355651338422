import { AfterViewInit, Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SpinnerService } from "src/app/compartilhados/componentes/spinners/spinner.service";
import { ConteudosPasta, PadraoConteudo } from "src/app/compartilhados/componentes/pasta/conteudos-pasta.model";
import { Pasta, PastaDados } from "src/app/modelos/pergunta.model";
import { PastaService } from "src/app/servicos/pasta-service.service";
import Swal from "sweetalert2";

declare var window:any;

@Component({
    selector: 'pasta',
    templateUrl: './pasta.component.html',
    styleUrls: ['./pasta.component.css']
})
export class PastaComponent<T extends PadraoConteudo> implements OnInit, AfterViewInit{

    @Input() id: string = "";
    @Input() grupo: string = "";
    @Input() assuntoConteudo: string = "item";
    @Input() conteudos: T[] = [];
    @Input() pastas: Pasta[] = [];

    @Output() eventConteudoSelecionado: EventEmitter<T> = new EventEmitter<T>();
    @Output() eventConteudosExcluido: EventEmitter<T[]> = new EventEmitter<T[]>();
    @Output() eventConteudosSemPasta: EventEmitter<T[]> = new EventEmitter<T[]>();
    @Output() eventConteudoMovidoPasta: EventEmitter<T> = new EventEmitter<T>();
    @Output() eventSalvarConteudoPasta: EventEmitter<{id: number,ids: number[]}> = new EventEmitter<{id: number,ids: number[]}>();

    public formNovaPasta!:FormGroup;
    public formEditarPasta!: FormGroup;

    private conteudosPasta: ConteudosPasta<T>[] = [];
    public conteudoSelecionado!: T;
    public pastaSelecionada!: Pasta;

    private conteudoSemPasta: T[] = [];

    private modalEditarPasta: any;
    private modalAddRmvConteudo: any;

    constructor(
        private fb: FormBuilder,
        private spinnerService: SpinnerService,
        private pastaService: PastaService
    ){}



    ngOnChanges(changes: SimpleChanges): void {
        this.separarConteudoEmPastas();
    }

    ngOnInit(): void {
        this.formNovaPasta = this.fb.group({
            nome: ['',[Validators.required]],
            grupo: [this.grupo,[]]
        });
        this.formEditarPasta = this.fb.group({
            id: ['',[Validators.required]],
            nome: ['',[Validators.required]]
        });
    }

    ngAfterViewInit(): void {
        this.modalEditarPasta = new window.bootstrap.Modal(document.getElementById(`modal-editar-pasta-${this.id}`));
        this.modalAddRmvConteudo = new window.bootstrap.Modal(document.getElementById(`modal-add-conteudo-pasta-${this.id}`))
    }

    private separarConteudoEmPastas(){

        if(this.pastas.length == 0) {
            this.eventConteudosSemPasta.emit(this.conteudos);
            return;
        }

        this.pastas.forEach(pasta => {
            this.addPastaEmConteudoPastas(pasta);
        });

        this.conteudos?.forEach(conteudo => {
            let semPasta = true;
            conteudo.pastas?.forEach(pasta => {
                let cp = this.conteudosPasta.find(cp => cp.pasta.id == pasta.id);
                if(cp){
                    cp.conteudos.push(conteudo);
                    semPasta = false;
                }
            });
            if(semPasta){
                this.conteudoSemPasta.push(conteudo);
            }
        });

        if(this.conteudoSemPasta.length > 0){
            this.eventConteudosSemPasta.emit(this.conteudoSemPasta);
        }

    }

    private addPastaEmConteudoPastas(pasta: Pasta){
        let indexConteudoPasta = this.buscarIndexConteudosPasta(pasta);
        if(indexConteudoPasta == -1){
            let novoConteudosPasta = {"pasta": pasta,"conteudos": [] } as ConteudosPasta<T>;
            this.conteudosPasta.push(novoConteudosPasta);
        }
    }

    //Ações do conteúdo

    public addConteudo(pasta: Pasta, conteudo: T){
        let indexPasta = this.buscarIndexConteudosPasta(pasta) as number;
        if(indexPasta != -1) {
            conteudo.pastas?.push(pasta);
            this.conteudosPasta[indexPasta].conteudos.push(conteudo);
        };
    }

    public rmvConteudo(pasta: Pasta, conteudo: T){
        let indexPasta = this.buscarIndexConteudosPasta(pasta) as number;
        if(indexPasta != -1) {
            let indexConteudo = this.conteudosPasta[indexPasta].conteudos.indexOf(conteudo);
            if(indexConteudo != -1) this.conteudosPasta[indexPasta].conteudos.splice(indexConteudo,1);
        }
    }

    public buscarConteudos(pasta: Pasta): T[] | undefined{
        let indexPasta = this.buscarIndexConteudosPasta(pasta) as number;
        if(indexPasta == -1) return undefined;
        return this.conteudosPasta[indexPasta].conteudos;
    }

    public buscarIndexConteudosPasta(pasta: Pasta): number | undefined{
        let cp = this.conteudosPasta.filter(cp => cp.pasta.id == pasta.id)[0];
        return this.conteudosPasta.indexOf(cp);
    }

    public buscarQtdConteudos(pasta: Pasta): number {
        let conteudo = this.buscarConteudos(pasta);
        if(!conteudo) return 0;
        return conteudo.length;
    }

    public buscarPastaConteudo(conteudo: T): Pasta | undefined{
        let pastaProcurada = undefined;
        this.conteudosPasta.forEach(cp => {
            conteudo.pastas?.forEach(pasta => {
                if(cp.pasta.id == pasta.id) pastaProcurada = cp.pasta;
            });
        })
        return pastaProcurada;
    }

    public selecionarConteudo(conteudo: T){
        this.conteudoSelecionado = conteudo;
        this.eventConteudoSelecionado.emit(this.conteudoSelecionado);
    }

    public isConteudoNaPastaSelecionada(conteudo: T): boolean{
        let conteudosPasta = this.buscarConteudos(this.pastaSelecionada);
        return conteudosPasta?.find(c => c == conteudo) ? true: false;
    }

    //Ações da pasta

    public addPasta(novaPasta: Pasta){
        this.pastas.push(novaPasta);
    }

    public alterarPasta(pasta: Pasta, pastaAlterada: Pasta){
        let indexPasta = this.buscarIndexPasta(pasta) as number;
        this.pastas[indexPasta].nome = pastaAlterada?.nome;
        this.pastas[indexPasta].dataUltimaModificacao = pastaAlterada?.dataUltimaModificacao;
    }

    public rmvPasta(pasta: Pasta){
        let indexPasta = this.buscarIndexPasta(pasta) as number;
        if(indexPasta != -1) this.pastas.splice(indexPasta,1);
    }

    public buscarIndexPasta(pasta: Pasta): number | undefined{
        return this.pastas.indexOf(pasta);
    }

    public selecionarPasta(pasta: Pasta){
        this.pastaSelecionada = pasta;
        this.formEditarPasta.get('id')?.setValue(this.pastaSelecionada.id);
        this.formEditarPasta.get('nome')?.setValue(this.pastaSelecionada.nome);
    }

    public gerarIdPainelPasta(pasta:Pasta): string{
        return `pasta-${pasta.id}`;
    }

    public isPastaSelecionada(pasta: Pasta): boolean{
        if(pasta == undefined || this.pastaSelecionada == undefined) return false;
        return pasta.id == this.pastaSelecionada.id;
    }

    public alternarConteudo(evento: any, conteudo: T){

        if(evento.target.checked){

            if((conteudo.pastas?.length as number) > 0) {
                conteudo.pastas?.filter(pasta => pasta.grupo == this.grupo).forEach(pasta => {
                    this.rmvConteudo(pasta,conteudo); //remove da outra pasta
                });
            } else {
                this.conteudoSemPasta.splice(this.conteudoSemPasta.indexOf(conteudo),1); //remove dos sem pasta
                this.eventConteudoMovidoPasta.emit(conteudo);
            }

            this.addConteudo(this.pastaSelecionada,conteudo);

        }else{ //retira da pasta
            this.rmvConteudo(this.pastaSelecionada,conteudo);
            if(this.conteudoSemPasta.indexOf(conteudo) == -1) {
                this.conteudoSemPasta.push(conteudo);
                this.eventConteudosSemPasta.emit(this.conteudoSemPasta);
            }
        }
    }

    public verificarConteudoNaPastaSelecionada(conteudo: T): boolean{
        let cp = this.conteudosPasta.find(cp => cp.pasta.id == this.pastaSelecionada?.id);
        if(cp?.conteudos.find(c => c.id == conteudo.id)) return true;
        return false;
    }

    //Ações de CRUD Pasta

    public cadastrarPasta(){

        let novaPasta = this.formNovaPasta.getRawValue() as PastaDados;
        novaPasta.id = null;

        this.spinnerService.ativarSpinner();

        this.pastaService.cadastrar(novaPasta).subscribe({
            next: pasta => {
                this.spinnerService.desativarSpinner();
                Swal.fire("Sucesso!","Nova Pasta Criada com Sucesso!","success");
                this.addPasta(pasta);
                this.addPastaEmConteudoPastas(pasta);
                this.selecionarPasta(this.pastas[this.pastas.length-1]);
            },
            error: error => {
                this.spinnerService.desativarSpinner();
                Swal.fire("Erro!","Erro ao criar pasta!","error");
            }
        });

    }

    public abrirModalEditarPasta(){
        if(this.pastas.length == 0) {
            Swal.fire("Atenção", "Não há pastas no momento!", "warning");
            return;
        }
        if(this.pastaSelecionada == null){
            Swal.fire('Atenção',"Nenhuma pasta selecionada!",'warning');
            return;
        }
        this.modalEditarPasta.show();
    }

    public editarPasta(){

        if(this.pastas.length == 0) {
            Swal.fire("Atenção", "Não há pastas no momento!", "warning");
            return;
        }

        if(this.pastaSelecionada == null) {
            Swal.fire("Atenção", "Por favor selecione uma pasta!", "warning");
            return;
        }

        Swal.fire({
            title: 'Deseja realmente alterar o nome da pasta?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {

            if(result.isConfirmed){

                this.spinnerService.ativarSpinner();

                let pasta = this.formEditarPasta.getRawValue() as PastaDados;

                this.pastaService.atualizar(pasta.id as number,pasta).subscribe({
                    next: pastaAlterada => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire("Sucesso!","Pasta alterada com sucesso!","success");
                        this.alterarPasta(this.pastaSelecionada,pastaAlterada);
                    },
                    error: error => {
                        Swal.fire("Erro!","Erro ao alterar pasta!","error");
                    }
                });

            }

        });

    }

    public excluirPasta(){

        if(this.pastas.length == 0) {
            Swal.fire("Atenção", "Não há pastas no momento!", "warning");
            return;
        }

        if(this.pastaSelecionada == null) {
            Swal.fire("Atenção", "Nenhuma pasta selecionada!", "warning");
            return;
        }

        Swal.fire({
            title: 'Você realmente quer excluir a pasta?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
          }).then((result) => {
            if (result.isConfirmed) {

                this.spinnerService.ativarSpinner();
                this.pastaService.excluir(this.pastaSelecionada.id).subscribe({
                    next: p => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire("Sucesso!","Pasta excluída com sucesso!","success");
                        let conteudo = this.buscarConteudos(this.pastaSelecionada);
                        if(conteudo != undefined) this.eventConteudosExcluido.emit(this.buscarConteudos(this.pastaSelecionada));
                        this.rmvPasta(this.pastaSelecionada);
                        if(this.pastas.length > 0) this.selecionarPasta(this.pastas[0]);
                    },
                    error: error => {
                        Swal.fire("Erro!", "Erro ao excluir pasta!", "error");
                    }
                });

            }
        });

    }

    public abrirModalAddRmvConteudo(){
        if(this.pastas.length == 0) {
            Swal.fire("Atenção", "Não há pastas no momento!", "warning");
            return;
        }

        if(this.pastaSelecionada == null){
            Swal.fire('Atenção','Nenhuma pasta selecionada!','warning');
            return;
        }
        this.modalAddRmvConteudo.show();
    }

    public salvarConteudoNaPasta(){

        if(this.pastas.length == 0) {
            Swal.fire("Atenção", "Não há pastas no momento!", "warning");
            return;
        }

        if(this.pastaSelecionada == null) {
            Swal.fire("Atenção", "Por favor selecione uma pasta!", "warning");
            return;
        }

        Swal.fire({
            title: `Você realmente quer salvar os ${this.assuntoConteudo}(s) na pasta?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                let idsConteudo = this.conteudosPasta.find(cp => cp.pasta.id == this.pastaSelecionada.id)?.conteudos.map(f => f.id) as number[];
                this.eventSalvarConteudoPasta.emit({id: this.pastaSelecionada.id as number,ids: idsConteudo});
            }
        });

    }

    //Script de correção de bug do Bootstrap
    public alternarPainelPasta(div: HTMLDivElement,pasta: Pasta){

        const CLASSE_FECHAR = 'fechar-suave';
        const CLASSE_ABRIR = 'abrir-suave';
        const ICONE_ACIMA = "fa-chevron-up";
        const ICONE_BAIXO = "fa-chevron-down";

        this.selecionarPasta(pasta);

        let painel = div.querySelector(`#${this.gerarIdPainelPasta(pasta)}`) as HTMLDivElement;
        let icone = div.getElementsByClassName('btn-pasta')[0].getElementsByClassName('btn-icone')[0] as HTMLElement;

        if(painel.classList.contains(CLASSE_FECHAR)){ //ABRIR
            painel.classList.remove(CLASSE_FECHAR);
            painel.classList.add(CLASSE_ABRIR);
            if(icone.classList.contains(ICONE_ACIMA)) {
                icone.classList.remove(ICONE_ACIMA);
                icone.classList.add(ICONE_BAIXO);
            }

        }else if(painel.classList.contains(CLASSE_ABRIR)){ //FECHAR
            painel.classList.remove(CLASSE_ABRIR);
            painel.classList.add(CLASSE_FECHAR);
            if(icone.classList.contains(ICONE_BAIXO)) {
                icone.classList.remove(ICONE_BAIXO);
                icone.classList.add(ICONE_ACIMA);
            }
        }

    }

    public getId(prefixo: string):string{
        return `${prefixo}-${this.id}`;
    }

}
