<div class="row">

    <div class="col-12">

        <div class="row justify-content-center">

            <div class="col-11 col-md-6 col-lg-4 col-xl-3 mt-5">

                <h4 class="text-center">Administração do Formulário</h4>

                <form [formGroup]="formAutenticacao" (ngSubmit)="entrar()">

                    <div class="form-group mt-4">
                        <label for="usuario" class="form-label mb-0"><i class="fa-solid fa-user me-1"></i>Usuário:</label>
                        <input type="email" id="usuario" data-test="email" class="form-control" formControlName="email"/>
                    </div>

                    <div class="form-group mt-4">
                        <label for="senha" class="form-label mb-0"><i class="fa-solid fa-lock me-1"></i>Senha:</label>
                        <input type="password" class="form-control" data-test="senha" id="senha" formControlName="senha"/>
                    </div>

                    <button type="submit" class="mt-4 btn btn-primary" [disabled]="formAutenticacao.invalid && desativaBotaoLogin">
                        <i class="fa-solid fa-right-to-bracket me-1"></i>Entrar
                    </button>

                </form>

            </div>

        </div>

    </div>

</div>
