import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SpinnerService } from "src/app/compartilhados/componentes/spinners/spinner.service";
import { EstiloFormulario, Formulario } from "src/app/modelos/formulario.model";
import { FormularioNome } from "src/app/modelos/formularioNome";
import { FormularioService } from "src/app/servicos/formulario.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { Pasta } from "src/app/modelos/pergunta.model";
import { PastaService } from "src/app/servicos/pasta-service.service";
import { ReplaySubject, debounceTime } from "rxjs";
import { OpenGraph } from "src/app/modelos/OpenGraph.model";
import { OpenGraphService } from "src/app/servicos/openGraph.service";

declare var window: any;

const PAUSA = 300;

@Component({
    selector: 'lista-forms',
    templateUrl: './lista-forms.component.html',
    styleUrls: ['./lista-forms.component.css'],
})
export class ListaFormsComponent implements OnInit {

    @Input() formularios!: Array<Formulario>;

    public formulariosSemPasta: Formulario[] = [];

    @Output() formulario: EventEmitter<Formulario> = new EventEmitter();

    public formSelecionado!: Formulario;

    public formNovoFormulario!: FormGroup;
    public campoUrlValido: boolean = true;
    public campoNomeValido: boolean = true;

    public formEditarNomeFormulario!: FormGroup;

    public pastas: Pasta[] = [];

    public formEstiloFormulario!: FormGroup;

    public excluirFormulario: boolean = false;
    public formularioASerExcluido!: Formulario;

    public grupoPasta: string = "adm-formulario";

    private modalEditarForm: any;
    private modalEstiloForm: any;
    private modalOpenGraph: any;

    public formOpenGraph!: FormGroup;

    constructor(
        private fb: FormBuilder,
        private formularioService: FormularioService,
        private spinnerService: SpinnerService,
        private pastaService: PastaService,
        private openGraphService: OpenGraphService
    ) { }

    ngOnInit(): void {
        this.buscarPastas();
        this.formNovoFormulario = this.fb.group({
            url: ['',Validators.required],
            nome: ['',Validators.required],
            versaoPrevNator: ['',Validators.required]
        });
        this.formNovoFormulario.get('url')?.valueChanges.pipe(debounceTime(PAUSA)).subscribe({
            next: valor => {
                this.formularioService.consultarURL(valor).subscribe({
                    next: resp => { this.campoUrlValido = resp.valueOf()}
                })
            }
        });
        this.formNovoFormulario.get('nome')?.valueChanges.pipe(debounceTime(PAUSA)).subscribe({
            next: valor => {
                this.formularioService.consultarNome(valor).subscribe({
                    next: resp => { this.campoNomeValido = resp.valueOf(); }
                })
            }
        });
        this.formEditarNomeFormulario = this.fb.group({
            url: ['', [Validators.required]],
            nome: ['', [Validators.required]],
            versaoPrevNator: ['',Validators.required],
            ativo: [false,[Validators.required]]
        });
        this.formEstiloFormulario = this.fb.group({
            id: ['', []],
            fonte: ['', []],
            corFonte: ['', []],
            corFundo: ['', []],
            exibeImagemFundo: [false, []],
            imagemFundoBase64: ['', []],
            chaveImagem: [{ value: '', disabled: true }, []],
            posicaoImagem: ['', []],
            mostrarBarraProgresso: [false,[]],
            corPassoProgresso: ['',[]],
            corBarraProgresso: ['',[]],
            opcaoCabecalho: [{value: "0"},[]],
            exibeImg1Cabecalho: ['',[]],
            imagemImg1Base64: ['',[]],
            chaveImg1Cabecalho: [{ value: '', disabled: true },[]],
            exibeImg2Cabecalho: ['',[]],
            imagemImg2Base64: ['',[]],
            chaveImg2Cabecalho: [{ value: '', disabled: true },[]],
	        exibeRodape: ['',[]],
            imagemRodapeBase64: ['',[]],
            chaveImagemRodape: [{ value: '', disabled: true },[]],
            exibirSplashscreen: [{value: true}]
        });
        this.formOpenGraph = this.fb.group({
            id: [null,[]],
            title: ['',[]],
            url: ['',[]],
            description: ['',[]],
            siteName: ['',[]],
            idFormulario: ['',[]],
            image: this.fb.group({
                id: [null,[]],
                url: ['',[]],
                secure_url: ['',[]],
                width: ['',[]],
                height: ['',[]],
                alt: ['',[]],
                type: ['',[]]
            }),
            video: this.fb.group({
                id: [null,[]],
                url: ['',[]],
                secure_url: ['',[]],
                width: ['',[]],
                height: ['',[]],
                alt: ['',[]],
                type: ['',[]]
            }),
            audio: this.fb.group({
                id: [null,[]],
                url: ['',[]],
                secure_url: ['',[]],
                type: ['',[]]
            })
        })
        this.modalEditarForm = new window.bootstrap.Modal(document.getElementById("modal-editar-nome-formulario"))
        this.modalEstiloForm = new window.bootstrap.Modal(document.getElementById("modal-editar-estilo-formulario"));
        this.modalOpenGraph = new window.bootstrap.Modal(document.getElementById("modal-openGraph"));
    }

    private buscarPastas() {
        this.spinnerService.ativarSpinner();
        this.pastaService.buscarPastas().subscribe({
            next: pastasRecebidas => {
                this.pastas = pastasRecebidas;
                this.spinnerService.desativarSpinner();
            },
            error: error => {
                this.spinnerService.desativarSpinner();
            }
        });
    }

    public selecionarFormulario(formulario: Formulario) {
        if (formulario) this.formSelecionado = formulario;
        let form = this.procurarFormulario(this.formSelecionado.id as number);
        if(form == undefined) form = this.procurarFormularioSemPasta(this.formSelecionado.id as number);
        if(form == undefined) return;
        this.formEditarNomeFormulario.get('nome')?.setValue(form?.nome);
        this.formEditarNomeFormulario.get('url')?.setValue(form?.url);
        this.formEditarNomeFormulario.get('versaoPrevNator')?.setValue(form?.versaoPrevNator)
        this.formEditarNomeFormulario.get('ativo')?.setValue(form.ativo)
        if (form.estilo) {
            this.formEstiloFormulario.get('id')?.setValue(form.estilo.id);
            this.formEstiloFormulario.get('fonte')?.setValue(form.estilo.fonte);
            this.formEstiloFormulario.get('corFonte')?.setValue(form.estilo.corFonte);
            this.formEstiloFormulario.get('corFundo')?.setValue(form.estilo.corFundo);
            this.formEstiloFormulario.get('exibeImagemFundo')?.setValue(form.estilo.exibeImagemFundo);
            this.formEstiloFormulario.get('imagemFundoBase64')?.setValue(form.estilo.imagemFundoBase64);
            this.formEstiloFormulario.get('chaveImagem')?.setValue(form.estilo.chaveImagem);
            this.formEstiloFormulario.get('posicaoImagem')?.setValue(form.estilo.posicaoImagem);
            this.formEstiloFormulario.get('mostrarBarraProgresso')?.setValue(form.estilo.mostrarBarraProgresso);
            this.formEstiloFormulario.get('corBarraProgresso')?.setValue(form.estilo.corBarraProgresso);
            this.formEstiloFormulario.get('corPassoProgresso')?.setValue(form.estilo.corPassoProgresso);
            this.formEstiloFormulario.get('opcaoCabecalho')?.setValue(form.estilo.opcaoCabecalho);
            this.formEstiloFormulario.get('exibeImg1Cabecalho')?.setValue(form.estilo.exibeImg1Cabecalho);
            this.formEstiloFormulario.get('imagemImg1Base64')?.setValue(form.estilo.imagemImg1Base64);
            this.formEstiloFormulario.get('chaveImg1Cabecalho')?.setValue(form.estilo.chaveImg1Cabecalho);
            this.formEstiloFormulario.get('exibeImg2Cabecalho')?.setValue(form.estilo.exibeImg2Cabecalho);
            this.formEstiloFormulario.get('imagemImg2Base64')?.setValue(form.estilo.imagemImg2Base64);
            this.formEstiloFormulario.get('chaveImg2Cabecalho')?.setValue(form.estilo.chaveImg2Cabecalho);
            this.formEstiloFormulario.get('exibeRodape')?.setValue(form.estilo.exibeRodape);
            this.formEstiloFormulario.get('imagemRodapeBase64')?.setValue(form.estilo.imagemRodapeBase64);
            this.formEstiloFormulario.get('chaveImagemRodape')?.setValue(form.estilo.chaveImagemRodape);
            this.formEstiloFormulario.get('exibirSplashscreen')?.setValue(form.estilo.exibirSplashscreen);
        } else {
            this.formEstiloFormulario.get('id')?.setValue(-1);
            this.formEstiloFormulario.get('fonte')?.setValue('');
            this.formEstiloFormulario.get('corFonte')?.setValue('');
            this.formEstiloFormulario.get('corFundo')?.setValue('');
            this.formEstiloFormulario.get('exibeImagemFundo')?.setValue(false);
            this.formEstiloFormulario.get('imagemFundoBase64')?.setValue('');
            this.formEstiloFormulario.get('chaveImagem')?.setValue('');
            this.formEstiloFormulario.get('posicaoImagem')?.setValue("Preencher");
            this.formEstiloFormulario.get('mostrarBarraProgresso')?.setValue(false);
            this.formEstiloFormulario.get('corBarraProgresso')?.setValue('#3B397F');
            this.formEstiloFormulario.get('corPassoProgresso')?.setValue('#000000');
            this.formEstiloFormulario.get('opcaoCabecalho')?.setValue('0');
            this.formEstiloFormulario.get('exibeImg1Cabecalho')?.setValue(true);
            this.formEstiloFormulario.get('imagemImg1Base64')?.setValue('');
            this.formEstiloFormulario.get('chaveImg1Cabecalho')?.setValue('');
            this.formEstiloFormulario.get('exibeImg2Cabecalho')?.setValue(true);
            this.formEstiloFormulario.get('imagemImg2Base64')?.setValue('');
            this.formEstiloFormulario.get('chaveImg2Cabecalho')?.setValue('');
            this.formEstiloFormulario.get('exibeRodape')?.setValue(true);
            this.formEstiloFormulario.get('imagemRodapeBase64')?.setValue('');
            this.formEstiloFormulario.get('chaveImagemRodape')?.setValue('');
            this.formEstiloFormulario.get('exibirSplashscreen')?.setValue(true);
        }
        if(form.openGraph){
            this.formOpenGraph.get('id')?.setValue(form.openGraph.id);
            this.formOpenGraph.get('title')?.setValue(form.openGraph.title);
            this.formOpenGraph.get('url')?.setValue(form.openGraph.url);
            this.formOpenGraph.get('description')?.setValue(form.openGraph.description);
            this.formOpenGraph.get('siteName')?.setValue(form.openGraph.siteName);
            this.formOpenGraph.get('idFormulario')?.setValue(form.openGraph.idFormulario);
            this.formOpenGraph.get('image')?.get('id')?.setValue(form.openGraph.image?.id);
            this.formOpenGraph.get('image')?.get('url')?.setValue(form.openGraph.image?.url);
            this.formOpenGraph.get('image')?.get('secure_url')?.setValue(form.openGraph.image?.secure_url);
            this.formOpenGraph.get('image')?.get('alt')?.setValue(form.openGraph.image?.alt);
            this.formOpenGraph.get('image')?.get('type')?.setValue(form.openGraph.image?.type);
            this.formOpenGraph.get('image')?.get('width')?.setValue(form.openGraph.image?.width);
            this.formOpenGraph.get('image')?.get('height')?.setValue(form.openGraph.image?.height);
            this.formOpenGraph.get('video')?.get('id')?.setValue(form.openGraph.video?.id);
            this.formOpenGraph.get('video')?.get('url')?.setValue(form.openGraph.video?.url);
            this.formOpenGraph.get('video')?.get('secure_url')?.setValue(form.openGraph.video?.secure_url);
            this.formOpenGraph.get('video')?.get('alt')?.setValue(form.openGraph.video?.alt);
            this.formOpenGraph.get('video')?.get('type')?.setValue(form.openGraph.video?.type);
            this.formOpenGraph.get('video')?.get('width')?.setValue(form.openGraph.video?.width);
            this.formOpenGraph.get('video')?.get('height')?.setValue(form.openGraph.video?.height);
            this.formOpenGraph.get('audio')?.get('id')?.setValue(form.openGraph.audio?.id);
            this.formOpenGraph.get('audio')?.get('url')?.setValue(form.openGraph.audio?.url);
            this.formOpenGraph.get('audio')?.get('secure_url')?.setValue(form.openGraph.audio?.secure_url);
            this.formOpenGraph.get('audio')?.get('type')?.setValue(form.openGraph.audio?.type);
        }else{
            this.formOpenGraph.reset();
        }
        this.formulario.emit(form);
    }

    public addForm() {

        Swal.fire({
            title: 'Deseja realmente criar novo formulário?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {

                this.spinnerService.ativarSpinner();

                let nome = this.formNovoFormulario.get('nome')?.value as string;
                let url = this.formNovoFormulario.get('url')?.value as string;
                let versao = this.formNovoFormulario.get('versaoPrevNator')?.value as string;

                let novoFormulario = {
                    id: null,
                    url: url,
                    nome: nome,
                    versaoPrevNator: versao,
                    ativo: true,
                    perguntas: []
                } as Formulario;

                this.formularioService.cadastrar(novoFormulario).subscribe({
                    next: form => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Formulário cadastrado com sucesso :) !', '', 'success');
                        this.formularios.push(form);
                        this.formulariosSemPasta.push(form);
                        this.selecionarFormulario(this.formulariosSemPasta[this.formulariosSemPasta.length - 1]);
                    },
                    error: error => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Erro ao cadastrar formulário :( !', '', 'error');
                    }
                });

            }
        });

    }

    public rmvForm() {
        if(this.formSelecionado == undefined) {
            Swal.fire('Atenção','Nenhum formulário selecionado', 'warning');
            return;
        }
        Swal.fire({
            title: `Deseja realmente excluir o formulário?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {

            if (result.isConfirmed) {

                this.spinnerService.ativarSpinner();

                this.formularioService.excluir(this.formSelecionado.id as number).subscribe({
                    next: resp => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Formulário excluído com sucesso :) !', '', 'success');
                        if(this.procurarFormulario(this.formSelecionado.id as number) && this.procurarFormulario(this.formSelecionado.id as number).pasta){
                            this.formularios.splice(this.procurarIndexFormulario(this.procurarFormulario(this.formSelecionado.id as number)), 1);
                        }
                        this.formulariosSemPasta.splice(this.procurarIndexFormularioSemPasta(this.procurarFormularioSemPasta(this.formSelecionado.id as number)), 1);
                        if(this.formulariosSemPasta.length > 0){
                            this.selecionarFormulario(this.formulariosSemPasta[0]);
                        }
                    },
                    error: error => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Erro ao excluir formulário :( !', '', 'error');
                    }
                });

            }

        });
    }

    public editarNomeForm() {
        Swal.fire({
            title: 'Deseja realmente alterar o formulário?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {

            if (result.isConfirmed) {

                this.spinnerService.ativarSpinner();

                let formularioNome = this.formEditarNomeFormulario.getRawValue() as FormularioNome;

                this.formularioService.atualizarNome(this.formSelecionado.id as number, formularioNome).subscribe({
                    next: formNovo => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Formulário alterado com sucesso :) !', '', 'success');
                        this.formularios[this.procurarIndexFormulario(this.procurarFormulario(this.formSelecionado.id as number))] = formNovo;
                        this.addFormularioSemPasta(this.formularios);
                    },
                    error: erro => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Erro ao alterar formulário :( !', '', 'error');
                    }
                });

            }

        });
    }

    private procurarFormulario(id: number): Formulario {
        return this.formularios.find(f => f.id == id) as Formulario;
    }

    private procurarFormularioSemPasta(id: number): Formulario {
        return this.formulariosSemPasta.find(f => f.id == id) as Formulario;
    }

    private procurarIndexFormulario(f: Formulario): number {
        return this.formularios.indexOf(f) as number;
    }

    private procurarIndexFormularioSemPasta(f: Formulario): number {
        return this.formulariosSemPasta.indexOf(f) as number;
    }

    public clonarFormulario() {
        if(this.formSelecionado == undefined) {
            Swal.fire('Atenção!', 'Nenhum formulário selecionado!!', 'warning')
            return;
        }
        Swal.fire({
            title: `Deseja realmente clonar o formulário ${this.formSelecionado.nome}?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {

            if (result.isConfirmed) {

                this.spinnerService.ativarSpinner();

                this.formularioService.clonar(this.formSelecionado.id as number).subscribe({
                    next: formularioClonado => {
                        this.formularioService.buscarPorId(formularioClonado.id as number).subscribe({
                            next: form => {
                                this.spinnerService.desativarSpinner();
                                Swal.fire('Formulário clonado com sucesso :) !', '', 'success');
                                this.formulariosSemPasta.push(form);
                                this.selecionarFormulario(this.formulariosSemPasta[this.formulariosSemPasta.length - 1]);
                            },
                            error: error => {
                                this.spinnerService.desativarSpinner();
                                Swal.fire('Erro ao clonar formulário :( !', '', 'error');
                            }
                        })
                    },
                    error: error => {
                        this.spinnerService.desativarSpinner();
                        Swal.fire('Erro ao clonar formulário :( !', '', 'error');
                    }
                });

            }

        });
    }

    public abrirPrevNator() {
        if(this.formSelecionado == undefined) {
            Swal.fire('Atenção!', 'Nenhum formulário Selecionado!!', 'warning');
            return;
        }
        let url = `${environment.urlPrevNator}/${this.procurarFormulario(this.formSelecionado.id as number).url}`;
        window.open(url, "_blank");
    }

    public addFormularioSemPasta(formularios: Formulario[]) {
        this.formulariosSemPasta = [];
        formularios.forEach(formulario => {
            let f = this.formulariosSemPasta.find(fsp => fsp.id == formulario.id);
            if(f == undefined){
                this.formulariosSemPasta.push(formulario);
            }
        });
    }

    public receberFormularioSelecionado(formulario: Formulario) {
        this.selecionarFormulario(formulario);
    }

    public receberFormulariosDePastaExcluida(formularios: Formulario[]) {
        formularios.forEach(formulario => {
            this.formulariosSemPasta.push(formulario);
        });
    }

    public rmvFormularioSemPasta(formulario: Formulario) {
        this.formulariosSemPasta.splice(this.formulariosSemPasta.indexOf(formulario), 1);
    }

    public salvarFormularioEmPasta(dados: any) {

        this.spinnerService.ativarSpinner();

        this.pastaService.addFormulario(dados.id, dados.ids).subscribe({
            next: a => {
                this.spinnerService.desativarSpinner();
                Swal.fire("Sucesso!", "Formulário(s) salvos na pasta!", "success");
            },
            error: error => {
                this.spinnerService.desativarSpinner();
                Swal.fire("Erro!", "Erro ao tentar salvar formulário(s) na pasta!", "error");
            }
        });

    }

    public getFontECorEscolhida(): string {
        return `font-family: ${this.formEstiloFormulario.get('fonte')?.value};
            color: ${this.formEstiloFormulario.get('corFonte')?.value}`;
    }

    public getCorEFundoEscolhido(): string {
        let estilo = `background-color: ${this.formEstiloFormulario.get('corFundo')?.value};`;

        if (this.formEstiloFormulario.get('exibeImagemFundo')?.value == false) {
            return estilo;
        } else if(this.formEstiloFormulario.get('exibeImagemFundo')?.value == true) {
            estilo += `background-image: url('data:image/jpg;base64,${this.formEstiloFormulario.get('imagemFundoBase64')?.value}');background-repeat: no-repeat;`;
            if (this.formEstiloFormulario.get('posicaoImagem')?.value == "Centralizar") {
                estilo += `background-position: center;`;
                return estilo;
            } else if (this.formEstiloFormulario.get('posicaoImagem')?.value == "Preencher") {
                estilo += `background-size: cover;`;
                return estilo;
            }
            return estilo;
        }

        return estilo;
    }

    public salvarEstiloFormulario() {

        Swal.fire({
            title: `Deseja realmente alterar o estilo do formulário?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {

            if (result.isConfirmed) {

                let estilo = this.formEstiloFormulario.getRawValue() as EstiloFormulario;
                console.log(estilo);

                if(estilo.exibeImagemFundo == true && ( estilo.posicaoImagem == null || estilo.imagemFundoBase64 == null)){
                    Swal.fire("Atenção", "Por favor Informe o arquivo e a posição da imagem de fundo","warning");

                }else{

                    this.spinnerService.ativarSpinner();

                    this.formularioService.atualizarEstilo(this.formSelecionado.id as number, estilo).subscribe({
                        next: formularioEstiloEditado => {
                            this.formularios[this.procurarIndexFormulario(this.procurarFormulario(this.formSelecionado.id as number))] = formularioEstiloEditado;
                            this.spinnerService.desativarSpinner();
                            console.log(formularioEstiloEditado);
                            this.formulario.emit(formularioEstiloEditado);
                            Swal.fire("Sucesso!", "Estilo do Formulário salvo com sucesso!", "success");
                        },
                        error: error => {
                            this.spinnerService.desativarSpinner();
                            Swal.fire("Erro!", "Erro ao tentar salvar estilo do formulário", "error");
                        }
                    });

                }

            }

        });

    }

    public salvarOpenGraph(){

        Swal.fire({
            title: `Deseja realmente salvar as opções de open graph?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {

            if (result.isConfirmed) {

                let openGraph = this.formOpenGraph.getRawValue() as OpenGraph;

                this.spinnerService.ativarSpinner();

                if(!openGraph.id){ //Cadastrar

                    openGraph.idFormulario = this.formSelecionado.id as number;

                    this.openGraphService.cadastrar(openGraph).subscribe({
                        next: newOpenGraph => {
                            this.formSelecionado.openGraph = newOpenGraph;
                            this.spinnerService.desativarSpinner();
                            Swal.fire('Sucesso!',"Opções de Open Graph salvas com sucesso!","success");
                        },
                        error: error => {
                            console.log(error);
                            this.spinnerService.desativarSpinner();
                            Swal.fire("Erro!","Erro ao salvar opções de open graph!", "error");
                        }
                    })

                }else{ //Atualizar

                    let id = this.formOpenGraph.get('id')?.value as number;

                    this.openGraphService.alterar(id, openGraph).subscribe({
                        next: newOpenGraph => {
                            this.formSelecionado.openGraph = newOpenGraph;
                            this.spinnerService.desativarSpinner();
                            Swal.fire('Sucesso!',"Opções de Open Graph salvas com sucesso!","success");
                        },
                        error: error => {
                            console.log(error);
                            this.spinnerService.desativarSpinner();
                            Swal.fire("Erro!","Erro ao salvar opções de open graph!", "error");
                        }
                    });

                }

            }
        
        })

    }

    public exibirImagemFundo(): boolean {
        return this.formEstiloFormulario.get('exibeImagemFundo')?.value;
    }

    private tratarArquivo(event: any): ReplaySubject<string> {
        let file = event.target.files[0] as File;
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(window.btoa(event.target?.result?.toString() as string));
        return result;
    }

    public arquivoImagem(event: any, opcao: number) {

        let result = this.tratarArquivo(event);

        result.subscribe({
            next: arquivoBase64 => {
                switch(opcao){
                    case 0:
                        this.formEstiloFormulario.get('imagemFundoBase64')?.setValue(arquivoBase64);
                    break;
                    case 1:
                        this.formEstiloFormulario.get('imagemImg1Base64')?.setValue(arquivoBase64);
                    break;
                    case 2:
                        this.formEstiloFormulario.get('imagemImg2Base64')?.setValue(arquivoBase64);
                    break;
                    case 3:
                        this.formEstiloFormulario.get('imagemRodapeBase64')?.setValue(arquivoBase64);
                    break;
                }
            }
        });

    }


    public getCorPassoProgresso(): string{
        let cor = this.formEstiloFormulario.get('corPassoProgresso')?.value;
        if(cor == '') return "#000000";
        return cor;
    }

    public getCorBarraProgresso(): string{
        let cor = this.formEstiloFormulario.get('corBarraProgresso')?.value;
        if(cor == '') return "#3B397F";
        return cor;
    }

    public abrirModalEditarForm(){
        if(this.formSelecionado == undefined){
            Swal.fire('Atenção','Nenhum formulário Selecionado!','warning');
        }else{
            this.modalEditarForm.show();
        }
    }

    public abrirModalEstiloForm(){
        if(this.formSelecionado == undefined){
            Swal.fire('Atenção','Nenhum formulário Selecionado!','warning');
        }else{
            this.modalEstiloForm.show();
        }
    }

    public abrirModalOpenGraph(){
        if(this.formSelecionado == undefined){
            Swal.fire('Atenção','Nenhum formulário Selecionado!','warning');
        }else{
            this.modalOpenGraph.show();
        }
    }

}
