import { TipoResposta } from "./tipo-resposta.model"

export interface Pergunta{
    id: number,
    posicao: number
    identificador: string,
    titulo: string,
    exibeImagem?: boolean,
    imagemBase64?: string,
    chaveImagem?:string,
    descricao: string,
    resposta: Resposta,
    ultima: boolean
    opcaoRedirecionamento: number,
    redirecionamentoPergunta?: number,
    redirecionamentoLink?: string,
    envioAPI?: EnvioAPI,
    indiceBarraProgresso?: number,
    totalIndiceBarraProgresso?: number,
    exibirTitulo: boolean;
    exibeTextoImagem: boolean;
    textoImagem: string;
    alturaBoxImagem?: number,
    margemTopBoxImagem?: number,
    alturaBoxVideo?: number,
    margemTopBoxVideo?: number,
    alturaBoxHtml?: number,
    margemTopBoxHtml?: number,
    marcada?: boolean,
    finalizaFluxo?: boolean;
    fluxoPrincipal?: boolean;
    nomeFluxo?: string;
    exibirBtnWhatsApp?: boolean;
    personalizarBtnAvancar?: boolean;
    botaoAvancar?: BotaoAvancar;
    tituloCampo?: string;
}

export interface Resposta{
    id: number,
    tipoResposta: TipoResposta,
    exibirLetraAlternativa?: boolean;
    alternativas?: Alternativa[],
    guarda: boolean,
    placeholder?: string,
    linkVideo?: string,
    gifVideo?: string,
    fullScreen: boolean,
    tempoFullScreen: number,
    validacoes?: Validacao[],
    opcaoLayout?: number
}

export interface Alternativa{
    id: number,
    titulo: string,
    ultima?: boolean,
    redirecionamentoPergunta?: number  //id da proxima pergunta
    redirecionamentoLink?: string
    estiloProprio?: boolean;
	estilo?: EstiloAlternativa;
    marcadaA?: boolean,
    burladorPersonalizado?: boolean;
    idBurlador?: number;
    burlador?: Burlador;
    finalizaFluxo?: boolean;
    fluxoPrincipal?: boolean;
    nomeFluxo?: string;
    opcaoDefault: boolean;
}

export interface EstiloAlternativa{
    id: number,
	corFundo: string,
	corFundoSelecionado: string,
	corTitulo: string,
	corTituloSelecionada: string,
	corFundoLetra: string,
	corLetra: string,
	corFundoLetraSelecionado: string,
	corLetraSelecionada: string,
    corBorda: string,
	corBordaSelecionada: string,
	corBordaLetra: string,
	corBordaLetraSelecionada: string,
    exibirIconeEsquerdo: boolean;
	iconeEsquerdo: string;
	exibirIconeDireito: boolean;
	iconeDireito: string;
}

export interface Burlador{
    id: number,
    nome: string,
    linkProprio: boolean,
    link: string,
    idFormulario: number
}

export interface Validacao{
    id: number | null,
    tipo: TipoValidacao,
    valor: string,
    mensagem: string
}

export enum TipoValidacao{
    REQUERIDA = "Requerida",
    VALOR_MINIMO = "Valor Mínimo",
    VALOR_MAXIMO = "Valor Máximo",
    ALFANUMERICO = "Alfanúmerico"
}

export interface EnvioAPI{
    id: number,
    metodoHTTP: string,
    url: string,
    linkProprio?: boolean,
    link?: boolean
    dados: DadoEnvioAPI[]
}

export interface DadoEnvioAPI{
    id: number,
    campo: string,
    valorEspecifico: boolean,
    valor: string
}

export interface PastaDados{
    id: number | null,
    nome: string
}

export interface Pasta{
    id: number,
    nome: string,
    grupo: string,
    dataHoraCriacao: string,
    dataUltimaModificacao: string
}

export interface BotaoAvancar {
    id: number;
    textoBotaoPersonalizado: boolean;
    textoBotao: string;
    corFundo: string;
    corFundoSelecionado: string;
    corTitulo: string;
    corTituloSelecionada: string;
    corFundoLetra: string;
    corLetra: string;
    corFundoLetraSelecionado: string;
    corLetraSelecionada: string;
    corBorda: string;
    corBordaSelecionada: string;
    corBordaLetra: string;
    corBordaLetraSelecionada: string;
    exibirIconeEsquerdo: boolean;
    iconeEsquerdo: string;
    exibirIconeDireito: boolean;
    iconeDireito: string;
}
