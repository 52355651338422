import { Directive, ElementRef, Input, TemplateRef } from "@angular/core";

@Directive({
    selector: '[dataBsTarget]'
})
export class DataBsTargetDirective{

    constructor(private eleRef: ElementRef) {}

    @Input() set dataBsTarget(id: string){
        let btn = this.eleRef.nativeElement as HTMLButtonElement;
        btn.setAttribute("data-bs-target",`#${id}`);
    }

}
