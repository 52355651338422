<button type="button" class="btn btn-sm btn-outline-primary d-inline" data-bs-toggle="modal"
    [dataBsTarget]="gerarId()"><i class="fa fa-edit me-1"></i>Personalizar Botão
</button>

<div class="modal fade" tabindex="-1" [id]="gerarId()">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5">{{tituloModal}}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">

                    <div class="row">

                        <div class="col-12 mb-4">
                            <h5 class="mb-1">Botão:</h5>
                            <button class="base-btn-alternativa btn-alternativa" #btnAlternativa><span class="base-letra-alternativa letra-alternativa ms-1 me-1" *ngIf="ativarLetra">A</span>{{titulo}}</button>
                            <form [formGroup]="formBtn" class="mt-2">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <label for="corTitulo">Cor do Titulo: </label>
                                        <input type="color" class="form-control form-control-color" formControlName="corTitulo" id="corTitulo">
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label for="corFundo">Cor do Fundo: </label>
                                        <input type="color" class="form-control form-control-color" formControlName="corFundo" id="corFundo">
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label for="corBorda">Cor da Borda: </label>
                                        <input type="color" class="form-control form-control-color" formControlName="corBorda" id="corBorda">
                                    </div>
                                </div>
                                <div class="row mt-1" *ngIf="ativarLetra">
                                    <div class="col-12 col-md-4">
                                        <label for="corLetra">Cor da Letra: </label>
                                        <input type="color" class="form-control form-control-color" formControlName="corLetra" id="corLetra">
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label for="corFundoLetra">Cor do Fundo da Letra:</label>
                                        <input type="color" class="form-control form-control-color" formControlName="corFundoLetra" id="corFundoLetra">
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label for="corBordaLetra">Cor da Borda da Letra: </label>
                                        <input type="color" class="form-control form-control-color" formControlName="corBordaLetra" id="corBordaLetra">
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-12 mb-4" *ngIf="ativarLetra">
                            <h5 class="mb-1">Botão Selecionado:</h5>
                            <button class="base-btn-alternativa btn-alternativa-selecionado" #btnAlternativaSelecionado><span class="base-letra-alternativa letra-alternativa-selecionada ms-1 me-1" *ngIf="ativarLetra">A</span>{{titulo}}</button>
                            <form [formGroup]="formBtnSelecionado" class="mt-2">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <label for="corTituloSelecionada">Cor do Titulo: </label>
                                        <input type="color" class="form-control form-control-color" formControlName="corTituloSelecionada" id="corTituloSelecionada">
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label for="corFundoSelecionado">Cor do Fundo: </label>
                                        <input type="color" class="form-control form-control-color" formControlName="corFundoSelecionado" id="corFundoSelecionado">
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label for="corBordaSelecionada">Cor da Borda: </label>
                                        <input type="color" class="form-control form-control-color" formControlName="corBordaSelecionada" id="corBordaSelecionada">
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-12 col-md-4">
                                        <label for="corLetraSelecionada">Cor da Letra: </label>
                                        <input type="color" class="form-control form-control-color" formControlName="corLetraSelecionada" id="corLetraSelecionada">
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label for="corFundoLetraSelecionado">Cor do Fundo da Letra:</label>
                                        <input type="color" class="form-control form-control-color" formControlName="corFundoLetraSelecionado" id="corFundoLetraSelecionado">
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <label for="corBordaLetraSelecionada">Cor da Borda da Letra: </label>
                                        <input type="color" class="form-control form-control-color" formControlName="corBordaLetraSelecionada" id="corBordaLetraSelecionada">
                                    </div>
                                </div>
                            </form>

                        </div>

                        <div class="col-12">
                            <h5 class="mb-1">Icones</h5>
                            <form [formGroup]="formIconesBtn">
                                <div class="row">
                                    <div class="col-6">
                                        <label for="exibirIconeEsquerdo" class="form-check-label me-1">Exibir Icone Esquerdo? Sim</label>
                                        <input type="checkbox" id="exibirIconeEsquerdo" class="form-check-input"  formControlName="exibirIconeEsquerdo">
                                    </div>
                                    <div class="col-6">
                                        <label for="exibirIconeDireito" class="form-check-label me-1">Exibir Icone Direito? Sim</label>
                                        <input type="checkbox" id="exibirIconeDireito" class="form-check-input" formControlName="exibirIconeDireito">
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-6" >
                                        <ng-container *ngIf="formIconesBtn.get('exibirIconeEsquerdo')?.value == true">
                                            <label for="iconeEsquerdo" class="form-label mb-0">icone esquerdo: </label>
                                            <input type="text" id="iconeEsquerdo" class="form-control" formControlName="iconeEsquerdo">
                                        </ng-container>
                                    </div>
                                    <div class="col-6" >
                                        <ng-container *ngIf="formIconesBtn.get('exibirIconeDireito')?.value == true">
                                            <label for="iconeDireito" class="form-label mb-0">icone direito: </label>
                                            <input type="text" id="iconeDireito" class="form-control" formControlName="iconeDireito">
                                        </ng-container>
                                    </div>
                                </div>

                            </form>

                            <span style="font-size: 11px;"><a href="https://fonts.google.com/icons" target="_blank">Icones</a></span>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i class="fa-solid fa-circle-xmark me-1"></i>Fechar</button>
                <button type="button" (click)="salvar()" class="btn btn-primary" data-bs-dismiss="modal"><i class="fa-solid fa-floppy-disk me-1"></i>Salvar</button>
            </div>
        </div>
    </div>
</div>
