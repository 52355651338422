import { Component, DoCheck, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ReplaySubject } from "rxjs";
import { DadoEnvioAPI, EstiloAlternativa, Pergunta } from "src/app/modelos/pergunta.model";
import { TipoResposta } from "src/app/modelos/tipo-resposta.model";
import { FormPerguntaService } from "../form-pergunta.service";
import { EstiloFormulario } from "src/app/modelos/formulario.model";

@Component({
    selector: 'form-pergunta-video',
    templateUrl: './form-pergunta-video.component.html',
    styleUrls: ['./form-pergunta-video.component.css']
})
export class FormPerguntaVideoComponent implements OnChanges, DoCheck{

    @Input() pergunta!: Pergunta;
    @Input() perguntas!: Array<Pergunta> | null;
    @Input() mostrarBarraProgresso: boolean = false;
    @Input() estiloFormulario!: EstiloFormulario | undefined | null;

    @Output() perguntaEditada: EventEmitter<Pergunta> = new EventEmitter();
    @Output() perguntaSalva: EventEmitter<Pergunta> = new EventEmitter();
    @Output() perguntaCadastrar: EventEmitter<Pergunta> = new EventEmitter();

    public formPerguntaVideo!: FormGroup;

    @Input() variaveis!: Array<string>;

    public capturarEnvioAPI:boolean = false;
    public identificadores: string[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private formPerguntaService: FormPerguntaService
    ){}

    ngOnChanges(){

        this.restaurarUltimaVersaoPergunta();

        this.formPerguntaVideo = this.formBuilder.group({
            id: [this.pergunta.id,[Validators.required]],
            posicao: [this.pergunta.posicao,[Validators.required]],
            identificador: [this.pergunta.identificador,[Validators.required]],
            titulo: [{value: this.pergunta.titulo, disabled: true},[Validators.required]],
            descricao: [{value: this.pergunta.descricao, disabled: true},[Validators.required]],
            exibeImagem: [this.pergunta.exibeImagem,[]],
            imagemBase64: [this.pergunta.imagemBase64,[]],
            urlImagemBase64: [{value: this.pergunta.chaveImagem, disabled: true},[]],
            ultima: [this.pergunta.ultima,[Validators.required]],
            opcaoRedirecionamento: [this.pergunta.opcaoRedirecionamento.toString(),[]],
            redirecionamentoPergunta: [this.pergunta.redirecionamentoPergunta,[]],
            redirecionamentoLink: [this.pergunta.redirecionamentoLink,[]],
            indiceBarraProgresso: [this.pergunta.indiceBarraProgresso,[]],
            totalIndiceBarraProgresso: [this.pergunta.totalIndiceBarraProgresso,[]],
            exibirTitulo: [this.pergunta.exibirTitulo,[]],
            exibeTextoImagem: [this.pergunta.exibeTextoImagem,[]],
            textoImagem: [this.pergunta.textoImagem,[]],
            alturaBoxImagem: [this.pergunta.alturaBoxImagem,[]],
            margemTopBoxImagem: [this.pergunta.margemTopBoxImagem,[]],
            alturaBoxVideo: [this.pergunta.alturaBoxVideo,[]],
            margemTopBoxVideo: [this.pergunta.margemTopBoxVideo,[]],
            alturaBoxHtml: [this.pergunta.alturaBoxHtml,[]],
            margemTopBoxHtml: [this.pergunta.margemTopBoxHtml,[]],
            finalizaFluxo: [this.pergunta.finalizaFluxo,[]],
            fluxoPrincipal: [this.pergunta.fluxoPrincipal,[]],
            nomeFluxo: [this.pergunta.nomeFluxo,[]],
            exibirBtnWhatsApp: [this.pergunta.exibirBtnWhatsApp],
            personalizarBtnAvancar: [this.pergunta.personalizarBtnAvancar],
            botaoAvancar: this.formBuilder.group({
                id: [this.pergunta.botaoAvancar?.id],
                textoBotaoPersonalizado: [this.pergunta.botaoAvancar?.textoBotaoPersonalizado],
                textoBotao: [this.pergunta.botaoAvancar?.textoBotao ? this.pergunta.botaoAvancar.textoBotao : 'Continuar'],
                corFundo: [this.pergunta.botaoAvancar?.corFundo ? this.pergunta.botaoAvancar.corFundo : '#3C3A80'],
                corFundoSelecionado: [this.pergunta.botaoAvancar?.corFundoSelecionado ? this.pergunta.botaoAvancar.corFundoSelecionado : '#ffffff'],
                corTitulo: [this.pergunta.botaoAvancar?.corTitulo ? this.pergunta.botaoAvancar.corTitulo : '#ffffff'],
                corTituloSelecionada: [this.pergunta.botaoAvancar?.corTituloSelecionada ? this.pergunta.botaoAvancar.corTituloSelecionada : '#3C3A80'],
                corFundoLetra: [this.pergunta.botaoAvancar?.corFundoLetra ? this.pergunta.botaoAvancar.corFundoLetra : '#ffffff'],
                corLetra: [this.pergunta.botaoAvancar?.corLetra ? this.pergunta.botaoAvancar.corLetra : '#3C3A80'],
                corFundoLetraSelecionado: [this.pergunta.botaoAvancar?.corFundoLetraSelecionado ? this.pergunta.botaoAvancar.corFundoLetraSelecionado : '#3C3A80'],
                corLetraSelecionada: [this.pergunta.botaoAvancar?.corLetraSelecionada ? this.pergunta.botaoAvancar.corLetraSelecionada : '#ffffff'],
                corBorda: [this.pergunta.botaoAvancar?.corBorda ? this.pergunta.botaoAvancar.corBorda : '#3C3A80'],
                corBordaSelecionada: [this.pergunta.botaoAvancar?.corBordaSelecionada ? this.pergunta.botaoAvancar.corBordaSelecionada : '#3C3A80'],
                corBordaLetra: [this.pergunta.botaoAvancar?.corBordaLetra ? this.pergunta.botaoAvancar.corBordaLetra : '#ffffff'],
                corBordaLetraSelecionada: [this.pergunta.botaoAvancar?.corBordaLetraSelecionada ? this.pergunta.botaoAvancar.corBordaLetraSelecionada : '#3C3A80'],
                exibirIconeEsquerdo: [this.pergunta.botaoAvancar?.exibirIconeEsquerdo],
                iconeEsquerdo: [this.pergunta.botaoAvancar?.iconeEsquerdo],
                exibirIconeDireito: [this.pergunta.botaoAvancar?.exibirIconeDireito],
                iconeDireito: [this.pergunta.botaoAvancar?.iconeDireito]
            }),
            resposta: this.formBuilder.group({
                id: [this.pergunta.resposta.id,[Validators.required]],
                tipoResposta: [{value: this.pergunta.resposta.tipoResposta, disabled: true},[Validators.required]],
                guarda: [this.pergunta.resposta.guarda,[Validators.required]],
                placeholder: [this.pergunta.resposta.placeholder,[]],
                linkVideo: [this.pergunta.resposta.linkVideo,[Validators.required]],
                gifVideo: [this.pergunta.resposta.gifVideo],
                fullScreen: [this.pergunta.resposta.fullScreen, [Validators.required]],
                tempoFullScreen: [this.pergunta.resposta.tempoFullScreen]
            }),
            envioAPI: this.formBuilder.group({
                id: [this.pergunta.envioAPI?.id,[Validators.required]],
                metodoHTTP: [this.pergunta.envioAPI?.metodoHTTP,[Validators.required]],
                url: [this.pergunta.envioAPI?.url,[Validators.required]],
                linkProprio: [this.pergunta.envioAPI?.linkProprio,[]],
                link: [this.pergunta.envioAPI?.link,[]],
                dados: this.formBuilder.array([])
            })
        });
        this.pergunta?.envioAPI?.dados.forEach(dado => {
            this.formDado.push(this.criarDado(dado));
        });
        this.identificadores = (this.perguntas as Pergunta[]).filter(p =>
            p.resposta.tipoResposta != TipoResposta.INFORMATIVO_TEXTO)
        .filter(p => p.resposta.tipoResposta != TipoResposta.INFORMATIVO_VIDEO)
        .filter(p => p.resposta.tipoResposta != TipoResposta.INFORMATIVO_VIDEO_PERSONALIZADO)
            .map(p => p.identificador) as string[];
    }

    private restaurarUltimaVersaoPergunta(){
        let p = this.formPerguntaService.getPerguntaUltimaVersao(this.pergunta.id);
        if(p) this.pergunta = p;
    }

    ngDoCheck(): void {
        let f = this.formPerguntaVideo.getRawValue() as Pergunta;
        this.formPerguntaService.addPerguntaAlterar(f);
    }

    get formDado(): FormArray{
        return this.formPerguntaVideo.get('envioAPI')?.get('dados') as FormArray;
    }

    private criarDado(dado?: DadoEnvioAPI):FormGroup{
        return this.formBuilder.group({
            id: [dado?.id,[Validators.required]],
            campo: [dado?.campo,[Validators.required]],
            valorEspecifico: [dado?.valorEspecifico == null ? false : dado?.valorEspecifico,[Validators.required]],
            valor: [dado?.valor,[Validators.required]]
        });
    }

    public addDado(){
        this.formDado.push(this.criarDado());
    }

    public rmvDado(){
        this.formDado.removeAt(this.formDado.length -1);
    }

    public gerarIdInput(prefixo:string,id: number):string{
        return `${prefixo}-${id}`;
    }

    public guardaPergunta(){
        let f = this.formPerguntaVideo.getRawValue() as Pergunta;
        this.perguntaSalva.emit(f);
    }

    public getTitulo():string{
        return this.formPerguntaVideo.get('titulo')?.value as string;
    }

    public getDescricao():string{
        return this.formPerguntaVideo.get('descricao')?.value as string;
    }

    public getTextoImagem():string{
        return this.formPerguntaVideo.get("textoImagem")?.value as string;
    }

    public videoFormatadoTitulo(texto: string){
        this.formPerguntaVideo.get('titulo')?.setValue(texto);
    }

    public videoFormatadoDescricao(texto: string){
        this.formPerguntaVideo.get('descricao')?.setValue(texto);
    }

    public textoFormatadoTextoImagem(texto: string){
        this.formPerguntaVideo.get('textoImagem')?.setValue(texto);
    }

    public gerarIdFormatador(radical: string){
        return `video${radical}${this.pergunta.identificador}`;
    }

    public gerarIdVisualizador(radical: string){
        return `visualizador${radical}${this.pergunta.identificador}`;
    }

    public tratarArquivo(event: any){

        let file = event.target.files[0] as File;

        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(window.btoa(event.target?.result?.toString() as string));

        result.subscribe({
            next: arquivoBase64 => {
                this.formPerguntaVideo.get('imagemBase64')?.setValue(arquivoBase64);
            }
        });

    }

    public getDadosExtras(dados: any){
        this.formPerguntaVideo.get('alturaBoxImagem')?.setValue(dados.alturaBoxImagem);
        this.formPerguntaVideo.get('margemTopBoxImagem')?.setValue(dados.margemTopBoxImagem);
        this.formPerguntaVideo.get('alturaBoxVideo')?.setValue(dados.alturaBoxVideo);
        this.formPerguntaVideo.get('margemTopBoxVideo')?.setValue(dados.margemTopBoxVideo);
        this.formPerguntaVideo.get('alturaBoxHtml')?.setValue(dados.alturaBoxHtml);
        this.formPerguntaVideo.get('margemTopBoxHtml')?.setValue(dados.margemTopBoxHtml);
    }

    public getPerguntaUltimoEstado(): Pergunta{
        return this.formPerguntaService.getPerguntaUltimaVersao(this.pergunta.id) as Pergunta;
    }

    public getFormBotaoAvancar(): FormGroup{
        return this.formPerguntaVideo.get('botaoAvancar') as FormGroup;
    }

    public getEstiloAlternativa(): EstiloAlternativa{

        let estilo = {
            corTitulo: this.pergunta.botaoAvancar?.corTitulo as string,
            corFundo: this.pergunta.botaoAvancar?.corFundo,
            corLetra: this.pergunta.botaoAvancar?.corLetra,
            corFundoLetra: this.pergunta.botaoAvancar?.corFundoLetra,
            corTituloSelecionada: this.pergunta.botaoAvancar?.corTituloSelecionada,
            corFundoSelecionado: this.pergunta.botaoAvancar?.corFundoSelecionado,
            corLetraSelecionada: this.pergunta.botaoAvancar?.corLetraSelecionada,
            corFundoLetraSelecionada: this.pergunta.botaoAvancar?.corFundoLetraSelecionado,
            corBorda: this.pergunta.botaoAvancar?.corBorda,
            corBordaSelecionada: this.pergunta.botaoAvancar?.corBordaSelecionada,
            corBordaLetra: this.pergunta.botaoAvancar?.corBordaLetra,
            corBordaLetraSelecionada: this.pergunta.botaoAvancar?.corBordaLetraSelecionada,
            exibirIconeEsquerdo: this.pergunta.botaoAvancar?.exibirIconeEsquerdo,
            iconeDireito: this.pergunta.botaoAvancar?.iconeDireito,
            exibirIconeDireito: this.pergunta.botaoAvancar?.exibirIconeDireito,
            iconeEsquerdo: this.pergunta.botaoAvancar?.iconeEsquerdo
        } as unknown as EstiloAlternativa;

        return estilo;

    }

    public setEstiloAlternativa(estilo: EstiloAlternativa){
        let formBotaoAvancar = this.getFormBotaoAvancar();
        formBotaoAvancar?.get('corTitulo')?.setValue(estilo.corTitulo);
        formBotaoAvancar?.get('corFundo')?.setValue(estilo.corFundo);
        formBotaoAvancar?.get('corLetra')?.setValue(estilo.corLetra);
        formBotaoAvancar?.get('corFundoLetra')?.setValue(estilo.corFundoLetra);
        formBotaoAvancar?.get('corTituloSelecionada')?.setValue(estilo.corTituloSelecionada);
        formBotaoAvancar?.get('corFundoSelecionado')?.setValue(estilo.corFundoSelecionado);
        formBotaoAvancar?.get('corLetraSelecionada')?.setValue(estilo.corLetraSelecionada);
        formBotaoAvancar?.get('corFundoLetraSelecionado')?.setValue(estilo.corFundoLetraSelecionado);
        formBotaoAvancar?.get('corBorda')?.setValue(estilo.corBorda);
        formBotaoAvancar?.get('corBordaSelecionada')?.setValue(estilo.corBordaSelecionada);
        formBotaoAvancar?.get('corBordaLetra')?.setValue(estilo.corBordaLetra);
        formBotaoAvancar?.get('corBordaLetraSelecionada')?.setValue(estilo.corBordaLetraSelecionada);
        formBotaoAvancar?.get('exibirIconeEsquerdo')?.setValue(estilo.exibirIconeEsquerdo);
        formBotaoAvancar?.get('iconeEsquerdo')?.setValue(estilo.iconeEsquerdo);
        formBotaoAvancar?.get('exibirIconeDireito')?.setValue(estilo.exibirIconeDireito);
        formBotaoAvancar?.get('iconeDireito')?.setValue(estilo.iconeDireito);
    }

}
