import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutenticacaoGuardService } from "src/app/servicos/autenticacao-guard.service";
import { AutenticacaoService } from "src/app/servicos/autenticacao.service";
import { InterceptorService } from "src/app/servicos/interceptador.service";
import { AutenticacaoComponent } from "./autenticacao.component";

@NgModule({
    declarations: [
        AutenticacaoComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    exports: [
        AutenticacaoComponent
    ],
    providers: [
        AutenticacaoService,
        AutenticacaoGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass:  InterceptorService,
            multi: true
        },
    ]
})
export class AutenticacaoModule{

}
