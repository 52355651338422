import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ReplaySubject } from "rxjs";
import { Alternativa, Burlador, DadoEnvioAPI, EstiloAlternativa, Pergunta } from "src/app/modelos/pergunta.model";
import { TipoResposta } from "src/app/modelos/tipo-resposta.model";
import { FormPerguntaService } from "../form-pergunta.service";
import { EstiloFormulario } from "src/app/modelos/formulario.model";

@Component({
    selector: 'form-pergunta-alternativa-unica',
    templateUrl: './form-pergunta-alternativa-unica.component.html',
    styleUrls: ['./form-pergunta-alternativa-unica.component.css']
})
export class FormPerguntaAlternativaUnicaComponent implements OnChanges, DoCheck{

    @Input() pergunta!: Pergunta;
    @Input() perguntas!: Array<Pergunta> | null;

    @Input() mostrarBarraProgresso: boolean = false;
    @Input() estiloFormulario!: EstiloFormulario | undefined | null;

    @Input() burladores: Burlador[] = [];

    @Output() perguntaEditada: EventEmitter<Pergunta> = new EventEmitter();
    @Output() perguntaSalva: EventEmitter<Pergunta> = new EventEmitter();
    @Output() perguntaCadastrar: EventEmitter<Pergunta> = new EventEmitter();

    public formPerguntaUnicaAlternativa!: FormGroup;

    @Input() variaveis!: Array<string>;
    public identificadores: string[] = [];
    public capturarEnvioAPI: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private formPerguntaService: FormPerguntaService
    ){}

    ngOnChanges(){

        this.restaurarUltimaVersaoPergunta();

        this.formPerguntaUnicaAlternativa = this.formBuilder.group({
            id: [this.pergunta.id,[Validators.required]],
            posicao: [this.pergunta.posicao,[Validators.required]],
            identificador: [this.pergunta.identificador,[Validators.required]],
            titulo: [{value: this.pergunta.titulo, disabled: true},[Validators.required]],
            descricao: [{value: this.pergunta.descricao, disabled: true},[Validators.required]],
            exibeImagem: [this.pergunta.exibeImagem,[]],
            imagemBase64: [this.pergunta.imagemBase64,[]],
            urlImagemBase64: [{value: this.pergunta.chaveImagem, disabled: true},[]],
            ultima: [this.pergunta.ultima,[Validators.required]],
            opcaoRedirecionamento: [this.pergunta.opcaoRedirecionamento.toString(),[]],
            redirecionamentoPergunta: [this.pergunta.redirecionamentoPergunta,[]],
            redirecionamentoLink: [this.pergunta.redirecionamentoLink,[]],
            indiceBarraProgresso: [this.pergunta.indiceBarraProgresso,[]],
            totalIndiceBarraProgresso: [this.pergunta.totalIndiceBarraProgresso,[]],
            exibirTitulo: [this.pergunta.exibirTitulo,[]],
            exibeTextoImagem: [this.pergunta.exibeTextoImagem,[]],
            textoImagem: [this.pergunta.textoImagem,[]],
            marcada: [this.pergunta.marcada,[]],
            resposta: this.formBuilder.group({
                id: [this.pergunta.resposta.id,[Validators.required]],
                tipoResposta: [{value: this.pergunta.resposta.tipoResposta,disabled: true},[Validators.required]],
                guarda: [this.pergunta.resposta.guarda,[Validators.required]],
                placeholder: [this.pergunta.resposta.placeholder,[]],
                alternativas: this.formBuilder.array([]),
                exibirLetraAlternativa: [this.pergunta.resposta.exibirLetraAlternativa,[]]
            }),
            envioAPI: this.formBuilder.group({
                id: [this.pergunta.envioAPI?.id,[Validators.required]],
                metodoHTTP: [this.pergunta.envioAPI?.metodoHTTP,[Validators.required]],
                url: [this.pergunta.envioAPI?.url,[Validators.required]],
                linkProprio: [this.pergunta.envioAPI?.linkProprio,[]],
                link: [this.pergunta.envioAPI?.link,[]],
                dados: this.formBuilder.array([])
            })
        });
        this.pergunta.resposta.alternativas?.forEach(
            alternativa => {
                this.formAlternativas.push(this.criarAlternativa(alternativa));
            }
        );
        this.pergunta?.envioAPI?.dados.forEach(dado => {
            this.formDado.push(this.criarDado(dado));
        });
        this.identificadores = (this.perguntas as Pergunta[]).filter(p =>
            p.resposta.tipoResposta != TipoResposta.INFORMATIVO_TEXTO)
            .filter(p => p.resposta.tipoResposta != TipoResposta.INFORMATIVO_VIDEO)
            .filter(p => p.resposta.tipoResposta != TipoResposta.INFORMATIVO_VIDEO_PERSONALIZADO)
            .map(p => p.identificador);
    }

    private criarAlternativa(alternativa?: Alternativa): FormGroup{
        return this.formBuilder.group({
            id: [alternativa?.id,[Validators.required]],
            titulo: [alternativa?.titulo,[Validators.required]],
            ultima: [alternativa?.ultima,[Validators.required]],
            redirecionamentoPergunta: [alternativa?.redirecionamentoPergunta,[]],
            redirecionamentoLink: [alternativa?.redirecionamentoLink,[]],
            estiloProprio: [alternativa?.estiloProprio,[]],
            alturaBoxImagem: [this.pergunta.alturaBoxImagem,[]],
            margemTopBoxImagem: [this.pergunta.margemTopBoxImagem,[]],
            alturaBoxVideo: [this.pergunta.alturaBoxVideo,[]],
            margemTopBoxVideo: [this.pergunta.margemTopBoxVideo,[]],
            alturaBoxHtml: [this.pergunta.alturaBoxHtml,[]],
            margemTopBoxHtml: [this.pergunta.margemTopBoxHtml,[]],
            marcadaA: [alternativa?.marcadaA,[]],
            burladorPersonalizado: [alternativa?.burladorPersonalizado,[]],
            idBurlador: [alternativa?.burlador?.id,[]],
            finalizaFluxo: [alternativa?.finalizaFluxo,[]],
            fluxoPrincipal: [alternativa?.fluxoPrincipal,[]],
            nomeFluxo: [alternativa?.nomeFluxo,[]],
            estilo: this.formBuilder.group({
                corTitulo: [alternativa?.estilo?.corTitulo  ? alternativa?.estilo?.corTitulo : '#ffffff',[]],
                corFundo: [alternativa?.estilo?.corFundo ?  alternativa?.estilo?.corFundo : '#3C3A80',[]],
                corLetra: [alternativa?.estilo?.corLetra ?  alternativa?.estilo?.corLetra : '#3C3A80',[]],
                corFundoLetra: [alternativa?.estilo?.corFundoLetra ? alternativa?.estilo?.corFundoLetra : '#ffffff',[]],
                corTituloSelecionada: [alternativa?.estilo?.corTituloSelecionada ? alternativa?.estilo?.corTituloSelecionada : '#3C3A80',[]],
                corFundoSelecionado: [alternativa?.estilo?.corFundoSelecionado ?  alternativa?.estilo?.corFundoSelecionado : '#ffffff',[]],
                corLetraSelecionada: [alternativa?.estilo?.corLetraSelecionada ? alternativa?.estilo?.corLetraSelecionada : '#ffffff',[]],
                corFundoLetraSelecionado: [alternativa?.estilo?.corFundoLetraSelecionado ? alternativa?.estilo?.corFundoLetraSelecionado : '#3C3A80',[]],
                corBorda: [alternativa?.estilo?.corBorda ? alternativa.estilo.corBorda : '#3C3A80', []],
                corBordaSelecionada: [alternativa?.estilo?.corBordaSelecionada ? alternativa.estilo.corBordaSelecionada : '#3C3A80', []],
                corBordaLetra: [alternativa?.estilo?.corBordaLetra ? alternativa.estilo.corBordaLetra : '#ffffff', []],
                corBordaLetraSelecionada: [alternativa?.estilo?.corBordaLetraSelecionada ? alternativa.estilo.corBordaLetraSelecionada : '#3C3A80', []],
                exibirIconeEsquerdo: [alternativa?.estilo?.exibirIconeEsquerdo,[]],
                iconeEsquerdo: [alternativa?.estilo?.iconeEsquerdo,[]],
                exibirIconeDireito: [alternativa?.estilo?.exibirIconeDireito,[]],
                iconeDireito: [alternativa?.estilo?.iconeDireito,[]]
            })
        });
    }

    private restaurarUltimaVersaoPergunta(){
        let p = this.formPerguntaService.getPerguntaUltimaVersao(this.pergunta.id);
        if(p) {
            this.pergunta = p;
            this.corrigirBurlador(p,this.burladores);
        }
    }

    ngDoCheck(): void {
        let f = this.formPerguntaUnicaAlternativa.getRawValue() as Pergunta;
        this.corrigirBurlador(f,this.burladores);
        this.formPerguntaService.addPerguntaAlterar(f);
    }

    get formAlternativas(): FormArray{
        return this.formPerguntaUnicaAlternativa.get('resposta')?.get('alternativas') as FormArray;
    }

    get formDado(): FormArray{
        return this.formPerguntaUnicaAlternativa.get('envioAPI')?.get('dados') as FormArray;
    }

    public addAlternativa(){
        this.formAlternativas.push(this.criarAlternativa());
    }

    public rmvAlternativa(index: number){
        this.formAlternativas.removeAt(index);
    }

    private criarDado(dado?: DadoEnvioAPI):FormGroup{
        return this.formBuilder.group({
            id: [dado?.id,[Validators.required]],
            campo: [dado?.campo,[Validators.required]],
            valorEspecifico: [dado?.valorEspecifico == null ? false : dado?.valorEspecifico,[Validators.required]],
            valor: [dado?.valor,[Validators.required]]
        });
    }

    public addDado(){
        this.formDado.push(this.criarDado());
    }

    public rmvDado(){
        this.formDado.removeAt(this.formDado.length -1);
    }

    public guardaPergunta(){
        let f = this.formPerguntaUnicaAlternativa.getRawValue() as Pergunta;
        this.perguntaSalva.emit(f);
    }

    public getTitulo():string{
        return this.formPerguntaUnicaAlternativa.get('titulo')?.value as string;
    }

    public getDescricao():string{
        return this.formPerguntaUnicaAlternativa.get('descricao')?.value as string;
    }

    public getTextoImagem():string{
        return this.formPerguntaUnicaAlternativa.get("textoImagem")?.value as string;
    }

    public unicaFormatadoTitulo(texto: string){
        this.formPerguntaUnicaAlternativa.get('titulo')?.setValue(texto);
    }

    public unicaFormatadoDescricao(texto: string){
        this.formPerguntaUnicaAlternativa.get('descricao')?.setValue(texto);
    }

    public textoFormatadoTextoImagem(texto: string){
        this.formPerguntaUnicaAlternativa.get('textoImagem')?.setValue(texto);
    }

    public gerarIdFormatador(radical: string){
        return `unica${radical}${this.pergunta.identificador}`;
    }

    public gerarIdInput(prefixo:string,id: number):string{
        return `${prefixo}-${id}`;
    }

    public gerarIdVisualizador(radical: string){
        return `visualizador${radical}${this.pergunta.identificador}`;
    }

    public tratarArquivo(event: any){

        let file = event.target.files[0] as File;

        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(window.btoa(event.target?.result?.toString() as string));

        result.subscribe({
            next: arquivoBase64 => {
                this.formPerguntaUnicaAlternativa.get('imagemBase64')?.setValue(arquivoBase64);
            }
        });

    }

    public getEstiloAlternativa(index:number): EstiloAlternativa{
        return this.formAlternativas.controls[index]?.get('estilo')?.getRawValue() as EstiloAlternativa;
    }

    public setEstiloAlternativa(index:number,estilo: EstiloAlternativa){
        let formEstilo = this.formAlternativas.controls[index].get('estilo');
        formEstilo?.get('corTitulo')?.setValue(estilo.corTitulo);
        formEstilo?.get('corFundo')?.setValue(estilo.corFundo);
        formEstilo?.get('corLetra')?.setValue(estilo.corLetra);
        formEstilo?.get('corFundoLetra')?.setValue(estilo.corFundoLetra);
        formEstilo?.get('corTituloSelecionada')?.setValue(estilo.corTituloSelecionada);
        formEstilo?.get('corFundoSelecionado')?.setValue(estilo.corFundoSelecionado);
        formEstilo?.get('corLetraSelecionada')?.setValue(estilo.corLetraSelecionada);
        formEstilo?.get('corFundoLetraSelecionado')?.setValue(estilo.corFundoLetraSelecionado);
        formEstilo?.get('corBorda')?.setValue(estilo.corBorda);
        formEstilo?.get('corBordaSelecionada')?.setValue(estilo.corBordaSelecionada);
        formEstilo?.get('corBordaLetra')?.setValue(estilo.corBordaLetra);
        formEstilo?.get('corBordaLetraSelecionada')?.setValue(estilo.corBordaLetraSelecionada);
        formEstilo?.get('exibirIconeEsquerdo')?.setValue(estilo.exibirIconeEsquerdo);
        formEstilo?.get('iconeEsquerdo')?.setValue(estilo.iconeEsquerdo);
        formEstilo?.get('exibirIconeDireito')?.setValue(estilo.exibirIconeDireito);
        formEstilo?.get('iconeDireito')?.setValue(estilo.iconeDireito);
    }

    public getDadosExtras(dados: any){
        this.formPerguntaUnicaAlternativa.get('alturaBoxImagem')?.setValue(dados.alturaBoxImagem);
        this.formPerguntaUnicaAlternativa.get('margemTopBoxImagem')?.setValue(dados.margemTopBoxImagem);
        this.formPerguntaUnicaAlternativa.get('alturaBoxVideo')?.setValue(dados.alturaBoxVideo);
        this.formPerguntaUnicaAlternativa.get('margemTopBoxVideo')?.setValue(dados.margemTopBoxVideo);
        this.formPerguntaUnicaAlternativa.get('alturaBoxHtml')?.setValue(dados.alturaBoxHtml);
        this.formPerguntaUnicaAlternativa.get('margemTopBoxHtml')?.setValue(dados.margemTopBoxHtml);
    }

    public getPerguntaUltimoEstado(): Pergunta{
        return this.formPerguntaService.getPerguntaUltimaVersao(this.pergunta.id) as Pergunta;
    }

    private corrigirBurlador(pergunta: Pergunta, burladores: Burlador[]){
        pergunta.resposta.alternativas?.forEach(a => {
            if(a.burladorPersonalizado == true){
                a.burlador = burladores.filter(b => b.id == a.idBurlador)[0] as Burlador;
            }
        });
    }

    public getIdAlternativa(indice: number, identificador: string){
        return `${identificador}-alt-${indice}`;
    }

}
