import { Component, OnChanges, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Usuario } from "src/app/modelos/usuario.model";
import { UsuarioLogadoService } from "src/app/servicos/usuario-logado.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'cabecalho',
    templateUrl: './cabecalho.component.html',
    styleUrls: ['./cabecalho.component.css']
})
export class CabecalhoComponent implements OnInit{

    public usuario!: Usuario | null;

    public versao: string = environment.versao;

    constructor(
        private usuarioLogadoService: UsuarioLogadoService,
        private router: Router
    ){}

    ngOnInit(): void {
        this.usuarioLogadoService.recuperarUsuario().subscribe({
            next: usu => {
                this.usuario = usu;
            }
        });
    }

    /*
    ngOnChanges(): void{

        this.usuarioLogadoService.recuperarUsuario().subscribe({
            next: usu => {
                this.usuario = usu;
            }
        });
    }*/

    public logout(){
        this.usuarioLogadoService.deslogarUsuario();
        window.localStorage.removeItem("rota");
        this.router.navigate(['/']);
    }

}
