import { NgModule } from "@angular/core";
import { ListaFormsComponent } from "./lista-forms.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PastaModule } from "src/app/compartilhados/componentes/pasta/pasta.module";
import { FormularioService } from "src/app/servicos/formulario.service";
import { SpinnerService } from "src/app/compartilhados/componentes/spinners/spinner.service";
import { SpinnerModule } from "src/app/compartilhados/componentes/spinners/spinner.module";
import { OpenGraphService } from "src/app/servicos/openGraph.service";

@NgModule({
    declarations: [
        ListaFormsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PastaModule,
        SpinnerModule
    ],
    exports: [
        ListaFormsComponent
    ],
    providers: [
        FormularioService,
        OpenGraphService
    ]
})
export class ListaFormsModule{

}
