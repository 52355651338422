import { NgModule } from "@angular/core";
import { PastaComponent } from "./pasta.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { PastaService } from "src/app/servicos/pasta-service.service";
import { DataBsTargetModule } from "../../diretivas/data-bs-target.module";

@NgModule({
    declarations: [
        PastaComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        DataBsTargetModule
    ],
    exports: [
        PastaComponent
    ],
    providers: [
        PastaService
    ]
})
export class PastaModule{

}
