import { Injectable } from "@angular/core";

@Injectable()
export class TokenService{

    private NOME_TOKEN = "token";

    constructor(){}

    public setToken(token: string){
        window.localStorage.setItem(this.NOME_TOKEN, token);
    }

    public getToken():string | null {
        return window.localStorage.getItem(this.NOME_TOKEN);
    }

    public excluirToken(){
        return window.localStorage.removeItem(this.NOME_TOKEN);
    }

    public temToken():boolean{
        let token = this.getToken();
        if(token == null) return false;
        return true;
    }

}
