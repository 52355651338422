import { Component, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SpinnerService } from "src/app/compartilhados/componentes/spinners/spinner.service";
import { Burlador } from "src/app/modelos/pergunta.model";
import { BurladorService } from "src/app/servicos/burlador.service";
import Swal from "sweetalert2";

@Component({
    selector: 'form-burlador',
    templateUrl: './form-burlador.component.html',
    styleUrls: ['./form-burlador.component.css']
})
export class FormBurladorComponent implements OnInit, OnChanges{

    @Input()
    public idFormulario!: number | null;

    public burladores: Burlador[] = [];

    public formSalvarBurlador!: FormGroup;
    public formEditarBurlador!: FormGroup;

    public mostrarEdicao: boolean = false;

    @Output()
    public listarBurladores: EventEmitter<Burlador[]> = new EventEmitter<Burlador[]>();

    constructor(
        private burladorService: BurladorService,
        private formBuilder: FormBuilder,
        private spinnerService: SpinnerService
    ){}

    ngOnInit(): void {
        this.formSalvarBurlador = this.formBuilder.group({
            id: ['',[]],
            nome: ['',[Validators.required]],
            link: ['',[]],
            linkProprio: ['',[]]
        });
        this.formEditarBurlador = this.formBuilder.group({
            id: ['',[]],
            nome: ['',[Validators.required]],
            link: ['',[]],
            linkProprio: ['',[]]
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.idFormulario){
            this.burladorService.listar(this.idFormulario).subscribe(
                lista => {
                    this.burladores = lista;
                    this.listarBurladores.emit(this.burladores);
                }
            );
        }
    }

    public gerarId(): string{
        return `mB${this.idFormulario}`;
    }

    public gerarIdEditar(): string{
        return `mEB${this.idFormulario}`;
    }

    public salvar(){
        let dados = this.formSalvarBurlador.getRawValue() as Burlador;
        dados.idFormulario = this.idFormulario as number;
        this.spinnerService.ativarSpinner();
        this.burladorService.salvar(dados).subscribe({
            next: dadosSalvaos => {
                this.formSalvarBurlador.reset();
                this.burladores.push(dadosSalvaos);
                this.listarBurladores.emit(this.burladores);
                this.spinnerService.desativarSpinner();
            },
            error: error => this.spinnerService.desativarSpinner()
        });
    }

    public excluir(burlador: Burlador){

        Swal.fire({
            title: 'Tem certeza que deseja excluir o burlador?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {

            if (result.isConfirmed) {
                this.spinnerService.ativarSpinner();
                this.burladorService.excluir(burlador.id).subscribe({
                    next: () => {
                        this.burladores = this.burladores.filter(b => b.id !== burlador.id);
                        this.listarBurladores.emit(this.burladores);
                        Swal.fire('Sucesso!', 'Burlador Excluído com sucesso!', 'success');
                        this.spinnerService.desativarSpinner();
                    },
                    error: error => {
                        Swal.fire('Erro!', 'Erro ao excluir Burlador!', 'error');
                        this.spinnerService.desativarSpinner();
                    }
                });

            }

        });

    }

    public setDadosEditar(burlador: Burlador){
        this.formEditarBurlador.get("id")?.setValue(burlador.id);
        this.formEditarBurlador.get("nome")?.setValue(burlador.nome);
        this.formEditarBurlador.get("link")?.setValue(burlador.link);
        this.formEditarBurlador.get("linkProprio")?.setValue(burlador.linkProprio);
        this.mostrarEdicao = true;
    }

    public salvarEdicao(){
        let dados = this.formEditarBurlador.getRawValue() as Burlador;
        dados.idFormulario = this.idFormulario as number;
        this.spinnerService.ativarSpinner();
        this.burladorService.alterar(dados.id,dados).subscribe({
            next: dadosAtualizados => {
                this.formEditarBurlador.reset();
                this.mostrarEdicao = false;
                this.burladores = this.burladores.filter(b => b.id != dados.id);
                this.burladores.push(dadosAtualizados);
                this.spinnerService.desativarSpinner();
            },
            error: error => this.spinnerService.desativarSpinner()
        });
    }

    public voltarPainelAdd(){
        this.formEditarBurlador.reset();
        this.mostrarEdicao = false;
    }

}
