import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Pasta, PastaDados } from "../modelos/pergunta.model";
import { Observable } from "rxjs";

@Injectable()
export class PastaService{

    private url: string = `${environment.apiBackEnd}/api/v1/pasta`;

    constructor(
        private http: HttpClient
    ){}

    public cadastrar(pasta: PastaDados): Observable<Pasta>{
        return this.http.post<Pasta>(this.url,pasta);
    }

    public atualizar(id: number, pasta: PastaDados): Observable<Pasta>{
        let urlLocal = `${this.url}/${id}`;
        return this.http.put<Pasta>(urlLocal,pasta);
    }

    public addFormulario(id: number, idsFormulario: number[]): Observable<any>{
        let urlLocal = `${this.url}/${id}/add`;
        return this.http.post<any>(urlLocal,idsFormulario);
    }

    public excluir(id: number): Observable<any>{
        let urlLocal = `${this.url}/${id}`;
        return this.http.delete<any>(urlLocal);
    }

    public buscarPastas(): Observable<Pasta[]>{
        let urlLocal = `${this.url}/get`;
        return this.http.post<Pasta[]>(urlLocal,null);
    }

}
