import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Burlador } from "../modelos/pergunta.model";
import { Observable } from "rxjs";

@Injectable()
export class BurladorService{

    private url:string = `${environment.apiBackEnd}/api/v1/burlador`;

    constructor(
        private http: HttpClient
    ){}

    public salvar(dados: Burlador): Observable<Burlador>{
        return this.http.post<Burlador>(this.url,dados);
    }

    public alterar(id: number, dados: Burlador): Observable<Burlador>{
        let urlLocal = `${this.url}/${id}`;
        return this.http.put<Burlador>(urlLocal,dados);
    }

    public excluir(id: number): Observable<void>{
        let urlLocal = `${this.url}/${id}`;
        return this.http.delete<void>(urlLocal);
    }

    public listar(idFormulario: number): Observable<Burlador[]>{
        let urlLocal = `${this.url}/listar/${idFormulario}`;
        return this.http.post<Burlador[]>(urlLocal,null);
    }

}
