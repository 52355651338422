import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { EstiloFormulario } from "src/app/modelos/formulario.model";
import { Alternativa, Pergunta } from "src/app/modelos/pergunta.model";

@Component({
    selector: "visualizador",
    templateUrl: "./form-visualizador.component.html",
    styleUrls: ['./form-visualizador.component.css']
})
export class FormVisualizadorComponent implements OnInit{

    @Input() public id!: string;
    @Input() public pergunta!: Pergunta;
    @Input() public estilo!: EstiloFormulario | undefined | null;

    @Output()
    public dadosSalvos: EventEmitter<any> = new EventEmitter<any>();

    public formAjustes!: FormGroup;

    constructor(
        private fb: FormBuilder
    ){}

    ngOnInit(): void {
        this.formAjustes = this.fb.group({
            alturaBoxImagem: ['220',[]],
            margemTopBoxImagem: ['0',[]],
            alturaBoxVideo: ['220',[]],
            margemTopBoxVideo: ['0',[]],
            alturaBoxHtml: ['220',[]],
            margemTopBoxHtml: ['0',[]],
        });
    }

    public gerarId(): string{
        return `mP${this.id}`;
    }

    public salvar(){
        let dados = this.formAjustes.getRawValue() as object;
        console.log(dados);
        this.dadosSalvos.emit(dados);
    }

    public getFontECorEscolhida(): string {
        return `font-family: ${this.estilo?.fonte} !important;
            color: ${this.estilo?.corFonte} !important; background-color: ${this.estilo?.corFundo} !important`;
    }

    public getCorEFundoEscolhido(): string {
        let estilo = `background-color: ${this.estilo?.corFundo};`;

        if (this.estilo?.exibeImagemFundo == false) {
            return estilo;
        } else if(this.estilo?.exibeImagemFundo == true) {
            estilo += `background-image: url('data:image/jpg;base64,${this.estilo.imagemFundoBase64}');background-repeat: no-repeat;`;
            if (this.estilo.posicaoImagem == "Centralizar") {
                estilo += `background-position: center;`;
                return estilo;
            } else if (this.estilo.posicaoImagem == "Preencher") {
                estilo += `background-size: cover;`;
                return estilo;
            }
            return estilo;
        }

        return estilo;
    }

    public getCorPassoProgresso(): string{
        let cor = this.estilo?.corPassoProgresso;
        if(cor == '') return "#000000";
        return cor as string;
    }

    public getCorBarraProgresso(): string{
        let cor = this.estilo?.corBarraProgresso;
        if(cor == '') return "#3B397F";
        return cor as string;
    }

    public calcularBarraProgresso(): string{
        let porcentagem = ((this.pergunta.indiceBarraProgresso as number) /
            (this.pergunta.totalIndiceBarraProgresso as number)) * 100;
        return `width: ${porcentagem}%`;
    }

    public getEstiloBoxImagem(){
        let img = `background-image: url("data:image/png;base64,${this.pergunta?.imagemBase64}")`;
        let altura = `height:  ${this.formAjustes.get('alturaBoxImagem')?.value}px`;
        let margem = `margin-top: ${this.formAjustes.get('margemTopBoxImagem')?.value}px`;
        return `${img};${altura};${margem}`;
    }

    public getEstiloBoxVideo(){
        let altura = `height:  ${this.formAjustes.get('alturaBoxVideo')?.value}px`;
        let margem = `margin-top: ${this.formAjustes.get('margemTopBoxVideo')?.value}px`;
        return `${altura};${margem}`;
    }

    public getEstiloBoxHtml(){
        let altura = `height:  ${this.formAjustes.get('alturaBoxHtml')?.value}px`;
        let margem = `margin-top: ${this.formAjustes.get('margemTopBoxHtml')?.value}px`;
        return `${altura};${margem}`;
    }

    public getLetraAlternativa(index: number) {
        let letras: string[] = ['a', 'b', 'c', 'd', 'e'];
        return letras[index];
    }

    public estiloPersonalizadoBtnAlternativa(alternativa: Alternativa):string{
        let fundo = "background-color: " + alternativa?.estilo?.corFundo as string + " !important";
        let cor = "color: " + alternativa?.estilo?.corTitulo as string + " !important";
        let borda = `border: 1px solid ${alternativa.estilo?.corBorda as string} !important`;
        return `${fundo};${cor};${borda}`;
    }

    public estiloPersonalizadoLetraBtnAlternativa(alternativa: Alternativa):string{
        let fundo = "background-color: " + alternativa.estilo?.corFundoLetra as string + " !important";
        let cor = "color: " + alternativa.estilo?.corLetra as string + " !important";
        let borda = `border: 1px solid ${alternativa.estilo?.corBordaLetra as string}` + " !important";
        return `${fundo};${cor};${borda}`;
    }

}
