export enum TipoResposta{
    MARCACAO_USUARIO = "Marcação de Usuário",
    ALTERNATIVA_UNICA = "Alternativa Única",
    ALTERNATIVA_MULTIPLA = "Alternativa Múltipla",
    DATA = "Data",
    HORA = "Hora",
    DATA_HORA = "Data e Hora",
    NUMERO_INTEIRO = "Número Inteiro",
    NUMERO_MOEDA = "Moeda",
    NUMERO_DECIMAL = "Número Decimal",
    TEXTO = "Texto",
    EMAIL = "Email",
    CELULAR = "Celular",
    ARQUIVO = "Arquivo",
    INFORMATIVO_TEXTO = "Informativo de Texto",
    INFORMATIVO_VIDEO = "Informativo Video",
    HTML = "Html",
    INFORMATIVO_VIDEO_PERSONALIZADO = "Informativo Video Personalizado",
    ALTERNATIVA_UNICA_DROPDOWN = "Alternativa Única Dropdown",
    ALTERNATIVA_UNICA_COMPACTA = "Alternativa Única Compacta"
}
